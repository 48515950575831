import api from '../../../../api'

export const FETCH_PROVIDERS_PENDING = 'FETCH_PROVIDERS_PENDING'
export const FETCH_PROVIDERS_FULFILLED = 'FETCH_PROVIDERS_FULFILLED'
export const FETCH_PROVIDERS_REJECTED = 'FETCH_PROVIDERS_REJECTED'

export const FETCH_PROVIDER_PENDING = 'FETCH_PROVIDER_PENDING'
export const FETCH_PROVIDER_FULFILLED = 'FETCH_PROVIDER_FULFILLED'
export const FETCH_PROVIDER_REJECTED = 'FETCH_PROVIDER_REJECTED'

export const CREATE_PROVIDER_PENDING = 'CREATE_PROVIDER_PENDING'
export const CREATE_PROVIDER_FULFILLED = 'CREATE_PROVIDER_FULFILLED'
export const CREATE_PROVIDER_REJECTED = 'CREATE_PROVIDER_REJECTED'

export const UPDATE_PROVIDER_PENDING = 'UPDATE_PROVIDER_PENDING'
export const UPDATE_PROVIDER_FULFILLED = 'UPDATE_PROVIDER_FULFILLED'
export const UPDATE_PROVIDER_REJECTED = 'UPDATE_PROVIDER_REJECTED'

export const DELETE_PROVIDER_PENDING = 'DELETE_PROVIDER_PENDING'
export const DELETE_PROVIDER_FULFILLED = 'DELETE_PROVIDER_FULFILLED'
export const DELETE_PROVIDER_REJECTED = 'DELETE_PROVIDER_REJECTED'

export const CHANGE_PROVIDER = 'CHANGE_PROVIDER'

export const ADD_PROVIDER = 'ADD_PROVIDER'

export function changeProvider(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_PROVIDER, payload: { name, value } })
  }
}

export function addProvider() {
  return dispatch => {
    dispatch({ type: ADD_PROVIDER });
  };
}

export function findAllProviders(first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PROVIDERS_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllProviders {
                  prestadores (first: ${first} offset: ${offset}) {
                    codigo
                    cpf
                    razaoSocial
                    celular
                    foneComercial
                    email
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PROVIDERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { prestadores } = data
        dispatch({ type: FETCH_PROVIDERS_FULFILLED, payload: prestadores });
        resolve(prestadores);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PROVIDERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findProvider(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PROVIDER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findProvider {
                  prestador (codigo: ${codigo}) {
                    codigo
                    status
                    razaoSocial
                    sexo
                    nascimento
                    estadoCivil
                    necessidades
                    fatorSangue   
                    nacionalidade   
                    naturalEstado
                    naturalMunicipio
                    profissao
                    especialidade
                    especialidades
                    orgaoClasse
                    numeroConselho
                    estadoConselho
                    cpf
                    rg
                    expedidorRg
                    ufRg
                    nomePai
                    nomeMae
                    tipoEndereco
                    endereco
                    numero
                    complemento
                    cep
                    bairro
                    estado
                    municipio
                    celular
                    telefone
                    foneResidencial
                    foneComercial
                    email
                    instagram
                    facebook
                    banco
                    numeroAgencia
                    tipoConta
                    numeroConta
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PROVIDER_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { prestador } = data
        dispatch({ type: FETCH_PROVIDER_FULFILLED, payload: prestador });
        resolve(prestador);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PROVIDER_REJECTED, error });
      resolve(error);
    });

  });

}

export function createProvider(provider) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PROVIDER_PENDING });

    api({
      method: 'post',    
      data: {
        query: `
          mutation createProvider {
            createPrestador (input: {
              codigo: ${provider.codigo}
              status: ${provider.status}
              razaoSocial: "${provider.razaoSocial}"
              sexo: "${provider.sexo}"
              nascimento: "${provider.nascimento}"
              estadoCivil: ${provider.estadoCivil}
              necessidades: ${provider.necessidades}
              fatorSangue: "${provider.fatorSangue}"   
              nacionalidade: ${provider.nacionalidade}    
              naturalEstado: ${provider.naturalEstado}
              naturalMunicipio: ${provider.naturalMunicipio}
              profissao: ${provider.profissao}
              especialidade: ${provider.especialidade}
              especialidades: "${provider.especialidades.join(",")}"
              orgaoClasse: ${provider.orgaoClasse}
              numeroConselho: "${provider.numeroConselho}"
              estadoConselho: ${provider.estadoConselho}
              cpf: "${provider.cpf}"
              rg: "${provider.rg}"
              expedidorRg: ${provider.expedidorRg}
              ufRg: ${provider.ufRg} 
              nomePai: "${provider.nomePai}"
              nomeMae: "${provider.nomeMae}"
              tipoEndereco: ${provider.tipoEndereco}
              endereco: "${provider.endereco}"
              numero: "${provider.numero}"
              complemento: "${provider.complemento}"
              cep: "${provider.cep}"
              bairro: "${provider.bairro}"
              estado: ${provider.estado}
              municipio: ${provider.municipio}
              celular: "${provider.celular}"
              telefone: "${provider.telefone}"
              foneResidencial: "${provider.foneResidencial}"
              foneComercial: "${provider.foneComercial}"
              email: "${provider.email}"
              instagram: "${provider.instagram}"
              facebook: "${provider.facebook}"
              banco: ${provider.banco}
              numeroAgencia: "${provider.numeroAgencia}"
              tipoConta: ${provider.tipoConta}
              numeroConta: "${provider.numeroConta}"
            } ) {
              codigo
              status
              razaoSocial
              sexo
              nascimento
              estadoCivil
              necessidades
              fatorSangue   
              nacionalidade   
              naturalEstado
              naturalMunicipio
              profissao
              especialidade
              especialidades
              orgaoClasse
              numeroConselho
              estadoConselho
              cpf
              rg
              expedidorRg
              ufRg
              nomePai
              nomeMae
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              telefone
              foneResidencial
              foneComercial
              email
              instagram
              facebook
              banco
              numeroAgencia
              tipoConta
              numeroConta
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PROVIDER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createPrestador } = data
        dispatch({ type: CREATE_PROVIDER_FULFILLED, payload: createPrestador });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PROVIDER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateProvider(provider) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_PROVIDER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateProvider {
            updatePrestador (input: {
              codigo: ${provider.codigo}
              status: ${provider.status}
              razaoSocial: "${provider.razaoSocial}"
              sexo: "${provider.sexo}"
              nascimento: "${provider.nascimento}"
              estadoCivil: ${provider.estadoCivil}
              necessidades: ${provider.necessidades}
              fatorSangue: "${provider.fatorSangue}"   
              nacionalidade: ${provider.nacionalidade}    
              naturalEstado: ${provider.naturalEstado}
              naturalMunicipio: ${provider.naturalMunicipio}
              profissao: ${provider.profissao}
              especialidade: ${provider.especialidade}
              especialidades: "${provider.especialidades.join(",")}"
              orgaoClasse: ${provider.orgaoClasse}
              numeroConselho: "${provider.numeroConselho}"
              estadoConselho: ${provider.estadoConselho}
              cpf: "${provider.cpf}"
              rg: "${provider.rg}"
              expedidorRg: ${provider.expedidorRg}
              ufRg: ${provider.ufRg} 
              nomePai: "${provider.nomePai}"
              nomeMae: "${provider.nomeMae}"
              tipoEndereco: ${provider.tipoEndereco}
              endereco: "${provider.endereco}"
              numero: "${provider.numero}"
              complemento: "${provider.complemento}"
              cep: "${provider.cep}"
              bairro: "${provider.bairro}"
              estado: ${provider.estado}
              municipio: ${provider.municipio}
              celular: "${provider.celular}"
              telefone: "${provider.telefone}"
              foneResidencial: "${provider.foneResidencial}"
              foneComercial: "${provider.foneComercial}"
              email: "${provider.email}"
              instagram: "${provider.instagram}"
              facebook: "${provider.facebook}"
              banco: ${provider.banco}
              numeroAgencia: "${provider.numeroAgencia}"
              tipoConta: ${provider.tipoConta}
              numeroConta: "${provider.numeroConta}"
            } ) {
              codigo
              status
              razaoSocial
              sexo
              nascimento
              estadoCivil
              necessidades
              fatorSangue   
              nacionalidade   
              naturalEstado
              naturalMunicipio
              profissao
              especialidade
              especialidades
              orgaoClasse
              numeroConselho
              estadoConselho
              cpf
              rg
              expedidorRg
              ufRg
              nomePai
              nomeMae
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              telefone
              foneResidencial
              foneComercial
              email
              instagram
              facebook
              banco
              numeroAgencia
              tipoConta
              numeroConta
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_PROVIDER_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updatePrestador } = data
        dispatch({ type: UPDATE_PROVIDER_FULFILLED, payload: updatePrestador });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_PROVIDER_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeProvider(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_PROVIDER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveProvider {
            deletePrestador (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_PROVIDER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deletePrestador } = data
        dispatch({ type: DELETE_PROVIDER_FULFILLED, payload: deletePrestador });
        dispatch(findAllProviders());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_PROVIDER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
