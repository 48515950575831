import {

    FETCH_COSTCENTERS_PENDING,
    FETCH_COSTCENTERS_FULFILLED,
    FETCH_COSTCENTERS_REJECTED,

    FETCH_COSTCENTER_PENDING,
    FETCH_COSTCENTER_FULFILLED,
    FETCH_COSTCENTER_REJECTED,

    CREATE_COSTCENTER_PENDING,
    CREATE_COSTCENTER_FULFILLED,
    CREATE_COSTCENTER_REJECTED,

    UPDATE_COSTCENTER_PENDING,
    UPDATE_COSTCENTER_FULFILLED,
    UPDATE_COSTCENTER_REJECTED,

    DELETE_COSTCENTER_PENDING,
    DELETE_COSTCENTER_FULFILLED,
    DELETE_COSTCENTER_REJECTED,
    
    CHANGE_COSTCENTER,
    
    ADD_COSTCENTER

} from './costCenterActions'

import { titleCase } from '../../../../common/utils'

const moment = require('moment')

const initialState = {
    loading: false,
    listOfCenters: [],
    costCenter: {
        codigo: 0,
        status: 0,
        descricao: '',
    },
    error: null,
}

export function costCenterReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_COSTCENTER:
            return Object.assign({}, state, {
                costCenter: { ...state.costCenter, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_COSTCENTER: 
            return Object.assign({}, state, {
                costCenter: { ...initialState.costCenter }
            })
        case FETCH_COSTCENTERS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfCenters: [],
            })
        case FETCH_COSTCENTERS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfCenters: action.payload
            })
        case FETCH_COSTCENTERS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_COSTCENTER_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //center: { ...initialState.center },
            })
        case CREATE_COSTCENTER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                costCenter: action.payload
            })
        case CREATE_COSTCENTER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_COSTCENTER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_COSTCENTER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                costCenter: action.payload
            })
        case UPDATE_COSTCENTER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_COSTCENTER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_COSTCENTER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                costCenter: action.payload
            })
        case FETCH_COSTCENTER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_COSTCENTER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_COSTCENTER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_COSTCENTER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}