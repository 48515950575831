import {

    FETCH_BILLS_TO_PAY_PENDING,
    FETCH_BILLS_TO_PAY_FULFILLED,
    FETCH_BILLS_TO_PAY_REJECTED,

    FETCH_BILL_TO_PAY_PENDING,
    FETCH_BILL_TO_PAY_FULFILLED,
    FETCH_BILL_TO_PAY_REJECTED,

    CREATE_BILL_TO_PAY_PENDING,
    CREATE_BILL_TO_PAY_FULFILLED,
    CREATE_BILL_TO_PAY_REJECTED,

    UPDATE_BILL_TO_PAY_PENDING,
    UPDATE_BILL_TO_PAY_FULFILLED,
    UPDATE_BILL_TO_PAY_REJECTED,

    DELETE_BILL_TO_PAY_PENDING,
    DELETE_BILL_TO_PAY_FULFILLED,
    DELETE_BILL_TO_PAY_REJECTED,
    
    CHANGE_BILL_TO_PAY,
    
    ADD_BILL_TO_PAY

} from './billsToPayActions'

import { titleCase } from '../../../../common/utils'

const moment = require('moment')
const initialState = {
    loading: false,
    listOfBillsToPay: [],
    billToPay: {
        codigo: 0,
        status: 0,
        dataMovimento: moment().format("DD/MM/YYYY"), 
        tipoDocunento: 0,
        numeroDocumento: '',
        dataEmissao: moment().format("DD/MM/YYYY"),
        descricao: '',
        centroCusto: 0,
        centroDeCusto: {
            descricao: '',
        },
        valor: '0,00',
        multa: '0,00',
        juros: '0,00',
        desconto: '0,00',
        dataVencimento: '',
        dataLiquidacao: '',
        valorTotal: '0,00',
        formaPagamento: 0,
        tipoPortador: 0,
        portador: 0,
        contaPortador: {
            razaoSocial: '',
        },
        contaGerencial: 0,
    },
    error: null,
}

export function billsToPayReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_BILL_TO_PAY:
            return Object.assign({}, state, {
                billToPay: { ...state.billToPay, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_BILL_TO_PAY: 
            return Object.assign({}, state, {
                billToPay: { ...initialState.billToPay }
            })
        case FETCH_BILLS_TO_PAY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfBillsToPay: [],
            })
        case FETCH_BILLS_TO_PAY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfBillsToPay: action.payload
            })
        case FETCH_BILLS_TO_PAY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_BILL_TO_PAY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //billToPay: { ...initialState.billToPay },
            })
        case CREATE_BILL_TO_PAY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                billToPay: action.payload
            })
        case CREATE_BILL_TO_PAY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_BILL_TO_PAY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_BILL_TO_PAY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                billToPay: action.payload
            })
        case UPDATE_BILL_TO_PAY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_BILL_TO_PAY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_BILL_TO_PAY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                billToPay: action.payload
            })
        case FETCH_BILL_TO_PAY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_BILL_TO_PAY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_BILL_TO_PAY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_BILL_TO_PAY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}