import {

    CREATE_TOKEN_PENDING,
    CREATE_TOKEN_FULFILLED,
    CREATE_TOKEN_REJECTED,
    
    CHANGE_LOGIN,

} from './loginActions'

const initialState = {
    loading: false,
    user: {
        login: '',
        password: '',
        remember: false,
    },
    error: null,
}

export function loginReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_LOGIN:
            return Object.assign({}, state, {
                user : { ...state.user, 
                    [action.payload.name]: action.payload.value
                }
            })
        case CREATE_TOKEN_PENDING:
            return Object.assign({}, state, {
                loading: true
            })

        case CREATE_TOKEN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                token: action.payload,
                user: { ...state.user, login: '', password: '' }
            })

        case CREATE_TOKEN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}