import api from '../../../../api'

export const FETCH_BANKS_PENDING = 'FETCH_BANKS_PENDING'
export const FETCH_BANKS_FULFILLED = 'FETCH_BANKS_FULFILLED'
export const FETCH_BANKS_REJECTED = 'FETCH_BANKS_REJECTED'

export const FETCH_BANK_PENDING = 'FETCH_BANK_PENDING'
export const FETCH_BANK_FULFILLED = 'FETCH_BANK_FULFILLED'
export const FETCH_BANK_REJECTED = 'FETCH_BANK_REJECTED'

export const CREATE_BANK_PENDING = 'CREATE_BANK_PENDING'
export const CREATE_BANK_FULFILLED = 'CREATE_BANK_FULFILLED'
export const CREATE_BANK_REJECTED = 'CREATE_BANK_REJECTED'

export const UPDATE_BANK_PENDING = 'UPDATE_BANK_PENDING'
export const UPDATE_BANK_FULFILLED = 'UPDATE_BANK_FULFILLED'
export const UPDATE_BANK_REJECTED = 'UPDATE_BANK_REJECTED'

export const DELETE_BANK_PENDING = 'DELETE_BANK_PENDING'
export const DELETE_BANK_FULFILLED = 'DELETE_BANK_FULFILLED'
export const DELETE_BANK_REJECTED = 'DELETE_BANK_REJECTED'

export const CHANGE_BANK = 'CHANGE_BANK'
export const ADD_BANK = 'ADD_BANK'

export function changeBank(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_BANK, payload: { name, value } })
  }
}
 
export function addBank() {
  return dispatch => {
    dispatch({ type: ADD_BANK });
  };
}

export function findAllBanks(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_BANKS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllBanks {
                  bancos (first: ${first} offset: ${offset}) {
                    codigo
                    cnpj
                    numero
                    nome
                    abreviatura
                    telefone
                    email  
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_BANKS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { bancos } = data
        dispatch({ type: FETCH_BANKS_FULFILLED, payload: bancos });
        resolve(bancos);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_BANKS_REJECTED, error });
      reject(error);
    });

  });

}

export function findBank(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_BANK_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findBank {
                  banco (codigo: ${codigo}) {
                    codigo
                    numero
                    cnpj
                    nome
                    abreviatura
                    telefone   
                    email
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_BANK_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { banco } = data
        dispatch({ type: FETCH_BANK_FULFILLED, payload: banco });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_BANK_REJECTED, error });
      resolve(error);
    });

  });

}

export function createBank(bank) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_BANK_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createBank {
            createBanco (input: {
              codigo: ${bank.codigo}
              numero: ${bank.numero}
              cnpj: "${bank.cnpj}"
              nome: "${bank.nome}"
              abreviatura: "${bank.abreviatura}"   
              telefone: "${bank.telefone}"
              email: "${bank.email}"
            } ) 
            {
              codigo
              numero
              cnpj
              nome
              abreviatura   
              telefone
              email
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_BANK_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createBanco } = data
        dispatch({ type: CREATE_BANK_FULFILLED, payload: createBanco });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_BANK_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateBank(bank) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_BANK_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation UpdateBank {
            updateBanco (input: {
              codigo: ${bank.codigo}
              numero: ${bank.numero}
              cnpj: "${bank.cnpj}"
              nome: "${bank.nome}"
              abreviatura: "${bank.abreviatura}"   
              telefone: "${bank.telefone}"
              email: "${bank.email}"
            } ) 
            {
              codigo
              numero
              cnpj
              nome
              abreviatura   
              telefone
              email
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_BANK_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateBanco } = data
        dispatch({ type: CREATE_BANK_FULFILLED, payload: updateBanco });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_BANK_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeBank(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_BANK_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation RemoveBank {
            deleteBanco (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_BANK_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteBanco } = data
        dispatch({ type: DELETE_BANK_FULFILLED, payload: deleteBanco });
        dispatch(findAllBanks());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_BANK_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
