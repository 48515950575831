import api from '../../../../api'

export const FETCH_PARAMETERS_PENDING = 'FETCH_PARAMETERS_PENDING'
export const FETCH_PARAMETERS_FULFILLED = 'FETCH_PARAMETERS_FULFILLED'
export const FETCH_PARAMETERS_REJECTED = 'FETCH_PARAMETERS_REJECTED'

export const FETCH_PARAMETER_PENDING = 'FETCH_PARAMETER_PENDING'
export const FETCH_PARAMETER_FULFILLED = 'FETCH_PARAMETER_FULFILLED'
export const FETCH_PARAMETER_REJECTED = 'FETCH_PARAMETER_REJECTED'

export const CREATE_PARAMETER_PENDING = 'CREATE_PARAMETER_PENDING'
export const CREATE_PARAMETER_FULFILLED = 'CREATE_PARAMETER_FULFILLED'
export const CREATE_PARAMETER_REJECTED = 'CREATE_PARAMETER_REJECTED'

export const UPDATE_PARAMETER_PENDING = 'UPDATE_PARAMETER_PENDING'
export const UPDATE_PARAMETER_FULFILLED = 'UPDATE_PARAMETER_FULFILLED'
export const UPDATE_PARAMETER_REJECTED = 'UPDATE_PARAMETER_REJECTED'

export const DELETE_PARAMETER_PENDING = 'DELETE_PARAMETER_PENDING'
export const DELETE_PARAMETER_FULFILLED = 'DELETE_PARAMETER_FULFILLED'
export const DELETE_PARAMETER_REJECTED = 'DELETE_PARAMETER_REJECTED'

export const CHANGE_PARAMETER = 'CHANGE_PARAMETER'

export const ADD_PARAMETER = 'ADD_PARAMETER'

export function changeParameter(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_PARAMETER, payload: { name, value } })
  }
}
 
export function addParameter() {
  return dispatch => {
    dispatch({ type: ADD_PARAMETER });
  };
}

export function findAllParameters(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PARAMETERS_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllParameters {
                  parameters (first: ${first} offset: ${offset}) {
                    codigo
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PARAMETERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { parameters } = data
        dispatch({ type: FETCH_PARAMETERS_FULFILLED, payload: parameters });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PARAMETERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findParameter(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PARAMETER_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findParameter {
                  parameters (codigo: ${codigo}) {
                    codigo
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PARAMETER_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { parameters } = data
        dispatch({ type: FETCH_PARAMETER_FULFILLED, payload: parameters });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PARAMETER_REJECTED, error });
      resolve(error);
    });

  });

}

export function createParameter(parameters) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PARAMETER_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation createParameter {
            createParameter (input: {
              codigo: ${parameters.codigo}
      } ) 
            {
              codigo
      }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PARAMETER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createParameter } = data
        dispatch({ type: CREATE_PARAMETER_FULFILLED, payload: createParameter });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PARAMETER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateParameter(parameters) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PARAMETER_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation updateParameter {
            updateParameter (input: {
              codigo: ${parameters.codigo}
            } ) 
            {
              codigo
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PARAMETER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateParameter } = data
        dispatch({ type: CREATE_PARAMETER_FULFILLED, payload: updateParameter });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PARAMETER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeParameter(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_PARAMETER_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation removeParameter {
            deleteParameter (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_PARAMETER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteParameter } = data
        dispatch({ type: DELETE_PARAMETER_FULFILLED, payload: deleteParameter });
        dispatch(findAllParameters());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_PARAMETER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
