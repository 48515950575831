import api from '../../../../api'

export const FETCH_COMPANIES_PENDING = 'FETCH_COMPANIES_PENDING'
export const FETCH_COMPANIES_FULFILLED = 'FETCH_COMPANIES_FULFILLED'
export const FETCH_COMPANIES_REJECTED = 'FETCH_COMPANIES_REJECTED'

export const FETCH_COMPANY_PENDING = 'FETCH_COMPANY_PENDING'
export const FETCH_COMPANY_FULFILLED = 'FETCH_COMPANY_FULFILLED'
export const FETCH_COMPANY_REJECTED = 'FETCH_COMPANY_REJECTED'

export const CREATE_COMPANY_PENDING = 'CREATE_COMPANY_PENDING'
export const CREATE_COMPANY_FULFILLED = 'CREATE_COMPANY_FULFILLED'
export const CREATE_COMPANY_REJECTED = 'CREATE_COMPANY_REJECTED'

export const UPDATE_COMPANY_PENDING = 'UPDATE_COMPANY_PENDING'
export const UPDATE_COMPANY_FULFILLED = 'UPDATE_COMPANY_FULFILLED'
export const UPDATE_COMPANY_REJECTED = 'UPDATE_COMPANY_REJECTED'

export const DELETE_COMPANY_PENDING = 'DELETE_COMPANY_PENDING'
export const DELETE_COMPANY_FULFILLED = 'DELETE_COMPANY_FULFILLED'
export const DELETE_COMPANY_REJECTED = 'DELETE_COMPANY_REJECTED'

export const CHANGE_COMPANY = 'CHANGE_COMPANY'

export const ADD_COMPANY = 'ADD_COMPANY'

export function changeCompany(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_COMPANY, payload: { name, value } })
  }
}

export function addCompany() {
  return dispatch => {
    dispatch({ type: ADD_COMPANY });
  };
}

export function findAllCompanies(first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_COMPANIES_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllCompanies {
                  empresas (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    cpfCnpj
                    razaoSocial
                    nomeFantasia
                    celular
                    fixo
                    ramal
                    email
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_COMPANIES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { empresas } = data
        dispatch({ type: FETCH_COMPANIES_FULFILLED, payload: empresas });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_COMPANIES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findCompany(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_COMPANY_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findCompany {
                  empresa (codigo: ${codigo}) {
                    codigo
                    status
                    tipoEmpresa
                    dataAbertura
                    cpfCnpj
                    razaoSocial
                    nomeFantasia
                    numeroCNAE
                    numeroIE
                    numeroIM
                    tipoRegime
                    tipoEndereco
                    endereco
                    numero
                    complemento
                    cep
                    bairro
                    estado
                    municipio
                    celular
                    fixo
                    ramal
                    responsavel
                    email
                    homePage
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_COMPANY_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { empresa } = data
        dispatch({ type: FETCH_COMPANY_FULFILLED, payload: empresa });
        resolve(empresa);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_COMPANY_REJECTED, error });
      resolve(error);
    });

  });

}

export function createCompany(company) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_COMPANY_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createCompany {
            createEmpresa (input: {
              codigo: ${company.codigo}
              status: ${company.status}
              tipoEmpresa: "${company.tipoEmpresa}"
              dataAbertura: "${company.dataAbertura}"
              cpfCnpj: "${company.cpfCnpj}"
              razaoSocial: "${company.razaoSocial}"
              nomeFantasia: "${company.nomeFantasia}"
              numeroCNAE: "${company.numeroCNAE}"
              numeroIE: "${company.numeroIE}"
              numeroIM: "${company.numeroIM}"
              tipoRegime: ${company.tipoRegime}
              tipoEndereco: "${company.tipoEndereco}"
              endereco: "${company.endereco}"
              numero: "${company.numero}"
              complemento: "${company.complemento}"
              cep: "${company.cep}"
              bairro: "${company.bairro}"
              estado: ${company.estado}
              municipio: ${company.municipio}
              celular: "${company.celular}"
              fixo: "${company.fixo}"
              ramal: "${company.ramal}"
              responsavel: "${company.responsavel}"
              email: "${company.email}"
              homePage: "${company.homePage}"
            } ) {
              codigo
              status
              tipoEmpresa
              dataAbertura
              cpfCnpj
              razaoSocial
              nomeFantasia
              numeroCNAE
              numeroIE
              numeroIM
              tipoRegime
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              fixo
              ramal
              responsavel
              email
              homePage
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_COMPANY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createEmpresa } = data
        dispatch({ type: CREATE_COMPANY_FULFILLED, payload: createEmpresa });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_COMPANY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateCompany(company) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_COMPANY_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateCompany {
            updateEmpresa (input: {
              codigo: ${company.codigo}
              status: ${company.status}
              tipoEmpresa: "${company.tipoEmpresa}"
              dataAbertura: "${company.dataAbertura}"
              cpfCnpj: "${company.cpfCnpj}"
              razaoSocial: "${company.razaoSocial}"
              nomeFantasia: "${company.nomeFantasia}"
              numeroCNAE: "${company.numeroCNAE}"
              numeroIE: "${company.numeroIE}"
              numeroIM: "${company.numeroIM}"
              tipoRegime: ${company.tipoRegime}
              tipoEndereco: "${company.tipoEndereco}"
              endereco: "${company.endereco}"
              numero: "${company.numero}"
              complemento: "${company.complemento}"
              cep: "${company.cep}"
              bairro: "${company.bairro}"
              estado: ${company.estado}
              municipio: ${company.municipio}
              celular: "${company.celular}"
              fixo: "${company.fixo}"
              ramal: "${company.ramal}"
              responsavel: "${company.responsavel}"
              email: "${company.email}"
              homePage: "${company.homePage}"
            } ) {
              codigo
              status
              tipoEmpresa
              dataAbertura
              cpfCnpj
              razaoSocial
              nomeFantasia
              numeroCNAE
              numeroIE
              numeroIM
              tipoRegime
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              fixo
              ramal
              responsavel
              email
              homePage
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_COMPANY_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateEmpresa } = data
        dispatch({ type: UPDATE_COMPANY_FULFILLED, payload: updateEmpresa });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_COMPANY_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeCompany(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_COMPANY_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveCompany {
            deleteEmpresa (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_COMPANY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteEmpresa } = data
        dispatch({ type: DELETE_COMPANY_FULFILLED, payload: deleteEmpresa });
        dispatch(findAllCompanies());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_COMPANY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
