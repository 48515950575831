import moment from "moment";
import api from '../../api';

export const TOGGLE_NEW_TODO = "TOGGLE_NEW_TODO";
export const TOGGLE_TODO = "TOGGLE_TODO";
export const TOGGLE_CLOSE_TODO = "TOGGLE_CLOSE_TODO";

export const UPDATE_TODO = "UPDATE_TODO";
export const DELETE_TODO = "DELETE_TODO";
export const CLOSE_TODO = "CLOSE_TODO";

export const FETCH_DATA_PENDING = 'FETCH_DATA_PENDING';
export const FETCH_DATA_FULFILLED = 'FETCH_DATA_FULFILLED';
export const FETCH_DATA_REJECTED = 'FETCH_DATA_REJECTED';

export const FETCH_CREATE_DATA_PENDING = 'FETCH_CREATE_DATA_PENDING';
export const FETCH_CREATE_DATA_FULFILLED = 'FETCH_CREATE_DATA_FULFILLED';
export const FETCH_CREATE_DATA_REJECTED = 'FETCH_CREATE_DATA_REJECTED';

export const FETCH_CLOSE_DATA_PENDING = 'FETCH_CLOSE_DATA_PENDING';
export const FETCH_CLOSE_DATA_FULFILLED = 'FETCH_CLOSE_DATA_FULFILLED';
export const FETCH_CLOSE_DATA_REJECTED = 'FETCH_CLOSE_DATA_REJECTED';

export function toggleNewTodo() {
  return {
    type: TOGGLE_NEW_TODO
  };
}

export function toggleCloseTodo(todo) {
  return {
    type: TOGGLE_CLOSE_TODO,
    payload: todo
  };
}

export function toggleTodo(todo) {
  if (todo.completedAt) {
    todo.completedAt = null;
    todo.type = "Important";
  } else {
    todo.completedAt = moment();
    todo.type = "Completed";
  }
  return {
    type: TOGGLE_TODO,
    payload: todo
  };
}

export function updateTodo(todo) {
  switch (todo.type) {
    default:
    case "Critical":
      todo.completedAt = null;
      break;
    case "Important":
      todo.completedAt = null;
      break;
    case "Completed":
      todo.completedAt = moment();
      break;
  }
  return {
    type: UPDATE_TODO,
    payload: todo
  };
}

export function deleteTodo(todo) {
  return {
    type: DELETE_TODO,
    payload: todo
  };
}


export function findAllTechnicalCalls(matriz) {

  return dispatch => new Promise((resolve, reject) => {

      dispatch({ type: FETCH_DATA_PENDING });

      api({
          method: 'post',        
          data: {
              query: `
        query findAllTechnicalCalls {
          chamados (matriz: ${matriz} first: 20 offset: 0) {
              codigo
              titulo
              descricao
              classificacao
              arquivo
              createdAt
              updatedAt
          }
        }
        `
          }
      }).then((result) => {
          const { errors, data } = result.data
          if (errors) {
              dispatch({ type: FETCH_DATA_REJECTED, payload: errors });
              resolve(errors);
          } else {
              const { chamados } = data
              dispatch({ type: FETCH_DATA_FULFILLED, payload: chamados });
              resolve(data);
          }
      }).catch((error) => {
          dispatch({ type: FETCH_DATA_REJECTED, error });
          resolve(error);
      });

  });

}

export function createTechnicalCall(input) {

  return dispatch => new Promise((resolve, reject) => {

      dispatch({ type: FETCH_CREATE_DATA_PENDING });

      api({
          method: 'post',      
          data: {
              query: `
                      mutation createTechnicalCall {
                        createChamado(input: {
                          codigo: 0
                          matriz: 1
                          titulo: "${input.title}"
                          descricao: "${input.description}"
                          classificacao: ${input.type}
                          arquivo: "${input.archive}"
                          usuarioAbertura: 1
                        }) {
                            codigo
                            titulo
                            descricao
                            classificacao
                            arquivo
                            createdAt
                            updatedAt
                        }
                      }
                      `
          }
      }).then((result) => {
          const { errors, data } = result.data
          if (errors) {
              dispatch({ type: FETCH_CREATE_DATA_REJECTED, payload: errors });
              resolve(errors);
          } else {
              const { createChamado } = data
              dispatch({ type: FETCH_CREATE_DATA_FULFILLED, payload: createChamado });
              resolve(data);
          }
      }).catch((error) => {
          dispatch({ type: FETCH_CREATE_DATA_REJECTED, error });
          resolve(error);
      });

  });

}

export function closeTechnicalCall(input) {

  return dispatch => new Promise((resolve, reject) => {

      dispatch({ type: FETCH_CLOSE_DATA_PENDING });

      api({
          method: 'post',        
          data: {
              query: `
                      mutation closeTechnicalCall {
                        closeChamado(input: {
                          codigo: ${input.id}
                          resolucao: "${input.resolution}"
                          usuarioFechamento: 1,
                          classificacao: 9
                        }) {
                            codigo
                            titulo
                            descricao
                            classificacao
                            arquivo
                            createdAt
                            updatedAt
                        }
                      }
                      `
          }
      }).then((result) => {
          const { errors, data } = result.data
          if (errors) {
              dispatch({ type: FETCH_CLOSE_DATA_REJECTED, payload: errors });
              resolve(errors);
          } else {
              const { closeChamado } = data
              dispatch({ type: FETCH_CLOSE_DATA_FULFILLED, payload: closeChamado });
              resolve(data);
          }
      }).catch((error) => {
          dispatch({ type: FETCH_CLOSE_DATA_REJECTED, error });
          resolve(error);
      });

  });

}