import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const SaleHistory = Loadable({
  loader: () => import("./components/SaleHistory"),
  loading: Loading
});

export const routes = [
  {
    path: "/historical/sale",
    exact: true,
    component: SaleHistory,
    name: "Sale History"
  },

];
