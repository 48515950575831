import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

// ---------------------- MÓDULO DE CADASTROS ------------------------ //

const ListProfession = Loadable({
  loader: () => import("./profession/ListProfession"),
  loading: Loading
});

const ListCompany = Loadable({
  loader: () => import("./company/ListCompany"),
  loading: Loading
});

const FormCompany = Loadable({
  loader: () => import("./company/FormCompany"),
  loading: Loading
});

const ListAccredited = Loadable({
  loader: () => import("./accredited/ListAccredited"),
  loading: Loading
});

const FormAccredited = Loadable({
  loader: () => import("./accredited/FormAccredited"),
  loading: Loading
});

const ListProvider = Loadable({
  loader: () => import("./provider/ListProvider"),
  loading: Loading
});

const FormProvider = Loadable({
  loader: () => import("./provider/FormProvider"),
  loading: Loading
});

const ListPatient = Loadable({
  loader: () => import("./patient/ListPatient"),
  loading: Loading
});

const FormPatient = Loadable({
  loader: () => import("./patient/FormPatient"),
  loading: Loading
});

const FormDependent = Loadable({
  loader: () => import("./patient/dependents/FormDependent"),
  loading: Loading
});

const FormProfession = Loadable({
  loader: () => import("./profession/FormProfession"),
  loading: Loading
});

const ListClassOrgan = Loadable({
  loader: () => import("./classOrgan/ListClassOrgan"),
  loading: Loading
});

const FormClassOrgan = Loadable({
  loader: () => import("./classOrgan/FormClassOrgan"),
  loading: Loading
});

const ListSpecialty = Loadable({
  loader: () => import("./specialty/ListSpecialty"),
  loading: Loading
});

const FormSpecialty = Loadable({
  loader: () => import("./specialty/FormSpecialty"),
  loading: Loading
});

const ListHealthPlan = Loadable({
  loader: () => import("./healthPlan/ListHealthPlan"),
  loading: Loading
});

const FormHealthPlan = Loadable({
  loader: () => import("./healthPlan/FormHealthPlan"),
  loading: Loading
});

const ListPlanModality = Loadable({
  loader: () => import("./healthPlan/modality/ListPlanModality"),
  loading: Loading
});

const FormPlanModality = Loadable({
  loader: () => import("./healthPlan/modality/FormPlanModality"),
  loading: Loading
});

const ListGroup = Loadable({
  loader: () => import("./procedure/group/ListGroup"),
  loading: Loading
});

const FormGroup = Loadable({
  loader: () => import("./procedure/group/FormGroup"),
  loading: Loading
});

const ListSubGroup = Loadable({
  loader: () => import("./procedure/subGroup/ListSubGroup"),
  loading: Loading
});

const FormSubGroup = Loadable({
  loader: () => import("./procedure/subGroup/FormSubGroup"),
  loading: Loading
});

const ListShipping = Loadable({
  loader: () => import("./procedure/shipping/ListShipping"),
  loading: Loading
});

const FormShipping = Loadable({
  loader: () => import("./procedure/shipping/FormShipping"),
  loading: Loading
});

const ListProcedure = Loadable({
  loader: () => import("./procedure/ListProcedure"),
  loading: Loading
});

const FormProcedure = Loadable({
  loader: () => import("./procedure/FormProcedure"),
  loading: Loading
});

const ListSalesPeople = Loadable({
  loader: () => import("./salesPeople/ListSalesPeople"),
  loading: Loading
});

const FormSalesPeople = Loadable({
  loader: () => import("./salesPeople/FormSalesPeople"),
  loading: Loading
});

const ListSupplier = Loadable({
  loader: () => import("./supplier/ListSupplier"),
  loading: Loading
});

const FormSupplier = Loadable({
  loader: () => import("./supplier/FormSupplier"),
  loading: Loading
});


// ---------------------- MÓDULO FINANCEIRO ------------------------ //

const FormBillsToPay = Loadable({
  loader: () => import("./billsToPay/FormBillsToPay"),
  loading: Loading
});

const FormLowBillsToPay = Loadable({
  loader: () => import("./billsToPay/FormLowBillsToPay"),
  loading: Loading
});

const ListBillsToPay = Loadable({
  loader: () => import("./billsToPay/ListBillsToPay"),
  loading: Loading
});

const FormBillsToReceive = Loadable({
  loader: () => import("./billsToReceive/FormBillsToReceive"),
  loading: Loading
});

const ListBillsToReceive = Loadable({
  loader: () => import("./billsToReceive/ListBillsToReceive"),
  loading: Loading
});

const FormLowBillsToReceive = Loadable({
  loader: () => import("./billsToReceive/FormLowBillsToReceive"),
  loading: Loading
});

const ListBank = Loadable({
  loader: () => import("./bank/ListBank"),
  loading: Loading
});

const FormBank = Loadable({
  loader: () => import("./bank/FormBank"),
  loading: Loading
});

const ListBankAccount = Loadable({
  loader: () => import("./bankAccount/ListBankAccount"),
  loading: Loading
});

const FormBankAccount = Loadable({
  loader: () => import("./bankAccount/FormBankAccount"),
  loading: Loading
});

const ListCashier = Loadable({
  loader: () => import("./cashier/ListCashier"),
  loading: Loading
});

const FormCashier = Loadable({
  loader: () => import("./cashier/FormCashier"),
  loading: Loading
});

const ListCashierMovement = Loadable({
  loader: () => import("./cashierMovement/ListCashierMovement"),
  loading: Loading
});

const FormCashierMovement = Loadable({
  loader: () => import("./cashierMovement/FormCashierMovement"),
  loading: Loading
});

const ListCashierOpeningAndClosing = Loadable({
  loader: () => import("./cashierOpenAndClose/ListCashierOpenAndClose"),
  loading: Loading
});

const FormCashierOpening = Loadable({
  loader: () => import("./cashierOpenAndClose/FormCashierOpening"),
  loading: Loading
});

const FormCashierClosing = Loadable({
  loader: () => import("./cashierOpenAndClose/FormCashierClosing"),
  loading: Loading
});

const FormCheck = Loadable({
  loader: () => import("./check/FormCheck"),
  loading: Loading
});

const ListCheck = Loadable({
  loader: () => import("./check/ListCheck"),
  loading: Loading
});

const FormPaymentMethods = Loadable({
  loader: () => import("./paymentMethods/FormPaymentMethods"),
  loading: Loading
});

const ListPaymentMethods = Loadable({
  loader: () => import("./paymentMethods/ListPaymentMethods"),
  loading: Loading
});

const FormCostCenter = Loadable({
  loader: () => import("./costCenter/FormCostCenter"),
  loading: Loading
});

const ListCostCenter = Loadable({
  loader: () => import("./costCenter/ListCostCenter"),
  loading: Loading
});

const FormManagementPlan = Loadable({
  loader: () => import("./managementPlan/FormManagementPlan"),
  loading: Loading
});

const ListManagementPlan = Loadable({
  loader: () => import("./managementPlan/ListManagementPlan"),
  loading: Loading
});

// ---------------------- MÓDULO CAMPANHAS ------------------------ //

const ListCampaign = Loadable({
  loader: () => import("./campaign/ListCampaign"),
  loading: Loading
});

const FormCampaign = Loadable({
  loader: () => import("./campaign/FormCampaign"),
  loading: Loading
});


// ---------------------- MÓDULO ESTOQUES ------------------------ //

const ListGroupProduct = Loadable({
  loader: () => import("./product/group/ListGroupProduct"),
  loading: Loading
});

const FormGroupProduct = Loadable({
  loader: () => import("./product/group/FormGroupProduct"),
  loading: Loading
});

const ListSubGroupProduct = Loadable({
  loader: () => import("./product/subGroup/ListSubGroupProduct"),
  loading: Loading
});

const FormSubGroupProduct = Loadable({
  loader: () => import("./product/subGroup/FormSubGroupProduct"),
  loading: Loading
});

const ListNcmProduct = Loadable({
  loader: () => import("./product/ncm/ListNcmProduct"),
  loading: Loading
});

const FormNcmProduct = Loadable({
  loader: () => import("./product/ncm/FormNcmProduct"),
  loading: Loading
});

const ListProduct = Loadable({
  loader: () => import("./product/ListProduct"),
  loading: Loading
});

const FormProduct = Loadable({
  loader: () => import("./product/FormProduct"),
  loading: Loading
});


// ---------------------- PARÂMETROS ------------------------ //

const FormParameters = Loadable({
  loader: () => import("./parameters/FormParameters"),
  loading: Loading
});


const ListAvailableSchedule = Loadable({
  loader: () => import("./availableSchedule/ListAvailableSchedule"),
  loading: Loading
});

const FormAvailableSchedule = Loadable({
  loader: () => import("./availableSchedule/FormAvailableSchedule"),
  loading: Loading
});

const ListCustomer = Loadable({
  loader: () => import("./customer/ListCustomer"),
  loading: Loading
});

const FormCustomer = Loadable({
  loader: () => import("./customer/FormCustomer"),
  loading: Loading
});

const ListSale = Loadable({
  loader: () => import("./sale/ListSale"),
  loading: Loading
});

const FormSale = Loadable({
  loader: () => import("./sale/FormSale"),
  loading: Loading
});

const ListUserProfile = Loadable({
  loader: () => import("./user/userType/ListUserType"),
  loading: Loading
});

const FormUserProfile = Loadable({
  loader: () => import("./user/userType/FormUserType"),
  loading: Loading
});

const FormListUser = Loadable({
  loader: () => import("./user/ListUser"),
  loading: Loading
});

const FormUser = Loadable({
  loader: () => import("./user/FormUser"),
  loading: Loading
});

//-----------------------------------------------------------------------------

const FormElements = Loadable({
  loader: () => import("./components/FormElements"),
  loading: Loading
});

const FormLayouts = Loadable({
  loader: () => import("./components/layouts/FormLayouts"),
  loading: Loading
});

const FormValidation = Loadable({
  loader: () => import("./components/FormValidation"),
  loading: Loading
});

const BootstrapFormElements = Loadable({
  loader: () => import("./components/BootstrapFormElements"),
  loading: Loading
});

const BootstrapValidation = Loadable({
  loader: () => import("./components/bootstrap-validation/BootstrapValidation"),
  loading: Loading
});

const FormPlugins = Loadable({
  loader: () => import("./components/form-plugins/FormPlugins"),
  loading: Loading
});

const Wizards = Loadable({
  loader: () => import("./components/wizards/Wizards"),
  loading: Loading
});

const DropzoneDemo = Loadable({
  loader: () => import("./components/DropzoneDemo"),
  loading: Loading
});

const BootstrapEditors = Loadable({
  loader: () => import("./components/BootstrapEditors"),
  loading: Loading
});

const ImageCropping = Loadable({
  loader: () => import("./components/ImageCropping"),
  loading: Loading
});

export const routes = [

  // ---------------------- PROCEDURES ------------------------ //
  {
    path: "/forms/listGroup",
    exact: true,
    component: ListGroup,
    name: "List Group Of Procedures"
  },
  {
    path: "/forms/formGroup",
    exact: true,
    component: FormGroup,
    name: "Form Group Of Procedures"
  },

  {
    path: "/forms/listSubGroup",
    exact: true,
    component: ListSubGroup,
    name: "List SubGroup Of Procedures"
  },
  {
    path: "/forms/formSubGroup",
    exact: true,
    component: FormSubGroup,
    name: "Form SubGroup Of Procedures"
  },

  {
    path: "/forms/listShipping",
    exact: true,
    component: ListShipping,
    name: "List Shipping Of Procedures"
  },
  {
    path: "/forms/formShipping",
    exact: true,
    component: FormShipping,
    name: "Form Shipping Of Procedures"
  },

  {
    path: "/forms/listProcedure",
    exact: true,
    component: ListProcedure,
    name: "List Procedures"
  },
  {
    path: "/forms/formProcedure",
    exact: true,
    component: FormProcedure,
    name: "Form Procedure"
  },

  {
    path: "/forms/listBank",
    exact: true,
    component: ListBank,
    name: "List Banks"
  },
  {
    path: "/forms/formBank",
    exact: true,
    component: FormBank,
    name: "Form Bank"
  },

  {
    path: "/forms/listProfession",
    exact: true,
    component: ListProfession,
    name: "List Professions"
  },
  {
    path: "/forms/formProfession",
    exact: true,
    component: FormProfession,
    name: "Form Profession"
  },
  {
    path: "/forms/listBankAccount",
    exact: true,
    component: ListBankAccount,
    name: "List Bank Account"
  },
  {
    path: "/forms/formBankAccount",
    exact: true,
    component: FormBankAccount,
    name: "Form Bank Account"
  },
  {
    path: "/forms/listCashier",
    exact: true,
    component: ListCashier,
    name: "List Cashier"
  },
  {
    path: "/forms/formCashier",
    exact: true,
    component: FormCashier,
    name: "Form Cashier"
  },
  {
    path: "/forms/listCashierMovement",
    exact: true,
    component: ListCashierMovement,
    name: "List Cashier"
  },
  {
    path: "/forms/formCashierMovement",
    exact: true,
    component: FormCashierMovement,
    name: "Form Cashier"
  },
  {
    path: "/forms/listCashierOpening",
    exact: true,
    component: ListCashierOpeningAndClosing,
    name: "List Cashier Opening"
  },
  {
    path: "/forms/formCashierOpening",
    exact: true,
    component: FormCashierOpening,
    name: "Form Cashier Opening"
  },
  {
    path: "/forms/formCashierClosing",
    exact: true,
    component: FormCashierClosing,
    name: "Form Cashier Closing"
  },
  {
    path: "/forms/listCostCenter",
    exact: true,
    component: ListCostCenter,
    name: "List Cost Center"
  },
  {
    path: "/forms/formCostCenter",
    exact: true,
    component: FormCostCenter,
    name: "Form Cost Center"
  },
  {
    path: "/forms/listCheck",
    exact: true,
    component: ListCheck,
    name: "List Check"
  },
  {
    path: "/forms/formCheck",
    exact: true,
    component: FormCheck,
    name: "Form Check"
  },
  {
    path: "/forms/listPaymentMethods",
    exact: true,
    component: ListPaymentMethods,
    name: "List Payment Methods"
  },
  {
    path: "/forms/formPaymentMethods",
    exact: true,
    component: FormPaymentMethods,
    name: "Form Payment Methods"
  },
  {
    path: "/forms/listManagementPlan",
    exact: true,
    component: ListManagementPlan,
    name: "List Management Plan"
  },
  {
    path: "/forms/formManagementPlan",
    exact: true,
    component: FormManagementPlan,
    name: "Form Management Plan"
  },

  // ---------------------- MÓDULO ESTOQUE ------------------------ //
 
  {
    path: "/forms/product/group/listGroupProduct",
    exact: true,
    component: ListGroupProduct,
    name: "List Group Product"
  },
  {
    path: "/forms/product/group/formGroupProduct",
    exact: true,
    component: FormGroupProduct,
    name: "Form Group Product"
  },

  {
    path: "/forms/product/subGroup/listSubGroupProduct",
    exact: true,
    component: ListSubGroupProduct,
    name: "List SubGroup Product"
  },
  {
    path: "/forms/product/subGroup/formSubGroupProduct",
    exact: true,
    component: FormSubGroupProduct,
    name: "Form SubGroup Product"
  },

  {
    path: "/forms/product/ncm/listNcmProduct",
    exact: true,
    component: ListNcmProduct,
    name: "List NCM Product"
  },
  {
    path: "/forms/product/ncm/formNcmProduct",
    exact: true,
    component: FormNcmProduct,
    name: "Form NCM Product"
  },
  
  {
    path: "/forms/product/listProduct",
    exact: true,
    component: ListProduct,
    name: "List Product"
  },
  {
    path: "/forms/product/formProduct",
    exact: true,
    component: FormProduct,
    name: "Form Product"
  },
  

  // ---------------------- PROCEDURES ------------------------ //
  {
    path: "/forms/listClassOrgan",
    exact: true,
    component: ListClassOrgan,
    name: "List Class Organ"
  },
  {
    path: "/forms/formClassOrgan",
    exact: true,
    component: FormClassOrgan,
    name: "Form Class Organ"
  },

  {
    path: "/forms/listSpecialty",
    exact: true,
    component: ListSpecialty,
    name: "List Specialty"
  },
  {
    path: "/forms/formSpecialty",
    exact: true,
    component: FormSpecialty,
    name: "Form Specialty"
  },

  {
    path: "/forms/listHealthPlan",
    exact: true,
    component: ListHealthPlan,
    name: "List Health Plans"
  },
  {
    path: "/forms/formHealthPlan",
    exact: true,
    component: FormHealthPlan,
    name: "Form Health Plan"
  },

  {
    path: "/forms/healthPlan/listPlanModality",
    exact: true,
    component: ListPlanModality,
    name: "List Plan Modality"
  },
  {
    path: "/forms/healthPlan/formPlanModality",
    exact: true,
    component: FormPlanModality,
    name: "Form Plan Modality"
  },

  {
    path: "/forms/listSalesPeople",
    exact: true,
    component: ListSalesPeople,
    name: "List Sales People"
  },
  {
    path: "/forms/formSalesPeople",
    exact: true,
    component: FormSalesPeople,
    name: "Form Sales People"
  },

  {
    path: "/forms/formSupplier",
    exact: true,
    component: FormSupplier,
    name: "Form Supplier"
  },
  {
    path: "/forms/listSupplier",
    exact: true,
    component: ListSupplier,
    name: "List Supplier"
  },

  {
    path: "/forms/listCompany",
    exact: true,
    component: ListCompany,
    name: "List Company"
  },

  {
    path: "/forms/formCompany",
    exact: true,
    component: FormCompany,
    name: "Form Company"
  },

  {
    path: "/forms/listAccredited",
    exact: true,
    component: ListAccredited,
    name: "List Accredited"
  },

  {
    path: "/forms/formAccredited",
    exact: true,
    component: FormAccredited,
    name: "Form Accredited"
  },

  {
    path: "/forms/listProvider",
    exact: true,
    component: ListProvider,
    name: "List Provider"
  },

  {
    path: "/forms/formProvider",
    exact: true,
    component: FormProvider,
    name: "Form Provider"
  },

  {
    path: "/forms/listPatient",
    exact: true,
    component: ListPatient,
    name: "List Patient"
  },

  {
    path: "/forms/formPatient",
    exact: true,
    component: FormPatient,
    name: "Form Patient"
  },

  {
    path: "/forms/formDependent",
    exact: true,
    component: FormDependent,
    name: "Form Dependent"
  },

  // ---------------------- MÓDULO FINANCEIRO ------------------------ //

  {
    path: "/forms/formParameters",
    exact: true,
    component: FormParameters,
    name: "Form Parameters"
  },

  {
    path: "/forms/listBillsToPay",
    exact: true,
    component: ListBillsToPay,
    name: "List Bills To Pay"
  },

  {
    path: "/forms/formBillsToPay",
    exact: true,
    component: FormBillsToPay,
    name: "Form Bills To Pay"
  },

  {
    path: "/forms/lowBillsToPay",
    exact: true,
    component: FormLowBillsToPay,
    name: "Form Low Bills To Pay"
  },

  {
    path: "/forms/listBillsToReceive",
    exact: true,
    component: ListBillsToReceive,
    name: "List Bills To Receive"
  },

  {
    path: "/forms/formBillsToReceive",
    exact: true,
    component: FormBillsToReceive,
    name: "Form Bills To Receive"
  },

  {
    path: "/forms/lowBillsToReceive",
    exact: true,
    component: FormLowBillsToReceive,
    name: "Form Low Bills To Receive"
  },

  {
    path: "/forms/listAvailableSchedule",
    exact: true,
    component: ListAvailableSchedule,
    name: "List Available Schedule"
  },
  {
    path: "/forms/formAvailableSchedule",
    exact: true,
    component: FormAvailableSchedule,
    name: "Form Available Schedule"
  },
  // ---------------------- PROCEDURES ------------------------ //
  {
    path: "/forms/listcustomer",
    exact: true,
    component: ListCustomer,
    name: "List Customer"
  },
  {
    path: "/forms/customer",
    exact: true,
    component: FormCustomer,
    name: "Form Customer"
  },

  {
    path: "/listUserProfile",
    exact: true,
    component: ListUserProfile,
    name: "Form User Profile"
  },

  {
    path: "/forms/formUserProfile",
    exact: true,
    component: FormUserProfile,
    name: "Form User Profile"
  },
  
  {
    path: "/listUsers",
    exact: true,
    component: FormListUser,
    name: "List User"
  },

  {
    path: "/forms/user",
    exact: true,
    component: FormUser,
    name: "Form User"
  },
  {
    path: "/forms/listsale",
    exact: true,
    component: ListSale,
    name: "List Sale"
  },
  {
    path: "/forms/sale",
    exact: true,
    component: FormSale,
    name: "Form Sale"
  },

  // ---------------------- MÓDULO CAMPANHAS ------------------------ //
 
  {
    path: "/forms/listCampaign",
    exact: true,
    component: ListCampaign,
    name: "List Campaign"
  },
  {
    path: "/forms/formCampaign",
    exact: true,
    component: FormCampaign,
    name: "Form Campaign"
  },

  //-----------------------------------------------------------------------------
  {
    path: "/forms/elements",
    exact: true,
    component: FormElements,
    name: "Form Elements"
  },
  {
    path: "/forms/layouts",
    exact: true,
    component: FormLayouts,
    name: "Form Layouts"
  },
  {
    path: "/forms/form-validation",
    exact: true,
    component: FormValidation,
    name: "Form Validation"
  },
  {
    path: "/forms/bootstrap-form-elements",
    exact: true,
    component: BootstrapFormElements,
    name: "Bootstrap Form Elements"
  },
  {
    path: "/forms/bootstrap-validation",
    exact: true,
    component: BootstrapValidation,
    name: "Bootstrap Valliidation"
  },
  {
    path: "/forms/plugins",
    exact: true,
    component: FormPlugins,
    name: "Form Plugins"
  },
  {
    path: "/forms/wizards",
    exact: true,
    component: Wizards,
    name: "Wizards"
  },
  {
    path: "/forms/dropzone",
    exact: true,
    component: DropzoneDemo,
    name: "Dropzone"
  },
  {
    path: "/forms/bootstrap-editors",
    exact: true,
    component: BootstrapEditors,
    name: "Bootstrap Editors"
  },
  {
    path: "/forms/image-editor",
    exact: true,
    component: ImageCropping,
    name: "Image Cropping"
  }
];
