import api from '../../../../api'
import { toFloat } from '../../../../common/utils'

export const FETCH_CHECKS_PENDING = 'FETCH_CHECKS_PENDING'
export const FETCH_CHECKS_FULFILLED = 'FETCH_CHECKS_FULFILLED'
export const FETCH_CHECKS_REJECTED = 'FETCH_CHECKS_REJECTED'

export const FETCH_CHECK_PENDING = 'FETCH_CHECK_PENDING'
export const FETCH_CHECK_FULFILLED = 'FETCH_CHECK_FULFILLED'
export const FETCH_CHECK_REJECTED = 'FETCH_CHECK_REJECTED'

export const CREATE_CHECK_PENDING = 'CREATE_CHECK_PENDING'
export const CREATE_CHECK_FULFILLED = 'CREATE_CHECK_FULFILLED'
export const CREATE_CHECK_REJECTED = 'CREATE_CHECK_REJECTED'

export const UPDATE_CHECK_PENDING = 'UPDATE_CHECK_PENDING'
export const UPDATE_CHECK_FULFILLED = 'UPDATE_CHECK_FULFILLED'
export const UPDATE_CHECK_REJECTED = 'UPDATE_CHECK_REJECTED'

export const DELETE_CHECK_PENDING = 'DELETE_CHECK_PENDING'
export const DELETE_CHECK_FULFILLED = 'DELETE_CHECK_FULFILLED'
export const DELETE_CHECK_REJECTED = 'DELETE_CHECK_REJECTED'

export const CHANGE_CHECK = 'CHANGE_CHECK'

export const ADD_CHECK = 'ADD_CHECK'

export function changeCheck(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_CHECK, payload: { name, value } })
  }
}
 
export function addCheck() {
  return dispatch => {
    dispatch({ type: ADD_CHECK });
  };
}

export function findAllChecks(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CHECKS_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
                query findAllChecks {
                  cheques (first: ${first} offset: ${offset}) {
                    codigo
                    situacao
                    emissao
                    numero
                    valor
                    predatado
                    deposito
                    banco
                    agencia
                    conta
                    proprio
                    titular
                    emissor
                    contaEmissor {
                      razaoSocial
                    }  
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CHECKS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { cheques } = data
        dispatch({ type: FETCH_CHECKS_FULFILLED, payload: cheques });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CHECKS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findCheck(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CHECK_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findCheck {
                  cheque (codigo: ${codigo}) {
                    codigo
                    situacao
                    emissao
                    numero
                    valor
                    predatado
                    deposito
                    banco
                    agencia
                    conta
                    proprio
                    titular
                    tipoPortador
                    emissor
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CHECK_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { cheque } = data
        dispatch({ type: FETCH_CHECK_FULFILLED, payload: cheque });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CHECK_REJECTED, error });
      resolve(error);
    });

  });

}

export function createCheck(check) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CHECK_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createCheck {
            createCheque (input: {
              codigo: ${check.codigo}
              situacao: ${check.situacao}
              emissao: "${check.emissao}"
              numero: ${check.numero}
              valor: ${toFloat(check.valor)}
              predatado: "${check.predatado}"
              banco: ${check.banco}
              agencia: "${check.agencia}"
              conta: "${check.conta}"
              proprio: ${check.proprio}
              titular: "${check.titular}"
              tipoPortador: ${check.tipoPortador}
              emissor: ${check.emissor}
            } ) 
            {
              codigo
              situacao
              emissao
              numero
              valor
              predatado
              deposito
              banco
              agencia
              conta
              proprio
              titular
              tipoPortador
              emissor
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CHECK_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createCheque } = data
        dispatch({ type: CREATE_CHECK_FULFILLED, payload: createCheque });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CHECK_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateCheck(check) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CHECK_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation UpdateCheck {
            updateCheque (input: {
              codigo: ${check.codigo}
              situacao: ${check.situacao}
              emissao: "${check.emissao}"
              numero: ${check.numero}
              valor: ${toFloat(check.valor)}
              predatado: "${check.predatado}"
              banco: ${check.banco}
              agencia: "${check.agencia}"
              conta: "${check.conta}"
              proprio: ${check.proprio}
              titular: "${check.titular}"
              tipoPortador: ${check.tipoPortador}
              emissor: ${check.emissor}
            } ) 
            {
              codigo
              situacao
              emissao
              numero
              valor
              predatado
              deposito
              banco
              agencia
              conta
              proprio
              titular
              tipoPortador
              emissor
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CHECK_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateCheque } = data
        dispatch({ type: CREATE_CHECK_FULFILLED, payload: updateCheque });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CHECK_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeCheck(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_CHECK_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation RemoveCheck {
            deteleCheque (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_CHECK_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deteleCheque } = data
        dispatch({ type: DELETE_CHECK_FULFILLED, payload: deteleCheque });
        dispatch(findAllChecks());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_CHECK_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
