import {

    FETCH_CLASS_ORGANS_PENDING,
    FETCH_CLASS_ORGANS_FULFILLED,
    FETCH_CLASS_ORGANS_REJECTED,

    FETCH_CLASS_ORGAN_PENDING,
    FETCH_CLASS_ORGAN_FULFILLED,
    FETCH_CLASS_ORGAN_REJECTED,

    CREATE_CLASS_ORGAN_PENDING,
    CREATE_CLASS_ORGAN_FULFILLED,
    CREATE_CLASS_ORGAN_REJECTED,

    UPDATE_CLASS_ORGAN_PENDING,
    UPDATE_CLASS_ORGAN_FULFILLED,
    UPDATE_CLASS_ORGAN_REJECTED,

    DELETE_CLASS_ORGAN_PENDING,
    DELETE_CLASS_ORGAN_FULFILLED,
    DELETE_CLASS_ORGAN_REJECTED,
    
    CHANGE_CLASS_ORGAN,
    
    ADD_CLASS_ORGAN

} from './classOrganActions'

import { titleCase } from '../../../../common/utils'

const initialState = {
    loading: false,
    listOfClassOrgans: [],
    classOrgan: {
        codigo: 0,
        nome: '',
        sigla: '',
        status: 0,
    },
    error: null,
}

export function classOrganReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_CLASS_ORGAN:
            return Object.assign({}, state, {
                classOrgan: { ...state.classOrgan, 
                    [action.payload.name]: action.payload.name === 'sigla' ? 
                        action.payload.value.toUpperCase() : 
                        titleCase(action.payload.value)
                }
            })
        case ADD_CLASS_ORGAN: 
            return Object.assign({}, state, {
                classOrgan: { ...initialState.classOrgan }
            })
        case FETCH_CLASS_ORGANS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfClassOrgans: [],
            })
        case FETCH_CLASS_ORGANS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfClassOrgans: action.payload
            })
        case FETCH_CLASS_ORGANS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_CLASS_ORGAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //classOrgan: { ...initialState.classOrgan },
            })
        case CREATE_CLASS_ORGAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                classOrgan: action.payload
            })
        case CREATE_CLASS_ORGAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_CLASS_ORGAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_CLASS_ORGAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                classOrgan: action.payload
            })
        case UPDATE_CLASS_ORGAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_CLASS_ORGAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_CLASS_ORGAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                classOrgan: action.payload
            })
        case FETCH_CLASS_ORGAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_CLASS_ORGAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_CLASS_ORGAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_CLASS_ORGAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}