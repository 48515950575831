import {

    FETCH_PROCEDURES_PENDING,
    FETCH_PROCEDURES_FULFILLED,
    FETCH_PROCEDURES_REJECTED,

    FETCH_PROCEDURE_PENDING,
    FETCH_PROCEDURE_FULFILLED,
    FETCH_PROCEDURE_REJECTED,

    CREATE_PROCEDURE_PENDING,
    CREATE_PROCEDURE_FULFILLED,
    CREATE_PROCEDURE_REJECTED,

    UPDATE_PROCEDURE_PENDING,
    UPDATE_PROCEDURE_FULFILLED,
    UPDATE_PROCEDURE_REJECTED,

    DELETE_PROCEDURE_PENDING,
    DELETE_PROCEDURE_FULFILLED,
    DELETE_PROCEDURE_REJECTED,
    
    CHANGE_PROCEDURE,
    
    ADD_PROCEDURE

} from './procedureActions'

import { titleCase } from '../../../../common/utils'

const moment = require('moment')

const initialState = {
    loading: false,
    listOfProcedures: [],
    procedure: {
        codigo: 0,
        grupo: 1,
        subgrupo: 1,
        classificacao: '',
        status: 0,
        descricao: '',
        porte: 1,
        custoOperacional: 0,
        numeroAuxiliares: 0,
        porteAnestesico: 0,
        filmes: 0,
        incidencia: 0
    },
    error: null,
}

export function procedureReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PROCEDURE:
            return Object.assign({}, state, {
                procedure: { ...state.procedure, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_PROCEDURE: 
            return Object.assign({}, state, {
                procedure: { ...initialState.procedure }
            })
        case FETCH_PROCEDURES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfProcedures: [],
            })
        case FETCH_PROCEDURES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfProcedures: action.payload
            })
        case FETCH_PROCEDURES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_PROCEDURE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //procedure: { ...initialState.procedure },
            })
        case CREATE_PROCEDURE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                procedure: action.payload
            })
        case CREATE_PROCEDURE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_PROCEDURE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_PROCEDURE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                procedure: action.payload
            })
        case UPDATE_PROCEDURE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_PROCEDURE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_PROCEDURE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                procedure: action.payload
            })
        case FETCH_PROCEDURE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_PROCEDURE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_PROCEDURE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_PROCEDURE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}