import api from '../../../api'

export const FETCH_POSICAO_PENDING = 'FETCH_POSICAO_PENDING'
export const FETCH_POSICAO_FULFILLED = 'FETCH_POSICAO_FULFILLED'
export const FETCH_POSICAO_REJECTED = 'FETCH_POSICAO_REJECTED'

export function findAllPositionsFromCustomer(matriz) {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({ type: FETCH_POSICAO_PENDING });

        api({
            method: 'post',       
            data: {
                query: `
                        query findAllPositionsFromCustomer {
                          posicoes (matriz: ${matriz} first: 100 offset: 0) {
                            veiculo {
                              matriz
                              tipoVeiculo
                              perfilPortas
                              garagem
                              grupo
                              placa
                              prefixo
                              marca
                              modelo
                              numeroFrota
                              clienteMatriz {
                                razaoSocial
                              }
                            }
                            motorista
                            modulo
                            dataHoraServidor
                            dataHoraGps
                            ignicao
                            panico
                            gps
                            gprs
                            entrada1
                            entrada2
                            entrada3
                            entrada4
                            saida1
                            saida2
                            saida3
                            saida4
                            temperatura
                            latitude
                            longitude
                            velocidade
                            rpm
                            hodometro
                            horimetro
                            direcao
                            ponto
                            area
                            rota
                            createdAt
                            updatedAt
                          }
                        }
                        `
            }
        }).then((result) => {
            const { errors, data } = result.data
            if (errors) {
                dispatch({ 
                  type: FETCH_POSICAO_REJECTED, 
                  payload: errors });
                resolve(errors);
            } else {
                const { posicoes } = data
                dispatch({ 
                  type: FETCH_POSICAO_FULFILLED, 
                  payload: posicoes });
                resolve(data);
            }
        }).catch((error) => {
            dispatch({ 
              type: FETCH_POSICAO_REJECTED, 
              payload: error });
            resolve(error);
        });

    });

}
