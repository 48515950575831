import api from '../../../../../api'

export const FETCH_USER_TYPES_PENDING = 'FETCH_USER_TYPES_PENDING'
export const FETCH_USER_TYPES_FULFILLED = 'FETCH_USER_TYPES_FULFILLED'
export const FETCH_USER_TYPES_REJECTED = 'FETCH_USER_TYPES_REJECTED'

export const FETCH_USER_TYPE_PENDING = 'FETCH_USER_TYPE_PENDING'
export const FETCH_USER_TYPE_FULFILLED = 'FETCH_USER_TYPE_FULFILLED'
export const FETCH_USER_TYPE_REJECTED = 'FETCH_USER_TYPE_REJECTED'

export const CREATE_USER_TYPE_PENDING = 'CREATE_USER_TYPE_PENDING'
export const CREATE_USER_TYPE_FULFILLED = 'CREATE_USER_TYPE_FULFILLED'
export const CREATE_USER_TYPE_REJECTED = 'CREATE_USER_TYPE_REJECTED'

export const UPDATE_USER_TYPE_PENDING = 'UPDATE_USER_TYPE_PENDING'
export const UPDATE_USER_TYPE_FULFILLED = 'UPDATE_USER_TYPE_FULFILLED'
export const UPDATE_USER_TYPE_REJECTED = 'UPDATE_USER_TYPE_REJECTED'

export const DELETE_USER_TYPE_PENDING = 'DELETE_USER_TYPE_PENDING'
export const DELETE_USER_TYPE_FULFILLED = 'DELETE_USER_TYPE_FULFILLED'
export const DELETE_USER_TYPE_REJECTED = 'DELETE_USER_TYPE_REJECTED'

export const CHANGE_USER_TYPE = 'CHANGE_USER_TYPE'

export const ADD_USER_TYPE = 'ADD_USER_TYPE'

export function changeUserType(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_USER_TYPE, payload: { name, value } })
  }
}

export function addUserType() {
  return dispatch => {
    dispatch({ type: ADD_USER_TYPE });
  };
}

export function findAllUserTypes(matriz = 1, first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_USER_TYPES_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllUserTypes {
                  tiposUsuario (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_USER_TYPES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { tiposUsuario } = data
        dispatch({ type: FETCH_USER_TYPES_FULFILLED, payload: tiposUsuario });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_USER_TYPES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findUserType(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_USER_TYPE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                    query findUserType {
                      tipoUsuario (codigo: ${codigo}) {
                        codigo
                        descricao
                        permissoes
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_USER_TYPE_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { tipoUsuario } = data
        dispatch({ type: FETCH_USER_TYPE_FULFILLED, payload: tipoUsuario });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_USER_TYPE_REJECTED, error });
      resolve(error);
    });

  });

}

export function createUserType(userType) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_USER_TYPE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createUserType {
            createTipoUsuario (input: {
              codigo: ${userType.codigo}
              descricao: "${userType.descricao}"
              permissoes: "${userType.permissoes}"
            } ) {
              codigo
              descricao
              permissoes
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_USER_TYPE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createTipoUsuario } = data
        dispatch({ type: CREATE_USER_TYPE_FULFILLED, payload: createTipoUsuario });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_USER_TYPE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateUserType(userType) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_USER_TYPE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateUserType {
            updateTipoUsuario (input: {
              codigo: ${userType.codigo}
              descricao: "${userType.descricao}"
              permissoes: "${userType.permissoes}"
            } ) {
              codigo
              descricao
              permissoes
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_USER_TYPE_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateTipoUsuario } = data
        dispatch({ type: UPDATE_USER_TYPE_FULFILLED, payload: updateTipoUsuario });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_USER_TYPE_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeUserType(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_USER_TYPE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveUserType {
            deleteTipoUsuario (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_USER_TYPE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteTipoUsuario } = data
        dispatch({ type: DELETE_USER_TYPE_FULFILLED, payload: deleteTipoUsuario });
        dispatch(findAllUserTypes());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_USER_TYPE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
