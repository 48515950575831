import {

    FETCH_DEPENDENTS_PENDING,
    FETCH_DEPENDENTS_FULFILLED,
    FETCH_DEPENDENTS_REJECTED,

    FETCH_DEPENDENT_PENDING,
    FETCH_DEPENDENT_FULFILLED,
    FETCH_DEPENDENT_REJECTED,

    CREATE_DEPENDENT_PENDING,
    CREATE_DEPENDENT_FULFILLED,
    CREATE_DEPENDENT_REJECTED,

    UPDATE_DEPENDENT_PENDING,
    UPDATE_DEPENDENT_FULFILLED,
    UPDATE_DEPENDENT_REJECTED,

    DELETE_DEPENDENT_PENDING,
    DELETE_DEPENDENT_FULFILLED,
    DELETE_DEPENDENT_REJECTED,
    
    CHANGE_DEPENDENT,
    
    ADD_DEPENDENT

} from './dependentActions'

import { titleCase } from "../../../../../common/utils";

const initialState = {
    loading: false,
    listOfDependents: [],
    dependent: {
        codigo: 0,
        paciente: 0,
        parentesco: 0,
        status: 0,
        razaoSocial: '', 
        sexo: 'F',
        nascimento: '',
        estadoCivil: 0,
        nacionalidade: 0,   
        necessidades: 0,
        fatorSangue: '', 
        naturalEstado: 1,
        naturalMunicipio: 1,
        escolaridade: 1,
        cpf: '',
        rg: '',
        expedidorRg: 0,
        ufRg: 1,
        numeroSus: '',
        peso: 0,
        altura: 0.0,
        profissao: 1,
        nomePai: '',
        nomeMae: '',
        tipoEndereco: '0',
        endereco: '',
        numero: '',
        complemento: '',
        cep: '',
        bairro: '',
        estado: 1,
        municipio: 1,
        celular: '',
        telefone: '',
        foneResidencial: '',
        foneComercial: '',
        email: ''
    },
    error: null,
}

export function dependentReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_DEPENDENT: 
            let value = ""
            
            if (action.payload.name.includes('email') || 
                action.payload.name.includes('homePage')) {
                value = action.payload.value.toLowerCase();
            } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                dependent: { ...state.dependent, 
                    [action.payload.name]: value
                }
            })
        case ADD_DEPENDENT: 
            return Object.assign({}, state, {
                dependent: { ...initialState.dependent }
            })
        case FETCH_DEPENDENTS_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_DEPENDENTS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfDependents: action.payload
            })
        case FETCH_DEPENDENTS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_DEPENDENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_DEPENDENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                dependent: action.payload
            })
        case CREATE_DEPENDENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_DEPENDENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_DEPENDENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                dependent: action.payload
            })
        case UPDATE_DEPENDENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_DEPENDENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_DEPENDENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                dependent: action.payload
            })
        case FETCH_DEPENDENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_DEPENDENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_DEPENDENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_DEPENDENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}