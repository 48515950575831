import React from "react";

import { Sparkline } from "../../graphs/inline";

export default class Stats extends React.Component {
  getStats() {
    return {
      income: {
        total: "14",
        totalOnline: "10",
        totalOffline: "4",
        values: [
          1300,
          1877,
          2500,
          2577,
          2000,
          2100,
          3000,
          2700,
          3631,
          2471,
          2700,
          3631,
          2471
        ]
      },
      traffic: {
        total: "45%",
        values: [110, 150, 300, 130, 400, 240, 220, 310, 220, 300, 270, 210]
      },
      orders: {
        total: "2447",
        values: [110, 150, 300, 130, 400, 240, 220, 310, 220, 300, 270, 210]
      }
    };
  }

  render() {
    const className = this.props.className;
    const stats = this.getStats();
    return (
      <div className="col-xs-12 col-sm-5 col-md-5 col-lg-8">
        <ul id="sparks" className={className}>
          <li className="sparks-info">
            <h5 className="m-0">
              Clientes
              <span className="txt-color-blue" style={{ textAlign: 'right' }}>Ativos {stats.income.total}</span>
            </h5>
          </li>
          <li className="sparks-info">
            <h5 className="m-0">
              CREDENCIADOS
              <span className="txt-color-blue" style={{ textAlign: 'right' }}>Ativos {stats.income.totalOnline}</span>
            </h5>
          </li>
          <li className="sparks-info">
            <h5 className="m-0">
              PRESTADORES
              <span className="txt-color-blue" style={{ textAlign: 'right' }}>Ativos {stats.income.totalOffline}</span>
            </h5>
          </li>
          <li className="sparks-info">
            <h5 className="m-0">
              Atendimento
              <span className="txt-color-blue" style={{ textAlign: 'right' }}>Presencial {30}</span>
            </h5>
          </li>
          <li className="sparks-info">
            <h5 className="m-0">
              Atendimento
              <span className="txt-color-blue" style={{ textAlign: 'right' }}>Virtual {10}%</span>
            </h5>
          </li>
        </ul>
      </div>
    );
  }
}
