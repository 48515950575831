import {

    FETCH_CHECKS_PENDING,
    FETCH_CHECKS_FULFILLED,
    FETCH_CHECKS_REJECTED,

    FETCH_CHECK_PENDING,
    FETCH_CHECK_FULFILLED,
    FETCH_CHECK_REJECTED,

    CREATE_CHECK_PENDING,
    CREATE_CHECK_FULFILLED,
    CREATE_CHECK_REJECTED,

    UPDATE_CHECK_PENDING,
    UPDATE_CHECK_FULFILLED,
    UPDATE_CHECK_REJECTED,

    DELETE_CHECK_PENDING,
    DELETE_CHECK_FULFILLED,
    DELETE_CHECK_REJECTED,
    
    CHANGE_CHECK,
    
    ADD_CHECK

} from './checkActions'

import { titleCase } from '../../../../common/utils'

const moment = require('moment')
const initialState = {
    loading: false,
    listOfChecks: [],
    check: {
        codigo: 0,
        tipoPortador: 0,
        situacao: 0,
        emissao: moment().format("DD/MM/YYYY"),
        numero: 0,
        valor: '0,00',
        predatado: '',
        deposito: '',
        banco: 0,
        agencia: '',
        conta: '',
        proprio: 0,
        titular: '',
        emissor: 0,
    },
    error: null,
}

export function checkReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_CHECK:
            return Object.assign({}, state, {
                check: { ...state.check, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_CHECK: 
            return Object.assign({}, state, {
                check: { ...initialState.check }
            })
        case FETCH_CHECKS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfChecks: [],
            })
        case FETCH_CHECKS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfChecks: action.payload
            })
        case FETCH_CHECKS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_CHECK_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //check: { ...initialState.check },
            })
        case CREATE_CHECK_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                check: action.payload
            })
        case CREATE_CHECK_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_CHECK_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_CHECK_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                check: action.payload
            })
        case UPDATE_CHECK_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_CHECK_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_CHECK_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                check: action.payload
            })
        case FETCH_CHECK_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_CHECK_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_CHECK_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_CHECK_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}