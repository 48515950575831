import {

    FETCH_MANAGEMENT_PLANS_PENDING,
    FETCH_MANAGEMENT_PLANS_FULFILLED,
    FETCH_MANAGEMENT_PLANS_REJECTED,

    FETCH_MANAGEMENT_PLAN_PENDING,
    FETCH_MANAGEMENT_PLAN_FULFILLED,
    FETCH_MANAGEMENT_PLAN_REJECTED,

    CREATE_MANAGEMENT_PLAN_PENDING,
    CREATE_MANAGEMENT_PLAN_FULFILLED,
    CREATE_MANAGEMENT_PLAN_REJECTED,

    UPDATE_MANAGEMENT_PLAN_PENDING,
    UPDATE_MANAGEMENT_PLAN_FULFILLED,
    UPDATE_MANAGEMENT_PLAN_REJECTED,

    DELETE_MANAGEMENT_PLAN_PENDING,
    DELETE_MANAGEMENT_PLAN_FULFILLED,
    DELETE_MANAGEMENT_PLAN_REJECTED,
    
    CHANGE_MANAGEMENT_PLAN,
    
    ADD_MANAGEMENT_PLAN

} from './managementPlanActions'

import { titleCase } from '../../../../common/utils'

const initialState = {
    loading: false,
    listOfManagementPlans: [],
    managementPlan: {
        codigo: 0,
        contaMae: 0,
        classificacao: '',
        descricao: '',
        tipo: 'C',
        editavel: true
    },
    error: null,
}

export function managementPlanReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_MANAGEMENT_PLAN:
            return Object.assign({}, state, {
                managementPlan: { ...state.managementPlan, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_MANAGEMENT_PLAN: 
            return Object.assign({}, state, {
                managementPlan: { ...initialState.managementPlan }
            })
        case FETCH_MANAGEMENT_PLANS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfManagementPlans: [],
            })
        case FETCH_MANAGEMENT_PLANS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfManagementPlans: action.payload
            })
        case FETCH_MANAGEMENT_PLANS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_MANAGEMENT_PLAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //center: { ...initialState.center },
            })
        case CREATE_MANAGEMENT_PLAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                managementPlan: action.payload
            })
        case CREATE_MANAGEMENT_PLAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_MANAGEMENT_PLAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_MANAGEMENT_PLAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                managementPlan: action.payload
            })
        case UPDATE_MANAGEMENT_PLAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_MANAGEMENT_PLAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_MANAGEMENT_PLAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                managementPlan: action.payload
            })
        case FETCH_MANAGEMENT_PLAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_MANAGEMENT_PLAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_MANAGEMENT_PLAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_MANAGEMENT_PLAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}