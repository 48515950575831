import api from '../../../../api'
import { toFloat } from '../../../../common/utils'

export const FETCH_SALES_PENDING = 'FETCH_SALES_PENDING'
export const FETCH_SALES_FULFILLED = 'FETCH_SALES_FULFILLED'
export const FETCH_SALES_REJECTED = 'FETCH_SALES_REJECTED'

export const FETCH_SALE_PENDING = 'FETCH_SALE_PENDING'
export const FETCH_SALE_FULFILLED = 'FETCH_SALE_FULFILLED'
export const FETCH_SALE_REJECTED = 'FETCH_SALE_REJECTED'

export const CREATE_SALE_PENDING = 'CREATE_SALE_PENDING'
export const CREATE_SALE_FULFILLED = 'CREATE_SALE_FULFILLED'
export const CREATE_SALE_REJECTED = 'CREATE_SALE_REJECTED'

export const UPDATE_SALE_PENDING = 'UPDATE_SALE_PENDING'
export const UPDATE_SALE_FULFILLED = 'UPDATE_SALE_FULFILLED'
export const UPDATE_SALE_REJECTED = 'UPDATE_SALE_REJECTED'

export const DELETE_SALE_PENDING = 'DELETE_SALE_PENDING'
export const DELETE_SALE_FULFILLED = 'DELETE_SALE_FULFILLED'
export const DELETE_SALE_REJECTED = 'DELETE_SALE_REJECTED'

export const CHANGE_SALE = 'CHANGE_SALE'

export const ADD_SALE = 'ADD_SALE'

export function changeSale(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SALE, payload: { name, value } })
  }
}
 
export function addSale() {
  return dispatch => {
    dispatch({ type: ADD_SALE });
  };
}

export function findAllSales(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SALES_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllSales {
                  historicoCompraPorLoja (codigoLoja: ${codigo}) {
                    codigo
                    codigoLoja
                    codigoCliente
                    codigoOperador
                    dataHoraCompra
                    valorCompra
                    numeroPdv
                    numeroEcf
                    numeroCupom
                    numeroTransacao
                    numeroParcelas
                    cliente {
                      codigo
                      nome
                    }
                    operador {
                      codigo
                      nome
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SALES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { historicoCompraPorLoja } = data
        dispatch({ type: FETCH_SALES_FULFILLED, payload: historicoCompraPorLoja });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SALES_REJECTED, error });
      resolve(error);
    });

  });

}

export function createSale(sale) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SALE_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation createSale {
            inclusaoHistoricoCompra (input: {
              codigoLoja: ${sale.codigoLoja}
              codigoCliente: ${sale.codigoCliente}
              codigoOperador: ${sale.codigoOperador}
              dataHoraCompra: "${sale.dataHoraCompra}:00"
              valorCompra: ${toFloat(sale.valorCompra)}
              numeroPdv: ${parseInt(sale.numeroPdv)}
              numeroEcf: ${parseInt(sale.numeroEcf)}
              numeroCupom: ${parseInt(sale.numeroCupom)}
              numeroTransacao: "${sale.numeroTransacao}"
              numeroParcelas: ${parseInt(sale.numeroParcelas)}
              numeroCartao: "${sale.numeroCartao}"
              validadeCartao: "${sale.validadeCartao}"
              segurancaCartao: "${parseInt(sale.segurancaCartao)}"
              observacoes: "${sale.observacoes}"
            } ) {
              codigo
              codigoLoja
              codigoCliente
              codigoOperador
              dataHoraCompra
              valorCompra
              numeroPdv
              numeroEcf
              numeroCupom
              numeroTransacao
              numeroParcelas
              observacoes
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SALE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { inclusaoHistoricoCompra } = data
        dispatch({ type: CREATE_SALE_FULFILLED, payload: inclusaoHistoricoCompra });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SALE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeSale(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_SALE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveSale {
            exclusaoHistoricoCompra (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_SALE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { exclusaoHistoricoCompra } = data
        dispatch({ type: DELETE_SALE_FULFILLED, payload: exclusaoHistoricoCompra });
        dispatch(findAllSales());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_SALE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
