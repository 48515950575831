import api from '../../../../api'
import { toFloat } from '../../../../common/utils'

export const FETCH_BILLS_TO_RECEIVE_PENDING = 'FETCH_BILLS_TO_RECEIVE_PENDING'
export const FETCH_BILLS_TO_RECEIVE_FULFILLED = 'FETCH_BILLS_TO_RECEIVE_FULFILLED'
export const FETCH_BILLS_TO_RECEIVE_REJECTED = 'FETCH_BILLS_TO_RECEIVE_REJECTED'

export const FETCH_BILL_TO_RECEIVE_PENDING = 'FETCH_BILL_TO_RECEIVE_PENDING'
export const FETCH_BILL_TO_RECEIVE_FULFILLED = 'FETCH_BILL_TO_RECEIVE_FULFILLED'
export const FETCH_BILL_TO_RECEIVE_REJECTED = 'FETCH_BILL_TO_RECEIVE_REJECTED'

export const CREATE_BILL_TO_RECEIVE_PENDING = 'CREATE_BILL_TO_RECEIVE_PENDING'
export const CREATE_BILL_TO_RECEIVE_FULFILLED = 'CREATE_BILL_TO_RECEIVE_FULFILLED'
export const CREATE_BILL_TO_RECEIVE_REJECTED = 'CREATE_BILL_TO_RECEIVE_REJECTED'

export const UPDATE_BILL_TO_RECEIVE_PENDING = 'UPDATE_BILL_TO_RECEIVE_PENDING'
export const UPDATE_BILL_TO_RECEIVE_FULFILLED = 'UPDATE_BILL_TO_RECEIVE_FULFILLED'
export const UPDATE_BILL_TO_RECEIVE_REJECTED = 'UPDATE_BILL_TO_RECEIVE_REJECTED'

export const DELETE_BILL_TO_RECEIVE_PENDING = 'DELETE_BILL_TO_RECEIVE_PENDING'
export const DELETE_BILL_TO_RECEIVE_FULFILLED = 'DELETE_BILL_TO_RECEIVE_FULFILLED'
export const DELETE_BILL_TO_RECEIVE_REJECTED = 'DELETE_BILL_TO_RECEIVE_REJECTED'

export const CHANGE_BILL_TO_RECEIVE = 'CHANGE_BILL_TO_RECEIVE'

export const ADD_BILL_TO_RECEIVE = 'ADD_BILL_TO_RECEIVE'

export function changeBillToReceive(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_BILL_TO_RECEIVE, payload: { name, value } })
  }
}
 
export function addBillToReceive() {
  return dispatch => {
    dispatch({ type: ADD_BILL_TO_RECEIVE });
  };
}

export function findAllBillToReceives(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_BILLS_TO_RECEIVE_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllBillToReceives {
                  contasReceber (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    dataMovimento
                    tipoDocunento
                    numeroDocumento
                    dataEmissao
                    descricao
                    centroCusto
                    centroDeCusto { 
                      descricao
                    }
                    valor
                    multa
                    juros
                    desconto
                    dataVencimento
                    valorTotal
                    formaPagamento
                    tipoPortador
                    portador
                    contaPortador {
                      razaoSocial
                    }
                    contaGerencial
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_BILLS_TO_RECEIVE_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { contasReceber } = data
        dispatch({ type: FETCH_BILLS_TO_RECEIVE_FULFILLED, payload: contasReceber });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_BILLS_TO_RECEIVE_REJECTED, error });
      resolve(error);
    });

  });

}

export function findBillToReceive(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_BILL_TO_RECEIVE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findBillToReceive {
                  contaReceber (codigo: ${codigo}) {
                    codigo
                    status
                    dataMovimento
                    tipoDocunento
                    numeroDocumento
                    dataEmissao
                    descricao
                    centroCusto
                    centroDeCusto {
                      descricao
                    }
                    valor
                    multa
                    juros
                    desconto
                    dataVencimento
                    dataLiquidacao
                    valorTotal
                    formaPagamento
                    tipoPortador
                    portador
                    contaPortador {
                      razaoSocial
                    }
                    contaGerencial
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_BILL_TO_RECEIVE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { contaReceber } = data
        dispatch({ type: FETCH_BILL_TO_RECEIVE_FULFILLED, payload: contaReceber });
        resolve(contaReceber);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_BILL_TO_RECEIVE_REJECTED, error });
      reject(error);
    });

  });

}

export function createBillToReceive(billToReceive) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_BILL_TO_RECEIVE_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createBillToReceive {
            createContaReceber (input: {
              codigo: ${billToReceive.codigo}
              status: ${billToReceive.status}
              dataMovimento: "${billToReceive.dataMovimento}"
              tipoDocunento: ${billToReceive.tipoDocunento}
              numeroDocumento: "${billToReceive.numeroDocumento}"
              dataEmissao: "${billToReceive.dataEmissao}"
              descricao: "${billToReceive.descricao}"
              centroCusto: ${billToReceive.centroCusto}
              valor: ${toFloat(billToReceive.valor)}
              multa: ${toFloat(billToReceive.multa)}
              juros: ${toFloat(billToReceive.juros)}
              desconto: ${toFloat(billToReceive.desconto)}
              dataVencimento: "${billToReceive.dataVencimento}"
              valorTotal: ${toFloat(billToReceive.valorTotal)}
              formaPagamento: ${billToReceive.formaPagamento}
              tipoPortador: ${billToReceive.tipoPortador}
              portador: ${billToReceive.portador}
              contaGerencial: ${billToReceive.contaGerencial}
            } ) 
            {
              codigo
              status
              dataMovimento
              tipoDocunento
              numeroDocumento
              dataEmissao
              descricao
              centroCusto
              valor
              multa
              juros
              desconto
              dataVencimento
              valorTotal
              formaPagamento
              tipoPortador
              portador
              contaGerencial
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_BILL_TO_RECEIVE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createContaReceber } = data
        dispatch({ type: CREATE_BILL_TO_RECEIVE_FULFILLED, payload: createContaReceber });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_BILL_TO_RECEIVE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateBillToReceive(billToReceive) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_BILL_TO_RECEIVE_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation UpdateBillToReceive {
            updateContaReceber (input: {
              codigo: ${billToReceive.codigo}
              status: ${billToReceive.status}
              dataMovimento: "${billToReceive.dataMovimento}"
              tipoDocunento: ${billToReceive.tipoDocunento}
              numeroDocumento: "${billToReceive.numeroDocumento}"
              dataEmissao: "${billToReceive.dataEmissao}"
              descricao: "${billToReceive.descricao}"
              centroCusto: ${billToReceive.centroCusto}
              valor: ${toFloat(billToReceive.valor)}
              multa: ${toFloat(billToReceive.multa)}
              juros: ${toFloat(billToReceive.juros)}
              desconto: ${toFloat(billToReceive.desconto)}
              dataVencimento: "${billToReceive.dataVencimento}"
              dataLiquidacao: "${billToReceive.dataLiquidacao}"
              valorTotal: ${toFloat(billToReceive.valorTotal)}
              formaPagamento: ${billToReceive.formaPagamento}
              tipoPortador: ${billToReceive.tipoPortador}
              portador: ${billToReceive.portador}
              contaGerencial: ${billToReceive.contaGerencial}
            } ) 
            {
              codigo
              status
              dataMovimento
              tipoDocunento
              numeroDocumento
              dataEmissao
              descricao
              centroCusto
              centroDeCusto {
                descricao
              }
              valor
              multa
              juros
              desconto
              dataVencimento
              dataLiquidacao
              valorTotal
              formaPagamento
              tipoPortador
              contaPortador {
                razaoSocial
              }
              portador
              contaGerencial
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_BILL_TO_RECEIVE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateContaReceber } = data
        dispatch({ type: CREATE_BILL_TO_RECEIVE_FULFILLED, payload: updateContaReceber });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_BILL_TO_RECEIVE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeBillToReceive(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_BILL_TO_RECEIVE_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation RemoveBillToReceive {
            deleteContaReceber (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_BILL_TO_RECEIVE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteContaReceber } = data
        dispatch({ type: DELETE_BILL_TO_RECEIVE_FULFILLED, payload: deleteContaReceber });
        dispatch(findAllBillToReceives());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_BILL_TO_RECEIVE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
