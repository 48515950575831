import {

    FETCH_CASHIER_MOVEMENT_MOVEMENTS_PENDING,
    FETCH_CASHIER_MOVEMENT_MOVEMENTS_FULFILLED,
    FETCH_CASHIER_MOVEMENT_MOVEMENTS_REJECTED,

    FETCH_CASHIER_MOVEMENT_PENDING,
    FETCH_CASHIER_MOVEMENT_FULFILLED,
    FETCH_CASHIER_MOVEMENT_REJECTED,

    CREATE_CASHIER_MOVEMENT_PENDING,
    CREATE_CASHIER_MOVEMENT_FULFILLED,
    CREATE_CASHIER_MOVEMENT_REJECTED,

    UPDATE_CASHIER_MOVEMENT_PENDING,
    UPDATE_CASHIER_MOVEMENT_FULFILLED,
    UPDATE_CASHIER_MOVEMENT_REJECTED,

    DELETE_CASHIER_MOVEMENT_PENDING,
    DELETE_CASHIER_MOVEMENT_FULFILLED,
    DELETE_CASHIER_MOVEMENT_REJECTED,
    
    CHANGE_CASHIER_MOVEMENT,
    
    ADD_CASHIER_MOVEMENT

} from './cashierMovementActions'
import * as moment from 'moment'

const initialState = {
    loading: false,
    listOfCashierMovements: [],
    cashierMovement: {
        codigo: 0,
        caixa: 1,
        usuario: 1,
        dataLancamento: moment().format("DD/MM/YYYY"),
        tipoDocumento: 0,
        numeroDocumento: '',
        tipoLancamento: 0,
        tipoPortador: 0,
        portador: 0,
        historico: '',
        formaPagamento: 0,
        valor: '0,00',
        centroCusto: 0,
        contaGerencial: 0,   
        
    },
    error: null,
}

export function cashierMovementReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_CASHIER_MOVEMENT:
            return Object.assign({}, state, {
                cashierMovement: { 
                    ...state.cashierMovement, 
                    [action.payload.name]: action.payload.value
                }
            })
        case ADD_CASHIER_MOVEMENT: 
            return Object.assign({}, state, {
                cashierMovement: { ...initialState.cashierMovement }
            })
        case FETCH_CASHIER_MOVEMENT_MOVEMENTS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfCashierMovements: [],
            })
        case FETCH_CASHIER_MOVEMENT_MOVEMENTS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfCashierMovements: action.payload
            })
        case FETCH_CASHIER_MOVEMENT_MOVEMENTS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_CASHIER_MOVEMENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //cashierMovement: { ...initialState.cashierMovement },
            })
        case CREATE_CASHIER_MOVEMENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cashierMovement: action.payload
            })
        case CREATE_CASHIER_MOVEMENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_CASHIER_MOVEMENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_CASHIER_MOVEMENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cashierMovement: action.payload
            })
        case UPDATE_CASHIER_MOVEMENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_CASHIER_MOVEMENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_CASHIER_MOVEMENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cashierMovement: action.payload
            })
        case FETCH_CASHIER_MOVEMENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_CASHIER_MOVEMENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_CASHIER_MOVEMENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_CASHIER_MOVEMENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}