import {

    FETCH_SHIPPINGS_PENDING,
    FETCH_SHIPPINGS_FULFILLED,
    FETCH_SHIPPINGS_REJECTED,

    FETCH_SHIPPING_PENDING,
    FETCH_SHIPPING_FULFILLED,
    FETCH_SHIPPING_REJECTED,

    CREATE_SHIPPING_PENDING,
    CREATE_SHIPPING_FULFILLED,
    CREATE_SHIPPING_REJECTED,

    UPDATE_SHIPPING_PENDING,
    UPDATE_SHIPPING_FULFILLED,
    UPDATE_SHIPPING_REJECTED,

    DELETE_SHIPPING_PENDING,
    DELETE_SHIPPING_FULFILLED,
    DELETE_SHIPPING_REJECTED,
    
    CHANGE_SHIPPING,
    
    ADD_SHIPPING

} from './shippingActions'

import { upperCase } from '../../../../../common/utils'

const initialState = {
    loading: false,
    listOfShippings: [],
    shipping: {
        codigo: 0,
        descricao: '',
        valorNormal: 0,
        tipoRedutor: 0,
        valorRedutor: 0,
        valorReduzido: 0
    },
    error: null,
}

export function shippingReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SHIPPING:
            return Object.assign({}, state, {
                shipping: { ...state.shipping, 
                    [action.payload.name]: upperCase(action.payload.value)
                }
            })
        case ADD_SHIPPING: 
            return Object.assign({}, state, {
                shipping: { ...initialState.shipping }
            })
        case FETCH_SHIPPINGS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfShippings: [],
            })
        case FETCH_SHIPPINGS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfShippings: action.payload
            })
        case FETCH_SHIPPINGS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_SHIPPING_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //shipping: { ...initialState.shipping },
            })
        case CREATE_SHIPPING_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                shipping: action.payload
            })
        case CREATE_SHIPPING_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_SHIPPING_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_SHIPPING_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                shipping: action.payload
            })
        case UPDATE_SHIPPING_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_SHIPPING_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_SHIPPING_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                shipping: action.payload
            })
        case FETCH_SHIPPING_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_SHIPPING_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_SHIPPING_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_SHIPPING_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}