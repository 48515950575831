import api from '../../../../api'

export const FETCH_CASHIERS_PENDING = 'FETCH_CASHIERS_PENDING'
export const FETCH_CASHIERS_FULFILLED = 'FETCH_CASHIERS_FULFILLED'
export const FETCH_CASHIERS_REJECTED = 'FETCH_CASHIERS_REJECTED'

export const FETCH_CASHIER_PENDING = 'FETCH_CASHIER_PENDING'
export const FETCH_CASHIER_FULFILLED = 'FETCH_CASHIER_FULFILLED'
export const FETCH_CASHIER_REJECTED = 'FETCH_CASHIER_REJECTED'

export const CREATE_CASHIER_PENDING = 'CREATE_CASHIER_PENDING'
export const CREATE_CASHIER_FULFILLED = 'CREATE_CASHIER_FULFILLED'
export const CREATE_CASHIER_REJECTED = 'CREATE_CASHIER_REJECTED'

export const UPDATE_CASHIER_PENDING = 'UPDATE_CASHIER_PENDING'
export const UPDATE_CASHIER_FULFILLED = 'UPDATE_CASHIER_FULFILLED'
export const UPDATE_CASHIER_REJECTED = 'UPDATE_CASHIER_REJECTED'

export const DELETE_CASHIER_PENDING = 'DELETE_CASHIER_PENDING'
export const DELETE_CASHIER_FULFILLED = 'DELETE_CASHIER_FULFILLED'
export const DELETE_CASHIER_REJECTED = 'DELETE_CASHIER_REJECTED'

export const CHANGE_CASHIER = 'CHANGE_CASHIER'

export const ADD_CASHIER = 'ADD_CASHIER'

export function changeCashier(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_CASHIER, payload: { name, value } })
  }
}
 
export function addCashier() {
  return dispatch => {
    dispatch({ type: ADD_CASHIER });
  };
}

export function findAllCashiers(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CASHIERS_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllCashiers {
                  caixas (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    descricao  
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CASHIERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { caixas } = data
        dispatch({ type: FETCH_CASHIERS_FULFILLED, payload: caixas });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CASHIERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findCashier(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CASHIER_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findCashier {
                  caixa (codigo: ${codigo}) {
                    codigo
                    status
                    descricao
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CASHIER_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { caixa } = data
        dispatch({ type: FETCH_CASHIER_FULFILLED, payload: caixa });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CASHIER_REJECTED, error });
      resolve(error);
    });

  });

}

export function createCashier(center) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CASHIER_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation createCashier {
            createCaixa (input: {
              codigo: ${center.codigo}
              status: ${center.status}
              descricao: "${center.descricao}"
            } ) 
            {
              codigo
              status
              descricao
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CASHIER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createCaixa } = data
        dispatch({ type: CREATE_CASHIER_FULFILLED, payload: createCaixa });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CASHIER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateCashier(center) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CASHIER_PENDING });

    api({
      method: 'post',   
      data: {
        query: `
          mutation UpdateBank {
            updateCaixa (input: {
              codigo: ${center.codigo}
              status: ${center.status}
              descricao: "${center.descricao}"
            } ) 
            {
              codigo
              status
              descricao
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CASHIER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateCaixa } = data
        dispatch({ type: CREATE_CASHIER_FULFILLED, payload: updateCaixa });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CASHIER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeCashier(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_CASHIER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveCashier {
            deleteCaixa (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_CASHIER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteCaixa } = data
        dispatch({ type: DELETE_CASHIER_FULFILLED, payload: deleteCaixa });
        dispatch(findAllCashiers());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_CASHIER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
