import api from '../../../../../api'

export const FETCH_GROUPS_PENDING = 'FETCH_GROUPS_PENDING'
export const FETCH_GROUPS_FULFILLED = 'FETCH_GROUPS_FULFILLED'
export const FETCH_GROUPS_REJECTED = 'FETCH_GROUPS_REJECTED'

export const FETCH_GROUP_PENDING = 'FETCH_GROUP_PENDING'
export const FETCH_GROUP_FULFILLED = 'FETCH_GROUP_FULFILLED'
export const FETCH_GROUP_REJECTED = 'FETCH_GROUP_REJECTED'

export const CREATE_GROUP_PENDING = 'CREATE_GROUP_PENDING'
export const CREATE_GROUP_FULFILLED = 'CREATE_GROUP_FULFILLED'
export const CREATE_GROUP_REJECTED = 'CREATE_GROUP_REJECTED'

export const UPDATE_GROUP_PENDING = 'UPDATE_GROUP_PENDING'
export const UPDATE_GROUP_FULFILLED = 'UPDATE_GROUP_FULFILLED'
export const UPDATE_GROUP_REJECTED = 'UPDATE_GROUP_REJECTED'

export const DELETE_GROUP_PENDING = 'DELETE_GROUP_PENDING'
export const DELETE_GROUP_FULFILLED = 'DELETE_GROUP_FULFILLED'
export const DELETE_GROUP_REJECTED = 'DELETE_GROUP_REJECTED'

export const CHANGE_GROUP = 'CHANGE_GROUP'

export const ADD_GROUP = 'ADD_GROUP'

export function changeGroup(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_GROUP, payload: { name, value } })
  }
}
 
export function addGroup() {
  return dispatch => {
    dispatch({ type: ADD_GROUP });
  };
}

export function findAllGroups(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_GROUPS_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllGroups {
                  grupos (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_GROUPS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { grupos } = data
        dispatch({ type: FETCH_GROUPS_FULFILLED, payload: grupos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_GROUPS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findGroup(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_GROUP_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findGroup {
                  grupo (codigo: ${codigo}) {
                    codigo
                    descricao
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_GROUP_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { grupo } = data
        dispatch({ type: FETCH_GROUP_FULFILLED, payload: grupo });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_GROUP_REJECTED, error });
      resolve(error);
    });

  });

}

export function createGroup(group) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_GROUP_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createGroup {
            createGrupo (input: {
              codigo: ${group.codigo}
              descricao: "${group.descricao}"
            } ) 
            {
              codigo
              descricao
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_GROUP_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createGrupo } = data
        dispatch({ type: CREATE_GROUP_FULFILLED, payload: createGrupo });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_GROUP_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateGroup(group) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_GROUP_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateGroup {
            updateGrupo (input: {
              codigo: ${group.codigo}
              descricao: "${group.descricao}"
            } ) 
            {
              codigo
              descricao
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_GROUP_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateGrupo } = data
        dispatch({ type: CREATE_GROUP_FULFILLED, payload: updateGrupo });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_GROUP_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeGroup(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_GROUP_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveGroup {
            deleteGrupo (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_GROUP_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteGrupo } = data
        dispatch({ type: DELETE_GROUP_FULFILLED, payload: deleteGrupo });
        dispatch(findAllGroups());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_GROUP_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
