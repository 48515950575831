import {

    FETCH_CASHIERS_OPEN_CLOSE_PENDING,
    FETCH_CASHIERS_OPEN_CLOSE_FULFILLED,
    FETCH_CASHIERS_OPEN_CLOSE_REJECTED,

    FETCH_CASHIER_OPEN_CLOSE_PENDING,
    FETCH_CASHIER_OPEN_CLOSE_FULFILLED,
    FETCH_CASHIER_OPEN_CLOSE_REJECTED,

    CREATE_CASHIER_OPEN_CLOSE_PENDING,
    CREATE_CASHIER_OPEN_CLOSE_FULFILLED,
    CREATE_CASHIER_OPEN_CLOSE_REJECTED,

    UPDATE_CASHIER_OPEN_CLOSE_PENDING,
    UPDATE_CASHIER_OPEN_CLOSE_FULFILLED,
    UPDATE_CASHIER_OPEN_CLOSE_REJECTED,

    DELETE_CASHIER_OPEN_CLOSE_PENDING,
    DELETE_CASHIER_OPEN_CLOSE_FULFILLED,
    DELETE_CASHIER_OPEN_CLOSE_REJECTED,
    
    CHANGE_CASHIER_OPENING,
    
    ADD_CASHIER_OPENING

} from './cashierOpenAndCloseActions'

const moment = require('moment')
const initialState = {
    loading: false,
    listOfCashierOpenAndClose: [],
    cashierOpenAndClose: {
        codigo: 0,
        usuario: 0,
        caixa: 0,
        dataAbertura: moment().format("DD/MM/YYYY"),
        horaAbertura: moment().format("HH:mm"),
        saldoInicial: 0,
    },
    error: null,
}

export function cashierOpenAndCloseReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_CASHIER_OPENING:
            return Object.assign({}, state, {
                cashierOpenAndClose: { ...state.cashierOpenAndClose, 
                    [action.payload.name]: action.payload.value
                }
            })
        case ADD_CASHIER_OPENING: 
            return Object.assign({}, state, {
                cashierOpenAndClose: { ...initialState.cashierOpenAndClose }
            })
        case FETCH_CASHIERS_OPEN_CLOSE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfCashierOpenAndClose: [],
            })
        case FETCH_CASHIERS_OPEN_CLOSE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfCashierOpenAndClose: action.payload
            })
        case FETCH_CASHIERS_OPEN_CLOSE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_CASHIER_OPEN_CLOSE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //cashierOpening: { ...initialState.cashierOpening },
            })
        case CREATE_CASHIER_OPEN_CLOSE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cashierOpenAndClose: action.payload
            })
        case CREATE_CASHIER_OPEN_CLOSE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_CASHIER_OPEN_CLOSE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_CASHIER_OPEN_CLOSE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cashierOpenAndClose: action.payload
            })
        case UPDATE_CASHIER_OPEN_CLOSE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_CASHIER_OPEN_CLOSE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_CASHIER_OPEN_CLOSE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cashierOpenAndClose: action.payload
            })
        case FETCH_CASHIER_OPEN_CLOSE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_CASHIER_OPEN_CLOSE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_CASHIER_OPEN_CLOSE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_CASHIER_OPEN_CLOSE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}