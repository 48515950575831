import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";

import { config } from "../config/config";
import { handleBodyClasses, dumpLayoutToStorage, layoutReducer, layoutInit } from "../common/layout";

import { navigationReducer } from "../common/navigation";
import { chatReducer, chatInit } from "../common/chat";
import { eventsReducer } from "../common/calendar";
import { todoReducer } from "../common/todo";
import { i18nReducer, i18nInit } from "../common/i18n";
import { outlookReducer } from "../views/outlook";

import { customerReducer } from "../views/forms/customer/store";
import { companyReducer } from "../views/forms/company/store";
import { regionReducer } from "../views/forms/region/store";
import { countryReducer } from "../views/forms/country/store";

import { groupReducer } from "../views/forms/procedure/group/store";
import { subGroupReducer } from "../views/forms/procedure/subGroup/store";
import { shippingReducer } from "../views/forms/procedure/shipping/store";
import { procedureReducer } from "../views/forms/procedure/store";
import { bankReducer } from "../views/forms/bank/store";
import { bankAccountReducer } from "../views/forms/bankAccount/store";
import { paymentMethodsReducer } from "../views/forms/paymentMethods/store";
import { checkReducer } from "../views/forms/check/store";
import { cashierOpenAndCloseReducer } from "../views/forms/cashierOpenAndClose/store";

import { professionReducer } from "../views/forms/profession/store";
import { classOrganReducer } from "../views/forms/classOrgan/store";
import { specialtyReducer } from "../views/forms/specialty/store";
import { healthPlanReducer } from "../views/forms/healthPlan/store";
import { planModalityReducer } from "../views/forms/healthPlan/modality/store";
import { salesPeopleReducer } from "../views/forms/salesPeople/store";
import { accreditedReducer } from "../views/forms/accredited/store";
import { providerReducer } from "../views/forms/provider/store";
import { supplierReducer } from "../views/forms/supplier/store";

import { patientReducer } from "../views/forms/patient/store";
import { dependentReducer } from "../views/forms/patient/dependents/store";

import { billsToPayReducer } from "../views/forms/billsToPay/store";
import { billsToReceiveReducer } from "../views/forms/billsToReceive/store";

import { availableScheduleReducer } from "../views/forms/availableSchedule/store";

import { saleReducer } from "../views/forms/sale/store";


import { cashierReducer } from "../views/forms/cashier/store";
import { cashierMovementReducer } from "../views/forms/cashierMovement/store";
import { costCenterReducer } from "../views/forms/costCenter/store";

import { groupProductReducer } from "../views/forms/product/group/store";
import { subGroupProductReducer } from "../views/forms/product/subGroup/store";
import { ncmProductReducer } from "../views/forms/product/ncm/store";

import { productReducer } from "../views/forms/product/store";
import { parametersReducer } from "../views/forms/parameters/store";

import { managementPlanReducer } from "../views/forms/managementPlan/store";

import { campaignReducer } from "../views/forms/campaign/store";
import { userReducer } from "../views/forms/user/store";
import { userTypeReducer } from "../views/forms/user/userType/store";

import { mapReducer } from "../views/maps/store";
import { loginReducer } from "../views/auth/components/login/store";

import {
  voiceReducer,
  VoiceMiddleware,
  voiceControlOn
} from "../common/voice-control";

export const rootReducer = combineReducers({
  layout: layoutReducer,
  navigation: navigationReducer,
  outlook: outlookReducer,
  user: userReducer,
  login: loginReducer,
  userType: userTypeReducer,
  chat: chatReducer,
  events: eventsReducer,
  voice: voiceReducer,
  todo: todoReducer,
  i18n: i18nReducer,

  group: groupReducer,
  subGroup: subGroupReducer,
  shipping: shippingReducer,
  procedure: procedureReducer,
  bank: bankReducer,
  bankAccount: bankAccountReducer,
  paymentMethods: paymentMethodsReducer,
  check: checkReducer,
  cashier: cashierReducer,
  cashierMovement: cashierMovementReducer,
  cashierOpenAndClose: cashierOpenAndCloseReducer,
  profession: professionReducer,
  classOrgan: classOrganReducer,
  specialty: specialtyReducer,
  healthPlan: healthPlanReducer,
  salesPeople: salesPeopleReducer,
  accredited: accreditedReducer,
  provider: providerReducer,
  supplier: supplierReducer,
  patient: patientReducer,
  dependent: dependentReducer,
  availableSchedule: availableScheduleReducer,
  billsToPay: billsToPayReducer,
  billsToReceive: billsToReceiveReducer,
  customer: customerReducer,
  company: companyReducer,
  region: regionReducer,
  country: countryReducer,
  map: mapReducer,
  sale: saleReducer,
  costCenter: costCenterReducer,
  managementPlan: managementPlanReducer,
  groupProduct: groupProductReducer,
  subGroupProduct: subGroupProductReducer,
  ncmProduct: ncmProductReducer,
  product: productReducer,  
  planModality: planModalityReducer,
  parameters: parametersReducer,
  campaign: campaignReducer,
  
});

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      thunk,
      handleBodyClasses,
      dumpLayoutToStorage,
      VoiceMiddleware
    )
  )
);

store.dispatch(layoutInit());
store.dispatch(chatInit());
// store.dispatch(requestUserInfo());
store.dispatch(i18nInit());

if (config.voice_command_auto) {
  store.dispatch(voiceControlOn());
}

export default store;
