import React from "react";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import "smartadmin-plugins/bower_components/fullcalendar/dist/fullcalendar.js";
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import * as EventActions from "../EventActions";

import { JarvisWidget } from "../../widgets/components";

class FullCalendarWidget extends React.Component {

  componentDidMount() {
    this.preRender();
  }

  preRender() {
    const self = this;
    const $calendar = $(this.refs.smartCalendar);
    const calendar = $calendar.fullCalendar({
      defaultView: 'agendaWeek',
      ignoreTimezone: false,
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      lang: "pt",
      editable: false,
      draggable: false,
      selectable: false,
      selectHelper: false,
      unselectAuto: false,
      disableResizing: false,
      droppable: false,
      axisFormat: 'HH:mm',
      allDayText: '',
      allDaySlot: false,
      slotEventOverlap: false,
      slotDuration: '00:15:00',
      scrollTime: '06:00:00',
      minTime: '06:00:00',
      maxTime: '19:00:00',
      hiddenDays: [0],  
      nowIndicator: true,
      selectable: true,
      businessHours: [ // specify an array instead
        {
          daysOfWeek: [ 1, 2, 3 ], // Monday, Tuesday, Wednesday
          startTime: '08:00', // 8am
          endTime: '18:00' // 6pm
        },
        {
          daysOfWeek: [ 4, 5 ], // Thursday, Friday
          startTime: '10:00', // 10am
          endTime: '16:00' // 4pm
        }
      ],
      titleFormat: {
        month: 'MMMM YYYY',
        week: "MMMM DD / YYYY",
        day: 'dddd, d MMMM YYYY'
      },
      columnFormat: {
        month: 'ddd',
        week: 'ddd DD/MM',
        day: ''
      },
      timeFormat: {
        '': 'H:mm',
        agenda: 'HH:mm - '
      },
      header: {
        left: "title", //,today
        center: "prev, next, today",
        right: "month, agendaWeek, agendaDay" //month, agendaDay,
      },

      drop(date, allDay) {
        // this function is called when something is dropped

        // retrieve the dropped element's stored Event Object
        const originalEventObject = $(this).data("eventObject");

        // we need to copy it, so that multiple events don't have a reference to the same object
        const copiedEventObject = { ...originalEventObject };

        // assign it the date that was reported
        copiedEventObject.start = date;
        copiedEventObject.allDay = allDay;

        // render the event on the calendar
        // the last `true` argument determines if the event "sticks" (http://arshaw.com/fullcalendar/docs/event_rendering/renderEvent/)
        $calendar.fullCalendar("renderEvent", copiedEventObject, true);

        self.props.dropExternal(originalEventObject);
      },

      select(start, end, allDay) {
        /*const title = prompt("Event Title:");
        if (title) {
          calendar.fullCalendar(
            "renderEvent",
            {
              title: title,
              start: start,
              end: end,
              allDay: allDay
            },
            true // make the event "stick"
          );
        }*/
        $('#createEventModal').modal('show');
        // $("#createEventModal").appendTo("body");
        calendar.fullCalendar("unselect");
      },

      events: (start, end, timezone, callback) => {
        callback(this.props.events);
      },

      eventRender(event, element, icon) {
        if (!event.description === "") {
          element
            .find(".fc-event-title")
            .append(
              "<br/><span class='ultra-light'>" + event.description + "</span>"
            );
        }
        if (!event.icon === "") {
          element
            .find(".fc-event-title")
            .append("<i class='air air-top-right fa " + event.icon + " '></i>");
        }
      },

      eventClick(info) {
        // alert('Event: ' + info.event.title);
        console.log(info)
        //alert('Event: ' + info.event.title);
        //alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
        //alert('View: ' + info.view.type);
    
        // change the border color just for fun
        //info.el.style.borderColor = 'red';
      }

    });

    $(".fc-header-right, .fc-header-center", $calendar).hide();
    $(".fc-left", $calendar).addClass("fc-header-title");
  }

  changeView = period => {
    $(this.refs.smartCalendar).fullCalendar("changeView", period);
  };

  next = () => {
    $(".fc-next-button", this.refs.smartCalendar).click();
  };

  prev = () => {
    $(".fc-prev-button", this.refs.smartCalendar).click();
  };

  today = () => {
    $(".fc-today-button", this.refs.smartCalendar).click();
  };

  render() {
    const { scheduleEvent } = this.props;
    return (
      <JarvisWidget color="blueDark" id="full-calendar-widget"
      colorbutton={false}
      deletebutton={false}
      togglebutton={false}
      editbutton={false}
      fullscreenbutton={true}
      >
        <header>
          <span className="widget-icon">
            <i className="fa fa-calendar" />
          </span>

          <h2> Agenda De Atendimento </h2>

          <div className="widget-toolbar">
            <DropdownButton
              id="calendar-showing-dropdown"
              title="Exibir"
              pullRight
              bsSize="xsmall"
            >
              <MenuItem onClick={this.changeView.bind(this, "agendaDay")}>
                Hoje
              </MenuItem>
              <MenuItem onClick={this.changeView.bind(this, "agendaWeek")}>
                Agenda Completa
              </MenuItem>
              <MenuItem onClick={this.changeView.bind(this, "month")}>
                Mês Completo
              </MenuItem>
            </DropdownButton>
          </div>
        </header>

        {/* widget div*/}
        <div>
          <div className="widget-body no-padding">
            {/* content goes here */}
            <div className="widget-body-toolbar">
              <div id="calendar-buttons">
                <div className="btn-group">
                  <a href="#/" onClick={this.prev} className="btn btn-default btn-xs">
                    <i className="fa fa-chevron-left" />
                  </a>
                  <a href="#/" onClick={this.next} className="btn btn-default btn-xs">
                    <i className="fa fa-chevron-right" />
                  </a>
                </div>
              </div>
            </div>

            <div id="calendar" ref="smartCalendar" />

            {/* end content */}
          </div>

          {/* Modal */}
          <div id="createEventModal" className="modal fade" tabindex="-1" role="dialog" data-backdrop="false" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title">Novo Agendamento</h4>
                </div>
                <div className="modal-body custom-scroll no-padding">
                <form className="smart-form" onSubmit={() => {}}>
                  <fieldset>
                    
                    <div className="row">
                      <section className="col col-lg-12">
                          <label className="label">Nome do Paciente</label>
                          <label className="input">
                            <input id="razaoSocial" name="razaoSocial" type="text" placeholder="nome / razão social"
                              value={scheduleEvent.razaoSocial} onChange={() => {}} required />
                          </label>
                          <span className="error"></span>
                      </section>
                    </div>

                    <div className="row">
                      <section className="col col-lg-6">
                          <label className="label">CPF</label>
                          <label className="input">
                          <InputMask id="cpf" name="cpf" mask={"999.999.999-99"}
                                maskChar={null} placeholder="cpf"
                                value={scheduleEvent.cpf} type="cpf" onChange={() => {}} required />
                          </label>
                          <span className="error"></span>
                      </section>

                      <section className="col col-lg-6">
                          <label className="label">Telefone</label>
                          <label className="input">
                          <InputMask id="foneCelular" name="foneCelular" mask={"(99) 9999-9999"}
                                maskChar={null} placeholder="telefone"
                                value={scheduleEvent.foneCelular} onChange={() => {}} />
                          </label>
                          <span className="error"></span>
                      </section>
                    </div>

                    <div className="row">
                      <section className="col col-lg-12">
                          <label className="label">E-mail</label>
                          <label className="input">
                            <input id="razaoSocial" name="razaoSocial" type="text" placeholder="e-mail (opcional)"
                              value={scheduleEvent.email} onChange={() => {}} required />
                          </label>
                          <span className="error"></span>
                      </section>
                    </div>

                    <div className="row">
                      <section className="col col-lg-5">
                          <label className="label">Tipo Atendimento</label>
                          <label className="select">
                            <select id="tipoAtendimento" name="tipoAtendimento"
                              value={scheduleEvent.tipoAtendimento}>
                              <option value={0}>Consulta Simples</option>
                              <option value={1}>Consulta com Exames</option>
                            </select>
                            <i />
                          </label>
                          <span className="error"></span>
                      </section>

                      <section className="col col-lg-3">
                          <label className="label">Data</label>
                          <label className="input">
                          <InputMask id="dataAgenda" name="dataAgenda" mask={"99/99/9999"}
                                maskChar={null} placeholder="data"
                                value={scheduleEvent.dataAgenda} onChange={() => {}} />
                          </label>
                          <span className="error"></span>
                      </section>

                      <section className="col col-lg-2">
                          <label className="label">Início</label>
                          <label className="input">
                          <InputMask id="inicioAgenda" name="inicioAgenda" mask={"99:99"}
                                maskChar={null} placeholder="inicio"
                                value={scheduleEvent.inicio} onChange={() => {}} />
                          </label>
                          <span className="error"></span>
                      </section>

                      <section className="col col-lg-2">
                          <label className="label">Término</label>
                          <label className="input">
                          <InputMask id="terminoAgenda" name="terminoAgenda" mask={"99:99"}
                                maskChar={null} placeholder="termino"
                                value={scheduleEvent.termino} onChange={() => {}} />
                          </label>
                          <span className="error"></span>
                      </section>
                    </div>

                    <div className="row">
                      <section className="col col-lg-12">
                          <label className="label">Observações</label>
                          <label className="input">
                            <input id="observacao" name="observacao" type="text" placeholder="observacões"
                              value={scheduleEvent.observacao} onChange={() => {}} required />
                          </label>
                          <span className="error"></span>
                      </section>
                    </div>

                  </fieldset>
                </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary">Confirmar</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                </div>
              </div>
            </div>
          </div>
          {/* /.modal */}
        
        </div>
        {/* end widget div */}
      </JarvisWidget>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.events.events,
  scheduleEvent: state.events.scheduleEvent,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(EventActions, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FullCalendarWidget));
