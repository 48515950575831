const moment = require('moment');

module.exports = [{
    "_id": "544c4183be624ef013bb009e",
    "title": "Presencial",
    "start": moment().subtract(1, 'day').startOf('day').add(8, 'hours').add(30, 'minutes'),
    "end": moment().subtract(1, 'day').startOf('day').add(9, 'hours').add(0, 'minutes'),
    "allDay": false,
    "className": ["event", "bg-color-green"]
},
{
    "_id": "544c4183be624ef013bb019e",
    "title": "Video Chamada",
    "start": moment().subtract(1, 'day').startOf('day').add(8, 'hours').add(30, 'minutes'),
    "end": moment().subtract(1, 'day').startOf('day').add(9, 'hours').add(0, 'minutes'),
    "allDay": false,
    "className": ["event", "bg-color-green"]
},
{
    "_id": "544c4183be624ef013bb009e",
    "title": "Presencial",
    "start": moment().add(0, 'day').startOf('day').add(8, 'hours').add(30, 'minutes'),
    "end": moment().add(0, 'day').startOf('day').add(9, 'hours').add(0, 'minutes'),
    "allDay": false,
    "className": ["event", "bg-color-green"]
},
{
    "_id": "544c4183be624ef013bb019e",
    "title": "Video Chamada",
    "start": moment().add(0, 'day').startOf('day').add(8, 'hours').add(30, 'minutes'),
    "end": moment().add(0, 'day').startOf('day').add(9, 'hours').add(0, 'minutes'),
    "allDay": false,
    "className": ["event", "bg-color-green"]
},
{
    "_id": "544c4183be624ef013cb009e",
    "title": "Presencial",
    "start": moment().add(0, 'day').startOf('day').add(10, 'hours').add(30, 'minutes'),
    "end": moment().add(0, 'day').startOf('day').add(11, 'hours').add(0, 'minutes'),
    "allDay": false,
    "className": ["event", "bg-color-green"]
},
{
    "_id": "544c4183be624ef013db019e",
    "title": "Video Chamada",
    "start": moment().add(0, 'day').startOf('day').add(10, 'hours').add(30, 'minutes'),
    "end": moment().add(0, 'day').startOf('day').add(11, 'hours').add(0, 'minutes'),
    "allDay": false,
    "className": ["event", "bg-color-green"]
}];