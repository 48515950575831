import {

    FETCH_GROUPS_PENDING,
    FETCH_GROUPS_FULFILLED,
    FETCH_GROUPS_REJECTED,

    FETCH_GROUP_PENDING,
    FETCH_GROUP_FULFILLED,
    FETCH_GROUP_REJECTED,

    CREATE_GROUP_PENDING,
    CREATE_GROUP_FULFILLED,
    CREATE_GROUP_REJECTED,

    UPDATE_GROUP_PENDING,
    UPDATE_GROUP_FULFILLED,
    UPDATE_GROUP_REJECTED,

    DELETE_GROUP_PENDING,
    DELETE_GROUP_FULFILLED,
    DELETE_GROUP_REJECTED,
    
    CHANGE_GROUP,
    
    ADD_GROUP

} from './groupActions'

import { titleCase } from '../../../../../common/utils'
const initialState = {
    loading: false,
    listOfGroups: [],
    group: {
        codigo: 0,
        descricao: '',
    },
    error: null,
}

export function groupReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_GROUP:
            return Object.assign({}, state, {
                group: { ...state.group, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_GROUP: 
            return Object.assign({}, state, {
                group: { ...initialState.group }
            })
        case FETCH_GROUPS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfGroups: [],
            })
        case FETCH_GROUPS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfGroups: action.payload
            })
        case FETCH_GROUPS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_GROUP_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //group: { ...initialState.group },
            })
        case CREATE_GROUP_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                group: action.payload
            })
        case CREATE_GROUP_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_GROUP_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_GROUP_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                group: action.payload
            })
        case UPDATE_GROUP_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_GROUP_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_GROUP_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                group: action.payload
            })
        case FETCH_GROUP_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_GROUP_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_GROUP_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_GROUP_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}