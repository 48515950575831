import {

    FETCH_PATIENTS_PENDING,
    FETCH_PATIENTS_FULFILLED,
    FETCH_PATIENTS_REJECTED,

    FETCH_PATIENT_PENDING,
    FETCH_PATIENT_FULFILLED,
    FETCH_PATIENT_REJECTED,

    CREATE_PATIENT_PENDING,
    CREATE_PATIENT_FULFILLED,
    CREATE_PATIENT_REJECTED,

    UPDATE_PATIENT_PENDING,
    UPDATE_PATIENT_FULFILLED,
    UPDATE_PATIENT_REJECTED,

    DELETE_PATIENT_PENDING,
    DELETE_PATIENT_FULFILLED,
    DELETE_PATIENT_REJECTED,
    
    CHANGE_PATIENT,
    
    ADD_PATIENT

} from './patientActions'

import { titleCase } from "../../../../common/utils";

const initialState = {
    loading: false,
    listOfPatients: [],
    patient: {
        codigo: 0,
        status: 0, 
        razaoSocial: '',
        sexo: 'F',
        nascimento: '',
        imagem: '',
        estadoCivil: 0,
        nacionalidade: 0,   
        necessidades: 0,
        fatorSangue: '', 
        naturalEstado: 1,
        naturalMunicipio: 1,
        escolaridade: 1,
        cpf: '',
        rg: '',
        expedidorRg: 0,
        ufRg: 1,
        numeroSus: '',
        peso: 0,
        altura: 0.0,
        profissao: 1,
        nomePai: '',
        nomeMae: '',
        tipoEndereco: '5',
        endereco: '',
        numero: '',
        complemento: '',
        cep: '',
        bairro: '',
        estado: 1,
        municipio: 1,
        celular: '',
        telefone: '',
        foneResidencial: '',
        foneComercial: '',
        email: '',
        planosSaude: [],
    },
    error: null,
}

export function patientReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PATIENT: 
            let value = ""
            
            if (action.payload.name.includes('email') || 
                action.payload.name.includes('homePage')) {
                value = action.payload.value.toLowerCase();
            } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                patient: { ...state.patient, 
                    [action.payload.name]: value
                }
            })
        case ADD_PATIENT: 
            return Object.assign({}, state, {
                patient: { ...initialState.patient }
            })
        case FETCH_PATIENTS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfPatients: [],
            })
        case FETCH_PATIENTS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfPatients: action.payload || []
            })
        case FETCH_PATIENTS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_PATIENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_PATIENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                patient: action.payload
            })
        case CREATE_PATIENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_PATIENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_PATIENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                patient: action.payload
            })
        case UPDATE_PATIENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_PATIENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_PATIENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                patient: action.payload
            })
        case FETCH_PATIENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_PATIENT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_PATIENT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_PATIENT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}