import api from '../../../../../api'

export const CREATE_TOKEN_PENDING = 'CREATE_BANK_PENDING'
export const CREATE_TOKEN_FULFILLED = 'CREATE_BANK_FULFILLED'
export const CREATE_TOKEN_REJECTED = 'CREATE_BANK_REJECTED'

export const CHANGE_LOGIN = 'CHANGE_LOGIN'

export function changeLogin(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_LOGIN, payload: { name, value } })
  }
}

export function createToken(login, password) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_TOKEN_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation CreateToken {
            createToken (
              login: "${login}"
              password: "${password}"
            ) 
            {
              token
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_TOKEN_REJECTED, payload: errors });
        reject({ status: false, error: errors });
      } else {
        const { createToken } = data
        dispatch({ type: CREATE_TOKEN_FULFILLED, payload: createToken });
        resolve({ status: true, createToken });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_TOKEN_REJECTED, errors });
      reject({ status: false, error: errors });
    });
  });
}
