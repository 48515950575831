import {

    FETCH_SALES_PENDING,
    FETCH_SALES_FULFILLED,
    FETCH_SALES_REJECTED,

    FETCH_SALE_PENDING,
    FETCH_SALE_FULFILLED,
    FETCH_SALE_REJECTED,

    CREATE_SALE_PENDING,
    CREATE_SALE_FULFILLED,
    CREATE_SALE_REJECTED,

    UPDATE_SALE_PENDING,
    UPDATE_SALE_FULFILLED,
    UPDATE_SALE_REJECTED,

    DELETE_SALE_PENDING,
    DELETE_SALE_FULFILLED,
    DELETE_SALE_REJECTED,
    
    CHANGE_SALE,
    
    ADD_SALE

} from './saleActions'

import { titleCase } from '../../../../common/utils'

const moment = require('moment')

const initialState = {
    loading: false,
    listOfSales: [],
    sale: {
        codigo: 0,
        codigoLoja: 0,
        codigoCliente: 0,
        codigoOperador: 0,
        dataHoraCompra: moment().format('DD/MM/YYYY HH:mm'),
        valorCompra: 0.0,
        numeroPdv: 0,
        numeroEcf: 0,
        numeroCupom: 0,
        numeroTransacao: '',
        numeroParcelas: 0,
        observacoes: '',
        numeroCartao: '',
        validadeCartao: '',
        segurancaCartao: ''
    },
    error: null,
}

export function saleReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SALE:
        if ((action.payload.name === "valorTotal") || (action.payload.name === "quantidade")) {
            return Object.assign({}, state, {
                sale: { ...state.sale, 
                    [action.payload.name]: action.payload.value,
                }
            })
        } else {
            return Object.assign({}, state, {
                sale: { ...state.sale, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        }
        case ADD_SALE: 
            return Object.assign({}, state, {
                sale: { ...initialState.sale }
            })
        case FETCH_SALES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfSales: [],
            })
        case FETCH_SALES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfSales: action.payload
            })
        case FETCH_SALES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_SALE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //sale: { ...initialState.sale },
            })
        case CREATE_SALE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                sale: action.payload
            })
        case CREATE_SALE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_SALE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_SALE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                sale: action.payload
            })
        case UPDATE_SALE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_SALE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_SALE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                sale: action.payload
            })
        case FETCH_SALE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_SALE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_SALE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_SALE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}