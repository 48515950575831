import {

    FETCH_SUPPLIERS_PENDING,
    FETCH_SUPPLIERS_FULFILLED,
    FETCH_SUPPLIERS_REJECTED,

    FETCH_SUPPLIER_PENDING,
    FETCH_SUPPLIER_FULFILLED,
    FETCH_SUPPLIER_REJECTED,

    CREATE_SUPPLIER_PENDING,
    CREATE_SUPPLIER_FULFILLED,
    CREATE_SUPPLIER_REJECTED,

    UPDATE_SUPPLIER_PENDING,
    UPDATE_SUPPLIER_FULFILLED,
    UPDATE_SUPPLIER_REJECTED,

    DELETE_SUPPLIER_PENDING,
    DELETE_SUPPLIER_FULFILLED,
    DELETE_SUPPLIER_REJECTED,
    
    CHANGE_SUPPLIER,
    
    ADD_SUPPLIER

} from './supplierActions'

import { titleCase } from "../../../../common/utils";

const initialState = {
    loading: false,
    listOfSuppliers: [],
    supplier: {
        codigo: 0,
        status: 0,
        tipoFornecedor: 'J',
        cpfCnpj: '',
        razaoSocial: '',
        nomeFantasia: '',
        numeroCNAE: '',
        numeroIE: '',
        numeroIM: '',
        tipoRegime: 0,
        tipoEndereco: '5',
        endereco: '',
        numero: '',
        complemento: '',
        cep: '',
        bairro: '',
        estado: 0,
        municipio: 0,
        celular: '',
        fixo: '',
        ramal: '',
        responsavel: '',
        email: '',
        homePage: ''
    },
    error: null,
}

export function supplierReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SUPPLIER: 
            let value = ""
            
            if (action.payload.name.includes('email') || 
                action.payload.name.includes('homePage')) {
                value = action.payload.value.toLowerCase();
            } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                supplier: { ...state.supplier, 
                    [action.payload.name]: value
                }
            })
        case ADD_SUPPLIER: 
            return Object.assign({}, state, {
                supplier: { ...initialState.supplier }
            })
        case FETCH_SUPPLIERS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfSuppliers: [],
            })
        case FETCH_SUPPLIERS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfSuppliers: action.payload
            })
        case FETCH_SUPPLIERS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_SUPPLIER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_SUPPLIER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                supplier: action.payload
            })
        case CREATE_SUPPLIER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_SUPPLIER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_SUPPLIER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                supplier: action.payload
            })
        case UPDATE_SUPPLIER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_SUPPLIER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_SUPPLIER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                supplier: action.payload
            })
        case FETCH_SUPPLIER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_SUPPLIER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_SUPPLIER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_SUPPLIER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}