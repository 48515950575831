import {

    FETCH_GROUPS_PRODUCT_PENDING,
    FETCH_GROUPS_PRODUCT_FULFILLED,
    FETCH_GROUPS_PRODUCT_REJECTED,

    FETCH_GROUP_PRODUCT_PENDING,
    FETCH_GROUP_PRODUCT_FULFILLED,
    FETCH_GROUP_PRODUCT_REJECTED,

    CREATE_GROUP_PRODUCT_PENDING,
    CREATE_GROUP_PRODUCT_FULFILLED,
    CREATE_GROUP_PRODUCT_REJECTED,

    UPDATE_GROUP_PRODUCT_PENDING,
    UPDATE_GROUP_PRODUCT_FULFILLED,
    UPDATE_GROUP_PRODUCT_REJECTED,

    DELETE_GROUP_PRODUCT_PENDING,
    DELETE_GROUP_PRODUCT_FULFILLED,
    DELETE_GROUP_PRODUCT_REJECTED,
    
    CHANGE_GROUP_PRODUCT,
    
    ADD_GROUP_PRODUCT

} from './groupProductActions'

import { titleCase } from '../../../../../common/utils'

const moment = require('moment')

const initialState = {
    loading: false,
    listOfGroups: [],
    group: {
        codigo: 0,
        descricao: '',
    },
    error: null,
}

export function groupProductReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_GROUP_PRODUCT:
            return Object.assign({}, state, {
                group: { ...state.group, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_GROUP_PRODUCT: 
            return Object.assign({}, state, {
                group: { ...initialState.group }
            })
        case FETCH_GROUPS_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfGroups: [],
            })
        case FETCH_GROUPS_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfGroups: action.payload
            })
        case FETCH_GROUPS_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_GROUP_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //group: { ...initialState.group },
            })
        case CREATE_GROUP_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                group: action.payload
            })
        case CREATE_GROUP_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_GROUP_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_GROUP_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                group: action.payload
            })
        case UPDATE_GROUP_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_GROUP_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_GROUP_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                group: action.payload
            })
        case FETCH_GROUP_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_GROUP_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_GROUP_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_GROUP_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}