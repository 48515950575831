import {

    FETCH_PRODUCTS_PENDING,
    FETCH_PRODUCTS_FULFILLED,
    FETCH_PRODUCTS_REJECTED,

    FETCH_PRODUCT_PENDING,
    FETCH_PRODUCT_FULFILLED,
    FETCH_PRODUCT_REJECTED,

    CREATE_PRODUCT_PENDING,
    CREATE_PRODUCT_FULFILLED,
    CREATE_PRODUCT_REJECTED,

    UPDATE_PRODUCT_PENDING,
    UPDATE_PRODUCT_FULFILLED,
    UPDATE_PRODUCT_REJECTED,

    DELETE_PRODUCT_PENDING,
    DELETE_PRODUCT_FULFILLED,
    DELETE_PRODUCT_REJECTED,
    
    CHANGE_PRODUCT,
    
    ADD_PRODUCT

} from './productActions'

import { titleCase } from '../../../../common/utils'

const initialState = {
    loading: false,
    listOfProducts: [],
    product: {
        codigo: 0,
        status: 0,
        produto: '',
        marca: ''
    },
    error: null,
}

export function productReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PRODUCT:
            return Object.assign({}, state, {
                product: { ...state.product, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_PRODUCT: 
            return Object.assign({}, state, {
                product: { ...initialState.product }
            })
        case FETCH_PRODUCTS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfProducts: [],
            })
        case FETCH_PRODUCTS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfProducts: action.payload
            })
        case FETCH_PRODUCTS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //product: { ...initialState.product },
            })
        case CREATE_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                product: action.payload
            })
        case CREATE_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                product: action.payload
            })
        case UPDATE_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                product: action.payload
            })
        case FETCH_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}