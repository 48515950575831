import api from '../../../../api'

export const FETCH_COSTCENTERS_PENDING = 'FETCH_COSTCENTERS_PENDING'
export const FETCH_COSTCENTERS_FULFILLED = 'FETCH_COSTCENTERS_FULFILLED'
export const FETCH_COSTCENTERS_REJECTED = 'FETCH_COSTCENTERS_REJECTED'

export const FETCH_COSTCENTER_PENDING = 'FETCH_COSTCENTER_PENDING'
export const FETCH_COSTCENTER_FULFILLED = 'FETCH_COSTCENTER_FULFILLED'
export const FETCH_COSTCENTER_REJECTED = 'FETCH_COSTCENTER_REJECTED'

export const CREATE_COSTCENTER_PENDING = 'CREATE_COSTCENTER_PENDING'
export const CREATE_COSTCENTER_FULFILLED = 'CREATE_COSTCENTER_FULFILLED'
export const CREATE_COSTCENTER_REJECTED = 'CREATE_COSTCENTER_REJECTED'

export const UPDATE_COSTCENTER_PENDING = 'UPDATE_COSTCENTER_PENDING'
export const UPDATE_COSTCENTER_FULFILLED = 'UPDATE_COSTCENTER_FULFILLED'
export const UPDATE_COSTCENTER_REJECTED = 'UPDATE_COSTCENTER_REJECTED'

export const DELETE_COSTCENTER_PENDING = 'DELETE_COSTCENTER_PENDING'
export const DELETE_COSTCENTER_FULFILLED = 'DELETE_COSTCENTER_FULFILLED'
export const DELETE_COSTCENTER_REJECTED = 'DELETE_COSTCENTER_REJECTED'

export const CHANGE_COSTCENTER = 'CHANGE_COSTCENTER'

export const ADD_COSTCENTER = 'ADD_COSTCENTER'

export function changeCenter(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_COSTCENTER, payload: { name, value } })
  }
}
 
export function addCenter() {
  return dispatch => {
    dispatch({ type: ADD_COSTCENTER });
  };
}

export function findAllCostCenters(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_COSTCENTERS_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllCostCenters {
                  centrosCusto (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    descricao  
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_COSTCENTERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { centrosCusto } = data
        dispatch({ type: FETCH_COSTCENTERS_FULFILLED, payload: centrosCusto });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_COSTCENTERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findCenter(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_COSTCENTER_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findCenter {
                  centroCusto (codigo: ${codigo}) {
                    codigo
                    status
                    descricao
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_COSTCENTER_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { centroCusto } = data
        dispatch({ type: FETCH_COSTCENTER_FULFILLED, payload: centroCusto });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_COSTCENTER_REJECTED, error });
      resolve(error);
    });

  });

}

export function createCenter(center) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_COSTCENTER_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation createCenter {
            createCentroCusto (input: {
              codigo: ${center.codigo}
              status: ${center.status}
              descricao: "${center.descricao}"
            } ) 
            {
              codigo
              status
              descricao
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_COSTCENTER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createCentroCusto } = data
        dispatch({ type: CREATE_COSTCENTER_FULFILLED, payload: createCentroCusto });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_COSTCENTER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateCenter(center) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_COSTCENTER_PENDING });

    api({
      method: 'post',   
      data: {
        query: `
          mutation UpdateBank {
            updateCentroCusto (input: {
              codigo: ${center.codigo}
              status: ${center.status}
              descricao: "${center.descricao}"
            } ) 
            {
              codigo
              status
              descricao
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_COSTCENTER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateCentroCusto } = data
        dispatch({ type: CREATE_COSTCENTER_FULFILLED, payload: updateCentroCusto });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_COSTCENTER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeCenter(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_COSTCENTER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveCostCenter {
            deleteCentroCusto (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_COSTCENTER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteCentroCusto } = data
        dispatch({ type: DELETE_COSTCENTER_FULFILLED, payload: deleteCentroCusto });
        dispatch(findAllCostCenters());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_COSTCENTER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
