import api from '../../../../api'

export const FETCH_CLASS_ORGANS_PENDING = 'FETCH_CLASS_ORGANS_PENDING'
export const FETCH_CLASS_ORGANS_FULFILLED = 'FETCH_CLASS_ORGANS_FULFILLED'
export const FETCH_CLASS_ORGANS_REJECTED = 'FETCH_CLASS_ORGANS_REJECTED'

export const FETCH_CLASS_ORGAN_PENDING = 'FETCH_CLASS_ORGAN_PENDING'
export const FETCH_CLASS_ORGAN_FULFILLED = 'FETCH_CLASS_ORGAN_FULFILLED'
export const FETCH_CLASS_ORGAN_REJECTED = 'FETCH_CLASS_ORGAN_REJECTED'

export const CREATE_CLASS_ORGAN_PENDING = 'CREATE_CLASS_ORGAN_PENDING'
export const CREATE_CLASS_ORGAN_FULFILLED = 'CREATE_CLASS_ORGAN_FULFILLED'
export const CREATE_CLASS_ORGAN_REJECTED = 'CREATE_CLASS_ORGAN_REJECTED'

export const UPDATE_CLASS_ORGAN_PENDING = 'UPDATE_CLASS_ORGAN_PENDING'
export const UPDATE_CLASS_ORGAN_FULFILLED = 'UPDATE_CLASS_ORGAN_FULFILLED'
export const UPDATE_CLASS_ORGAN_REJECTED = 'UPDATE_CLASS_ORGAN_REJECTED'

export const DELETE_CLASS_ORGAN_PENDING = 'DELETE_CLASS_ORGAN_PENDING'
export const DELETE_CLASS_ORGAN_FULFILLED = 'DELETE_CLASS_ORGAN_FULFILLED'
export const DELETE_CLASS_ORGAN_REJECTED = 'DELETE_CLASS_ORGAN_REJECTED'

export const CHANGE_CLASS_ORGAN = 'CHANGE_CLASS_ORGAN'

export const ADD_CLASS_ORGAN = 'ADD_CLASS_ORGAN'

export function changeClassOrgan(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_CLASS_ORGAN, payload: { name, value } })
  }
}
 
export function addClassOrgan() {
  return dispatch => {
    dispatch({ type: ADD_CLASS_ORGAN });
  };
}

export function findAllClassOrgans(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CLASS_ORGANS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllClassOrgans {
                  orgaosClasse (first: ${first} offset: ${offset}) {
                    codigo
                    nome
                    sigla
                    status
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CLASS_ORGANS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { orgaosClasse } = data
        dispatch({ type: FETCH_CLASS_ORGANS_FULFILLED, payload: orgaosClasse });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CLASS_ORGANS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findClassOrgan(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CLASS_ORGAN_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findClassOrgan {
                  orgaoClasse (codigo: ${codigo}) {
                    codigo
                    nome
                    sigla
                    status
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CLASS_ORGAN_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { orgaoClasse } = data
        dispatch({ type: FETCH_CLASS_ORGAN_FULFILLED, payload: orgaoClasse });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CLASS_ORGAN_REJECTED, error });
      resolve(error);
    });

  });

}

export function createClassOrgan(classOrgan) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CLASS_ORGAN_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
          mutation createClassOrgan {
            createOrgaoClasse (input: {
              codigo: ${classOrgan.codigo}
              nome: "${classOrgan.nome}"
              sigla: "${classOrgan.sigla}"
              status: ${classOrgan.status}
            } ) 
            {
              codigo
              nome
              sigla
              status
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CLASS_ORGAN_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createOrgaoClasse } = data
        dispatch({ type: CREATE_CLASS_ORGAN_FULFILLED, payload: createOrgaoClasse });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CLASS_ORGAN_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateClassOrgan(classOrgan) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CLASS_ORGAN_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation UpdateClassOrgan {
            updateOrgaoClasse (input: {
              codigo: ${classOrgan.codigo}
              nome: "${classOrgan.nome}"
              sigla: "${classOrgan.sigla}"
              status: ${classOrgan.status}
            } ) 
            {
              codigo
              nome
              sigla
              status
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CLASS_ORGAN_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateOrgaoClasse } = data
        dispatch({ type: CREATE_CLASS_ORGAN_FULFILLED, payload: updateOrgaoClasse });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CLASS_ORGAN_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeClassOrgan(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_CLASS_ORGAN_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveClassOrgan {
            deleteOrgaoClasse (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_CLASS_ORGAN_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteOrgaoClasse } = data
        dispatch({ type: DELETE_CLASS_ORGAN_FULFILLED, payload: deleteOrgaoClasse });
        dispatch(findAllClassOrgans());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_CLASS_ORGAN_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
