import api from '../../../../../api'

export const FETCH_GROUPS_PRODUCT_PENDING = 'FETCH_GROUPS_PRODUCT_PENDING'
export const FETCH_GROUPS_PRODUCT_FULFILLED = 'FETCH_GROUPS_PRODUCT_FULFILLED'
export const FETCH_GROUPS_PRODUCT_REJECTED = 'FETCH_GROUPS_PRODUCT_REJECTED'

export const FETCH_GROUP_PRODUCT_PENDING = 'FETCH_GROUP_PRODUCT_PENDING'
export const FETCH_GROUP_PRODUCT_FULFILLED = 'FETCH_GROUP_PRODUCT_FULFILLED'
export const FETCH_GROUP_PRODUCT_REJECTED = 'FETCH_GROUP_PRODUCT_REJECTED'

export const CREATE_GROUP_PRODUCT_PENDING = 'CREATE_GROUP_PRODUCT_PENDING'
export const CREATE_GROUP_PRODUCT_FULFILLED = 'CREATE_GROUP_PRODUCT_FULFILLED'
export const CREATE_GROUP_PRODUCT_REJECTED = 'CREATE_GROUP_PRODUCT_REJECTED'

export const UPDATE_GROUP_PRODUCT_PENDING = 'UPDATE_GROUP_PRODUCT_PENDING'
export const UPDATE_GROUP_PRODUCT_FULFILLED = 'UPDATE_GROUP_PRODUCT_FULFILLED'
export const UPDATE_GROUP_PRODUCT_REJECTED = 'UPDATE_GROUP_PRODUCT_REJECTED'

export const DELETE_GROUP_PRODUCT_PENDING = 'DELETE_GROUP_PRODUCT_PENDING'
export const DELETE_GROUP_PRODUCT_FULFILLED = 'DELETE_GROUP_PRODUCT_FULFILLED'
export const DELETE_GROUP_PRODUCT_REJECTED = 'DELETE_GROUP_PRODUCT_REJECTED'

export const CHANGE_GROUP_PRODUCT = 'CHANGE_GROUP_PRODUCT'

export const ADD_GROUP_PRODUCT = 'ADD_GROUP_PRODUCT'

export function changeGroupProduct(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_GROUP_PRODUCT, payload: { name, value } })
  }
}
 
export function addGroupProduct() {
  return dispatch => {
    dispatch({ type: ADD_GROUP_PRODUCT });
  };
}

export function findAllGroupsProduct(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_GROUPS_PRODUCT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllGroups {
                  grupoProdutos (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_GROUPS_PRODUCT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { grupoProdutos } = data
        dispatch({ type: FETCH_GROUPS_PRODUCT_FULFILLED, payload: grupoProdutos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_GROUPS_PRODUCT_REJECTED, error });
      resolve(error);
    });

  });

}

export function findGroupProduct(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_GROUP_PRODUCT_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
                query findGroup {
                  grupoProduto (codigo: ${codigo}) {
                    codigo
                    descricao
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_GROUP_PRODUCT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { grupoProduto } = data
        dispatch({ type: FETCH_GROUP_PRODUCT_FULFILLED, payload: grupoProduto });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_GROUP_PRODUCT_REJECTED, error });
      resolve(error);
    });

  });

}

export function createGroupProduct(group) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_GROUP_PRODUCT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createGroup {
            createGrupoProduto (input: {
              codigo: ${group.codigo}
              descricao: "${group.descricao}"
            } ) 
            {
              codigo
              descricao
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_GROUP_PRODUCT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createGrupoProduto } = data
        dispatch({ type: CREATE_GROUP_PRODUCT_FULFILLED, payload: createGrupoProduto });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_GROUP_PRODUCT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateGroupProduct(group) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_GROUP_PRODUCT_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
          mutation UpdateGroup {
            updateGrupoProduto (input: {
              codigo: ${group.codigo}
              descricao: "${group.descricao}"
            } ) 
            {
              codigo
              descricao
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_GROUP_PRODUCT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateGrupoProduto } = data
        dispatch({ type: CREATE_GROUP_PRODUCT_FULFILLED, payload: updateGrupoProduto });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_GROUP_PRODUCT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeGroupProduct(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_GROUP_PRODUCT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveGroup {
            deleteGrupoProduto (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_GROUP_PRODUCT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteGrupoProduto } = data
        dispatch({ type: DELETE_GROUP_PRODUCT_FULFILLED, payload: deleteGrupoProduto });
        dispatch(findAllGroupsProduct());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_GROUP_PRODUCT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
