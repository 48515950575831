import api from '../../../../../api'
import { toFloat } from '../../../../../common/utils'

export const FETCH_SHIPPINGS_PENDING = 'FETCH_SHIPPINGS_PENDING'
export const FETCH_SHIPPINGS_FULFILLED = 'FETCH_SHIPPINGS_FULFILLED'
export const FETCH_SHIPPINGS_REJECTED = 'FETCH_SHIPPINGS_REJECTED'

export const FETCH_SHIPPING_PENDING = 'FETCH_SHIPPING_PENDING'
export const FETCH_SHIPPING_FULFILLED = 'FETCH_SHIPPING_FULFILLED'
export const FETCH_SHIPPING_REJECTED = 'FETCH_SHIPPING_REJECTED'

export const CREATE_SHIPPING_PENDING = 'CREATE_SHIPPING_PENDING'
export const CREATE_SHIPPING_FULFILLED = 'CREATE_SHIPPING_FULFILLED'
export const CREATE_SHIPPING_REJECTED = 'CREATE_SHIPPING_REJECTED'

export const UPDATE_SHIPPING_PENDING = 'UPDATE_SHIPPING_PENDING'
export const UPDATE_SHIPPING_FULFILLED = 'UPDATE_SHIPPING_FULFILLED'
export const UPDATE_SHIPPING_REJECTED = 'UPDATE_SHIPPING_REJECTED'

export const DELETE_SHIPPING_PENDING = 'DELETE_SHIPPING_PENDING'
export const DELETE_SHIPPING_FULFILLED = 'DELETE_SHIPPING_FULFILLED'
export const DELETE_SHIPPING_REJECTED = 'DELETE_SHIPPING_REJECTED'

export const CHANGE_SHIPPING = 'CHANGE_SHIPPING'

export const ADD_SHIPPING = 'ADD_SHIPPING'

export function changeShipping(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SHIPPING, payload: { name, value } })
  }
}
 
export function addShipping() {
  return dispatch => {
    dispatch({ type: ADD_SHIPPING });
  };
}

export function findAllShippings(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SHIPPINGS_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllShippings {
                  portes (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    valorNormal
                    tipoRedutor
                    valorRedutor
                    valorReduzido
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SHIPPINGS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { portes } = data
        dispatch({ type: FETCH_SHIPPINGS_FULFILLED, payload: portes });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SHIPPINGS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findShipping(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SHIPPING_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
                query findShipping {
                  porte (codigo: ${codigo}) {
                    codigo
                    descricao
                    valorNormal
                    tipoRedutor
                    valorRedutor
                    valorReduzido
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SHIPPING_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { porte } = data
        dispatch({ type: FETCH_SHIPPING_FULFILLED, payload: porte });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SHIPPING_REJECTED, error });
      resolve(error);
    });

  });

}

export function createShipping(shipping) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SHIPPING_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createShipping {
            createPorte (input: {
              codigo: ${shipping.codigo}
              descricao: "${shipping.descricao}"
              valorNormal: ${toFloat(shipping.valorNormal)}
              tipoRedutor: ${toFloat(shipping.tipoRedutor)}
              valorRedutor: ${toFloat(shipping.valorRedutor)}
              valorReduzido: ${toFloat(shipping.valorReduzido)}
            } ) 
            {
              codigo
              descricao
              valorNormal
              tipoRedutor
              valorRedutor
              valorReduzido
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SHIPPING_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createPorte } = data
        dispatch({ type: CREATE_SHIPPING_FULFILLED, payload: createPorte });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SHIPPING_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateShipping(shipping) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SHIPPING_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateShipping {
            updatePorte (input: {
              codigo: ${shipping.codigo}
              descricao: "${shipping.descricao}"
              valorNormal: ${toFloat(shipping.valorNormal)}
              tipoRedutor: ${toFloat(shipping.tipoRedutor)}
              valorRedutor: ${toFloat(shipping.valorRedutor)}
              valorReduzido: ${toFloat(shipping.valorReduzido)}
            } ) 
            {
              codigo
              descricao
              valorNormal
              tipoRedutor
              valorRedutor
              valorReduzido
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SHIPPING_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updatePorte } = data
        dispatch({ type: CREATE_SHIPPING_FULFILLED, payload: updatePorte });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SHIPPING_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeShipping(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_SHIPPING_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveShipping {
            deletePorte (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_SHIPPING_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deletePorte } = data
        dispatch({ type: DELETE_SHIPPING_FULFILLED, payload: deletePorte });
        dispatch(findAllShippings());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_SHIPPING_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
