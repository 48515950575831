import api from '../../../../api'

const Entities = require('html-entities').Html5Entities;
const entities = new Entities();

export const FETCH_CAMPAIGNS_PENDING = 'FETCH_CAMPAIGNS_PENDING'
export const FETCH_CAMPAIGNS_FULFILLED = 'FETCH_CAMPAIGNS_FULFILLED'
export const FETCH_CAMPAIGNS_REJECTED = 'FETCH_CAMPAIGNS_REJECTED'

export const FETCH_CAMPAIGN_PENDING = 'FETCH_CAMPAIGN_PENDING'
export const FETCH_CAMPAIGN_FULFILLED = 'FETCH_CAMPAIGN_FULFILLED'
export const FETCH_CAMPAIGN_REJECTED = 'FETCH_CAMPAIGN_REJECTED'

export const CREATE_CAMPAIGN_PENDING = 'CREATE_CAMPAIGN_PENDING'
export const CREATE_CAMPAIGN_FULFILLED = 'CREATE_CAMPAIGN_FULFILLED'
export const CREATE_CAMPAIGN_REJECTED = 'CREATE_CAMPAIGN_REJECTED'

export const UPDATE_CAMPAIGN_PENDING = 'UPDATE_CAMPAIGN_PENDING'
export const UPDATE_CAMPAIGN_FULFILLED = 'UPDATE_CAMPAIGN_FULFILLED'
export const UPDATE_CAMPAIGN_REJECTED = 'UPDATE_CAMPAIGN_REJECTED'

export const DELETE_CAMPAIGN_PENDING = 'DELETE_CAMPAIGN_PENDING'
export const DELETE_CAMPAIGN_FULFILLED = 'DELETE_CAMPAIGN_FULFILLED'
export const DELETE_CAMPAIGN_REJECTED = 'DELETE_CAMPAIGN_REJECTED'

export const CHANGE_CAMPAIGN = 'CHANGE_CAMPAIGN'

export const ADD_CAMPAIGN = 'ADD_CAMPAIGN'

export function changeCampaign(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_CAMPAIGN, payload: { name, value } })
  }
}

export function addCampaign() {
  return dispatch => {
    dispatch({ type: ADD_CAMPAIGN });
  };
}

export function findAllCampaigns(first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CAMPAIGNS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllCampaigns {
                  campanhas (first: ${first} offset: ${offset}) {
                    codigo
                    title
                    shortDescription
                    startDate
                    endDate
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CAMPAIGNS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { campanhas } = data
        dispatch({ type: FETCH_CAMPAIGNS_FULFILLED, payload: campanhas });
        resolve(campanhas);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CAMPAIGNS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findCampaign(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CAMPAIGN_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findCampaign {
                  campanha (codigo: ${codigo}) {
                    codigo
                    title
                    shortDescription
                    htmlContent
                    expire
                    startDate
                    endDate
                    imageHomeUrl
                    imageDetailUrl
                    conditions
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CAMPAIGN_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { campanha } = data
        dispatch({ type: FETCH_CAMPAIGN_FULFILLED, payload: campanha });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CAMPAIGN_REJECTED, error });
      resolve(error);
    });

  });

}

export function createCampaign(campaign) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CAMPAIGN_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation createCampaign {
            createCampanha (input: {
              codigo: ${campaign.codigo}
              title: "${campaign.title}"
              shortDescription: "${campaign.shortDescription}"
              htmlContent: "${entities.encode(campaign.htmlContent)}"
              expire: ${campaign.expire}
              startDate: "${campaign.startDate}"
              endDate: "${campaign.endDate}"
              imageHomeUrl: "${campaign.imageHomeUrl}"
              imageDetailUrl: "${campaign.imageDetailUrl}"
              conditions: "${campaign.conditions}"
            } ) {
              codigo
              title
              shortDescription
              htmlContent
              expire
              startDate
              endDate
              imageHomeUrl
              imageDetailUrl
              conditions
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CAMPAIGN_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createCampanha } = data
        dispatch({ type: CREATE_CAMPAIGN_FULFILLED, payload: createCampanha });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CAMPAIGN_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateCampaign(campaign) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_CAMPAIGN_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation UpdateCampaign {
            updateCampanha (input: {
              codigo: ${campaign.codigo}
              title: "${campaign.title}"
              shortDescription: "${campaign.shortDescription}"
              htmlContent: "${entities.encode(campaign.htmlContent)}"
              expire: ${campaign.expire}
              startDate: "${campaign.startDate}"
              endDate: "${campaign.endDate}"
              imageHomeUrl: "${campaign.imageHomeUrl}"
              imageDetailUrl: "${campaign.imageDetailUrl}"
              conditions: "${campaign.conditions}"
            } ) {
              codigo
              title
              shortDescription
              htmlContent
              expire
              startDate
              endDate
              imageHomeUrl
              imageDetailUrl
              conditions
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_CAMPAIGN_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateCampanha } = data
        dispatch({ type: UPDATE_CAMPAIGN_FULFILLED, payload: updateCampanha });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_CAMPAIGN_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeCampaign(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_CAMPAIGN_PENDING });

    api({
      method: 'post',    
      data: {
        query: `
          mutation RemoveCampaign {
            deleteCampanha (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_CAMPAIGN_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteCampanha } = data
        dispatch({ type: DELETE_CAMPAIGN_FULFILLED, payload: deleteCampanha });
        dispatch(findAllCampaigns());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_CAMPAIGN_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
