import moment from "moment";
import decode from "jwt-decode";
import swal from 'sweetalert';
import { cpf, cnpj } from 'cpf-cnpj-validator'; 

export function empty(e) {
    if ((e) && (e instanceof String)) e = e.replace(/\s/g,"");
    switch (e) {
      case "":
      case 0:
      case "0":
      case null:
      case false:
      case undefined:
      case typeof(e) == "undefined":
        return true;
      default:
        return false;
    }
  }

export function titleCase(str) {
    if (!str || !(typeof str === 'string')) return str;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

export function upperCase(str) {
  if (!str || !(typeof str === 'string')) return str;
  return str.toUpperCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

export function toFloat(value) {
  value = `${value}`.replace(".","").replace(",",".")
  return isNaN(parseFloat(value)) ? 0.00 : parseFloat(value);
}

export function toFixedFloat(value) {
  return toFloat(value).toFixed(2).replace(".",",");
}

export function formatedFloat(value) {
  value = `${value}`.replace(".","").replace(",",".")
  return isNaN( parseFloat(value) ) ? '0,00' : parseFloat(value).toFixed(2).replace(".",",");
}

export function formatarMoeda(valor) { 
  if (!`${valor}`.includes(".")) {
    valor += ".00";
  }
  valor = valor + '';
  valor = parseInt(valor.replace(/[\D]+/g,''));
  valor = valor + '';
  valor = valor.replace(/([0-9]{2})$/g, ",$1");

  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  }
  return valor;
}

export function getTodayDate() {
  return moment().format('DD/MM/YYYY');
}

export function toInt(value) {
  return isNaN(parseInt(value)) ? 0 : parseInt(value);
}

export function testBit(byte, bit) {
  return ((byte >> bit) % 2 !== 0);
}

export function between(number, from, to) {
  return (number >> from) & ((1 << to - from) - 1);
}

export function from(number, from) {
  return number >> from;
}

export function to(number, to) {
  return this.between(number, 0, to);
}

export function setBit(byte, bit) {
  return (byte | (1 << bit));
}

export function clearBit(byte, bit) {
  return byte & ~(1 << bit);
}

export function parseBool(b) {
  return !(/^(false|0)$/i).test(b) && !!b;
}

export function getPermissions() {
  const token = localStorage.getItem("@RapidoSaude");
  if (token) {
    const data = decode(token);
    return data.rules.split(';');
  }
  return ["0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0","0"]
}

export function IsCEP(strCEP)
{
    var cep = /^[0-9]{2}[0-9]{3}-[0-9]{3}$/;
    strCEP = strCEP.trim();
    if(strCEP.length > 0)
    {
        if(cep.test(strCEP))
            return true;
        else
            return false;
    }
    else
        return false;
}

export function isValidCPFCNPJ(value) {
  let error = false;
  value = value.replace(/[^a-z0-9]/gi,'');
  if ((value.length >= 14) && (!cnpj.isValid(value))) {
    error = true;
  } else if ((value.length <= 11) && (!cpf.isValid(value))) {
    error = true;
  }
  if (error) {
    swal("CPF / CNPJ Inválido. Verifique!", { icon: "error", })
    .then(() => { });
    return false;
  }
  return true;
}