import api from '../../../../api'

export const FETCH_PRODUCTS_PENDING = 'FETCH_PRODUCTS_PENDING'
export const FETCH_PRODUCTS_FULFILLED = 'FETCH_PRODUCTS_FULFILLED'
export const FETCH_PRODUCTS_REJECTED = 'FETCH_PRODUCTS_REJECTED'

export const FETCH_PRODUCT_PENDING = 'FETCH_PRODUCT_PENDING'
export const FETCH_PRODUCT_FULFILLED = 'FETCH_PRODUCT_FULFILLED'
export const FETCH_PRODUCT_REJECTED = 'FETCH_PRODUCT_REJECTED'

export const CREATE_PRODUCT_PENDING = 'CREATE_PRODUCT_PENDING'
export const CREATE_PRODUCT_FULFILLED = 'CREATE_PRODUCT_FULFILLED'
export const CREATE_PRODUCT_REJECTED = 'CREATE_PRODUCT_REJECTED'

export const UPDATE_PRODUCT_PENDING = 'UPDATE_PRODUCT_PENDING'
export const UPDATE_PRODUCT_FULFILLED = 'UPDATE_PRODUCT_FULFILLED'
export const UPDATE_PRODUCT_REJECTED = 'UPDATE_PRODUCT_REJECTED'

export const DELETE_PRODUCT_PENDING = 'DELETE_PRODUCT_PENDING'
export const DELETE_PRODUCT_FULFILLED = 'DELETE_PRODUCT_FULFILLED'
export const DELETE_PRODUCT_REJECTED = 'DELETE_PRODUCT_REJECTED'

export const CHANGE_PRODUCT = 'CHANGE_PRODUCT'

export const ADD_PRODUCT = 'ADD_PRODUCT'

export function changeProduct(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_PRODUCT, payload: { name, value } })
  }
}
 
export function addProduct() {
  return dispatch => {
    dispatch({ type: ADD_PRODUCT });
  };
}

export function findAllProducts(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PRODUCTS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllProducts {
                  produtos (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    dataCadastro
                    dataUltimaCompra
                    codigoBarras
                    codigoCest
                    classificacaoNCM
                    origemProduto
                    produto
                    produtoReduzido
                    referencia
                    marca
                    grupo
                    subGrupo
                    unidade
                    quantidade
                    pesoBruto
                    pesoLiquido
                    planoContas
                    contaContabil
                    cstEstadual
                    cstSimples
                    icms
                    ipi
                    pis
                    cofins
                    cfop
                    valorCusto
                    valorLucro
                    margemLucro
                    valorDesconto
                    valorVenda
                    estoqueAtual
                    estoqueMinimo
                    estoqueMaximo
                    pedidoCompra
                    fornecedor  
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PRODUCTS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { produtos } = data
        dispatch({ type: FETCH_PRODUCTS_FULFILLED, payload: produtos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PRODUCTS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findProduct(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PRODUCT_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findProduct {
                  produto (codigo: ${codigo}) {
                    codigo
                    status
                    dataCadastro
                    dataUltimaCompra
                    codigoBarras
                    codigoCest
                    classificacaoNCM
                    origemProduto
                    produto
                    produtoReduzido
                    referencia
                    marca
                    grupo
                    subGrupo
                    unidade
                    quantidade
                    pesoBruto
                    pesoLiquido
                    planoContas
                    contaContabil
                    cstEstadual
                    cstSimples
                    icms
                    ipi
                    pis
                    cofins
                    cfop
                    valorCusto
                    valorLucro
                    margemLucro
                    valorDesconto
                    valorVenda
                    estoqueAtual
                    estoqueMinimo
                    estoqueMaximo
                    pedidoCompra
                    fornecedor  
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PRODUCT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { produto } = data
        dispatch({ type: FETCH_PRODUCT_FULFILLED, payload: produto });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PRODUCT_REJECTED, error });
      resolve(error);
    });

  });

}

export function createProduct(product) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PRODUCT_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createProduct {
            createProduto (input: {
              codigo: ${product.codigo}
              status: ${product.status}
              dataCadastro: ${product.dataCadastro}
              dataUltimaCompra: ${product.dataUltimaCompra}
              codigoBarras: ${product.codigoBarras}
              codigoCest: ${product.codigoCest}
              classificacaoNCM: ${product.classificacaoNCM}
              origemProduto: ${product.origemProduto}
              produto: ${product.produto}
              produtoReduzido: ${product.produtoReduzido}
              referencia: ${product.referencia}
              marca: ${product.marca}
              grupo: ${product.grupo}
              subGrupo: ${product.subGrupo}
              unidade: ${product.unidade}
              quantidade: ${product.quantidade}
              pesoBruto: ${product.pesoBruto}
              pesoLiquido: ${product.pesoLiquido}
              planoContas: ${product.planoContas}
              contaContabil: ${product.contaContabil}
              cstEstadual: ${product.cstEstadual}
              cstSimples: ${product.cstSimples}
              cfop: ${product.cfop}
              icms: ${product.icms}
              ipi: ${product.ipi}
              pis: ${product.pis}
              cofins: ${product.cofins}
              valorCusto: ${product.valorCusto}
              valorLucro: ${product.valorLucro}
              margemLucro: ${product.margemLucro}
              valorDesconto: ${product.valorDesconto}
              valorVenda: ${product.valorVenda}
              estoqueAtual: ${product.estoqueAtual}
              estoqueMinimo: ${product.estoqueMinimo}
              estoqueMaximo: ${product.estoqueMaximo}
              pedidoCompra: ${product.pedidoCompra}
              fornecedor: ${product.fornecedor}
      } ) 
            {
              codigo
              status
              dataCadastro
              dataUltimaCompra
              codigoBarras
              codigoCest
              classificacaoNCM
              origemProduto
              produto
              produtoReduzido
              referencia
              marca
              grupo
              subGrupo
              unidade
              quantidade
              pesoBruto
              pesoLiquido
              planoContas
              contaContabil
              cstEstadual
              cstSimples
              icms
              ipi
              pis
              cofins
              cfop
              valorCusto
              valorLucro
              margemLucro
              valorDesconto
              valorVenda
              estoqueAtual
              estoqueMinimo
              estoqueMaximo
              pedidoCompra
              fornecedor  
      }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PRODUCT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createProduto } = data
        dispatch({ type: CREATE_PRODUCT_FULFILLED, payload: createProduto });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PRODUCT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateProduct(product) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PRODUCT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateProduct {
            updateProduto (input: {
              codigo: ${product.codigo}
              status: ${product.status}
              dataCadastro: ${product.dataCadastro}
              dataUltimaCompra: ${product.dataUltimaCompra}
              codigoBarras: ${product.codigoBarras}
              codigoCest: ${product.codigoCest}
              classificacaoNCM: ${product.classificacaoNCM}
              origemProduto: ${product.origemProduto}
              produto: ${product.produto}
              produtoReduzido: ${product.produtoReduzido}
              referencia: ${product.referencia}
              marca: ${product.marca}
              grupo: ${product.grupo}
              subGrupo: ${product.subGrupo}
              unidade: ${product.unidade}
              quantidade: ${product.quantidade}
              pesoBruto: ${product.pesoBruto}
              pesoLiquido: ${product.pesoLiquido}
              planoContas: ${product.planoContas}
              contaContabil: ${product.contaContabil}
              cstEstadual: ${product.cstEstadual}
              cstSimples: ${product.cstSimples}
              cfop: ${product.cfop}
              icms: ${product.icms}
              ipi: ${product.ipi}
              pis: ${product.pis}
              cofins: ${product.cofins}
              valorCusto: ${product.valorCusto}
              valorLucro: ${product.valorLucro}
              margemLucro: ${product.margemLucro}
              valorDesconto: ${product.valorDesconto}
              valorVenda: ${product.valorVenda}
              estoqueAtual: ${product.estoqueAtual}
              estoqueMinimo: ${product.estoqueMinimo}
              estoqueMaximo: ${product.estoqueMaximo}
              pedidoCompra: ${product.pedidoCompra}
              fornecedor: ${product.fornecedor}
            } ) 
            {
              codigo
              status
              dataCadastro
              dataUltimaCompra
              codigoBarras
              codigoCest
              classificacaoNCM
              origemProduto
              produto
              produtoReduzido
              referencia
              marca
              grupo
              subGrupo
              unidade
              quantidade
              pesoBruto
              pesoLiquido
              planoContas
              contaContabil
              cstEstadual
              cstSimples
              icms
              ipi
              pis
              cofins
              cfop
              valorCusto
              valorLucro
              margemLucro
              valorDesconto
              valorVenda
              estoqueAtual
              estoqueMinimo
              estoqueMaximo
              pedidoCompra
              fornecedor  
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PRODUCT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateProduto } = data
        dispatch({ type: CREATE_PRODUCT_FULFILLED, payload: updateProduto });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PRODUCT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeProduct(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_PRODUCT_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveProduct {
            deleteProduto (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_PRODUCT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteProduto } = data
        dispatch({ type: DELETE_PRODUCT_FULFILLED, payload: deleteProduto });
        dispatch(findAllProducts());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_PRODUCT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
