import api from '../../../../api'

export const FETCH_MANAGEMENT_PLANS_PENDING = 'FETCH_MANAGEMENT_PLANS_PENDING'
export const FETCH_MANAGEMENT_PLANS_FULFILLED = 'FETCH_MANAGEMENT_PLANS_FULFILLED'
export const FETCH_MANAGEMENT_PLANS_REJECTED = 'FETCH_MANAGEMENT_PLANS_REJECTED'

export const FETCH_MANAGEMENT_PLAN_PENDING = 'FETCH_MANAGEMENT_PLAN_PENDING'
export const FETCH_MANAGEMENT_PLAN_FULFILLED = 'FETCH_MANAGEMENT_PLAN_FULFILLED'
export const FETCH_MANAGEMENT_PLAN_REJECTED = 'FETCH_MANAGEMENT_PLAN_REJECTED'

export const CREATE_MANAGEMENT_PLAN_PENDING = 'CREATE_MANAGEMENT_PLAN_PENDING'
export const CREATE_MANAGEMENT_PLAN_FULFILLED = 'CREATE_MANAGEMENT_PLAN_FULFILLED'
export const CREATE_MANAGEMENT_PLAN_REJECTED = 'CREATE_MANAGEMENT_PLAN_REJECTED'

export const UPDATE_MANAGEMENT_PLAN_PENDING = 'UPDATE_MANAGEMENT_PLAN_PENDING'
export const UPDATE_MANAGEMENT_PLAN_FULFILLED = 'UPDATE_MANAGEMENT_PLAN_FULFILLED'
export const UPDATE_MANAGEMENT_PLAN_REJECTED = 'UPDATE_MANAGEMENT_PLAN_REJECTED'

export const DELETE_MANAGEMENT_PLAN_PENDING = 'DELETE_MANAGEMENT_PLAN_PENDING'
export const DELETE_MANAGEMENT_PLAN_FULFILLED = 'DELETE_MANAGEMENT_PLAN_FULFILLED'
export const DELETE_MANAGEMENT_PLAN_REJECTED = 'DELETE_MANAGEMENT_PLAN_REJECTED'

export const CHANGE_MANAGEMENT_PLAN = 'CHANGE_MANAGEMENT_PLAN'

export const ADD_MANAGEMENT_PLAN = 'ADD_MANAGEMENT_PLAN'

export function changeManagementPlan(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_MANAGEMENT_PLAN, payload: { name, value } })
  }
}
 
export function addManagementPlan() {
  return dispatch => {
    dispatch({ type: ADD_MANAGEMENT_PLAN });
  };
}

export function findAllManagementPlans(tipos = "C,D", first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_MANAGEMENT_PLANS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllManagementPlans {
                  planoContas (tipos: "${tipos}" first: ${first} offset: ${offset}) {
                    codigo
                    contaMae
                    classificacao
                    descricao
                    tipo
                    editavel  
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_MANAGEMENT_PLANS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { planoContas } = data
        dispatch({ type: FETCH_MANAGEMENT_PLANS_FULFILLED, payload: planoContas });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_MANAGEMENT_PLANS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findManagementPlan(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_MANAGEMENT_PLAN_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findManagementPlan {
                  planoConta (codigo: ${codigo}) {
                    codigo
                    contaMae
                    classificacao
                    descricao
                    tipo
                    editavel
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_MANAGEMENT_PLAN_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { planoConta } = data
        dispatch({ type: FETCH_MANAGEMENT_PLAN_FULFILLED, payload: planoConta });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_MANAGEMENT_PLAN_REJECTED, error });
      resolve(error);
    });

  });

}

export function createManagementPlan(managementPlan) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_MANAGEMENT_PLAN_PENDING });

    api({
      method: 'post',      
      data: { 
        query: `
          mutation createManagementPlan {
            createPlanoConta (input: {
              codigo: ${managementPlan.codigo}
              contaMae: ${managementPlan.contaMae}
              classificacao: "${managementPlan.classificacao}"
              descricao: "${managementPlan.descricao}"
              tipo: "${managementPlan.tipo}"
              editavel: ${managementPlan.editavel}
            } ) 
            {
              codigo
              contaMae
              classificacao
              descricao
              tipo
              editavel
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_MANAGEMENT_PLAN_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createPlanoConta } = data
        dispatch({ type: CREATE_MANAGEMENT_PLAN_FULFILLED, payload: createPlanoConta });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_MANAGEMENT_PLAN_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateManagementPlan(managementPlan) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_MANAGEMENT_PLAN_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateManagementPlan {
            updatePlanoConta (input: {
              codigo: ${managementPlan.codigo}
              contaMae: ${managementPlan.contaMae}
              classificacao: "${managementPlan.classificacao}"
              descricao: "${managementPlan.descricao}"
              tipo: "${managementPlan.tipo}"
              editavel: ${managementPlan.editavel}
            } ) 
            {
              codigo
              contaMae
              classificacao
              descricao
              tipo
              editavel
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_MANAGEMENT_PLAN_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updatePlanoConta } = data
        dispatch({ type: CREATE_MANAGEMENT_PLAN_FULFILLED, payload: updatePlanoConta });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_MANAGEMENT_PLAN_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeManagementPlan(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_MANAGEMENT_PLAN_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveManagementPlan {
            deletePlanoConta (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_MANAGEMENT_PLAN_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deletePlanoConta } = data
        dispatch({ type: DELETE_MANAGEMENT_PLAN_FULFILLED, payload: deletePlanoConta });
        dispatch(findAllManagementPlans());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_MANAGEMENT_PLAN_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
