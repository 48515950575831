import {

    FETCH_USERS_PENDING,
    FETCH_USERS_FULFILLED,
    FETCH_USERS_REJECTED,

    FETCH_USER_PENDING,
    FETCH_USER_FULFILLED,
    FETCH_USER_REJECTED,

    CREATE_USER_PENDING,
    CREATE_USER_FULFILLED,
    CREATE_USER_REJECTED,

    UPDATE_USER_PENDING,
    UPDATE_USER_FULFILLED,
    UPDATE_USER_REJECTED,

    DELETE_USER_PENDING,
    DELETE_USER_FULFILLED,
    DELETE_USER_REJECTED,
    
    CHANGE_USER,
    
    ADD_USER

} from './userActions'

import { titleCase } from '../../../../common/utils'

const initialState = {
    loading: false,
    listOfUsers: [],
    user: {
        codigo: 0,
        status: 0,
        perfil: 0,
        nome: '',
        celular: '',
        validade: '',
        login: '',
        senha: '',
        confirmaSenha: '',
        email: '',
    },
    error: null,
}

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_USER:
            
        let value = ""
                
        if (action.payload.name.includes('mail') || action.payload.name.includes('login')) {
            value = action.payload.value.toLowerCase();
        } else 
            if (action.payload.name.includes('senha') || action.payload.name.includes('confirmaSenha')) {
                value = action.payload.value;
            } else {
                value = titleCase(action.payload.value);
            }
        return Object.assign({}, state, {
            user: { ...state.user, 
                [action.payload.name]: value
            }
        })
        case ADD_USER: 
            return Object.assign({}, state, {
                user: { ...initialState.user }
            })
        case FETCH_USERS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfUsers: [],
            })
        case FETCH_USERS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfUsers: action.payload
            })
        case FETCH_USERS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_USER_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //user: { ...initialState.user },
            })
        case CREATE_USER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                user: { ...action.payload, senha: '', confirmaSenha: ''}
            })
        case CREATE_USER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_USER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_USER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                user: action.payload
            })
        case UPDATE_USER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_USER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_USER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                user: { ...action.payload, senha: '', confirmaSenha: ''}
            })
        case FETCH_USER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_USER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_USER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_USER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}