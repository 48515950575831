import api from '../../../../api'
import { toFloat } from '../../../../common/utils'

export const FETCH_PROCEDURES_PENDING = 'FETCH_PROCEDURES_PENDING'
export const FETCH_PROCEDURES_FULFILLED = 'FETCH_PROCEDURES_FULFILLED'
export const FETCH_PROCEDURES_REJECTED = 'FETCH_PROCEDURES_REJECTED'

export const FETCH_PROCEDURE_PENDING = 'FETCH_PROCEDURE_PENDING'
export const FETCH_PROCEDURE_FULFILLED = 'FETCH_PROCEDURE_FULFILLED'
export const FETCH_PROCEDURE_REJECTED = 'FETCH_PROCEDURE_REJECTED'

export const CREATE_PROCEDURE_PENDING = 'CREATE_PROCEDURE_PENDING'
export const CREATE_PROCEDURE_FULFILLED = 'CREATE_PROCEDURE_FULFILLED'
export const CREATE_PROCEDURE_REJECTED = 'CREATE_PROCEDURE_REJECTED'

export const UPDATE_PROCEDURE_PENDING = 'UPDATE_PROCEDURE_PENDING'
export const UPDATE_PROCEDURE_FULFILLED = 'UPDATE_PROCEDURE_FULFILLED'
export const UPDATE_PROCEDURE_REJECTED = 'UPDATE_PROCEDURE_REJECTED'

export const DELETE_PROCEDURE_PENDING = 'DELETE_PROCEDURE_PENDING'
export const DELETE_PROCEDURE_FULFILLED = 'DELETE_PROCEDURE_FULFILLED'
export const DELETE_PROCEDURE_REJECTED = 'DELETE_PROCEDURE_REJECTED'

export const CHANGE_PROCEDURE = 'CHANGE_PROCEDURE'

export const ADD_PROCEDURE = 'ADD_PROCEDURE'

export function changeProcedure(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_PROCEDURE, payload: { name, value } })
  }
}
 
export function addProcedure() {
  return dispatch => {
    dispatch({ type: ADD_PROCEDURE });
  };
}

export function findAllProcedures(first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PROCEDURES_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllProcedures {
                  procedimentos (first: ${first} offset: ${offset}) {
                    codigo
                    classificacao
                    status
                    descricao
                    porte
                    custoOperacional
                    numeroAuxiliares
                    porteAnestesico
                    filmes
                    incidencia
                    procedimentoPorte {
                      descricao
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PROCEDURES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { procedimentos } = data
        dispatch({ type: FETCH_PROCEDURES_FULFILLED, payload: procedimentos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PROCEDURES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findProcedure(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PROCEDURE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findProcedure {
                  procedimento (codigo: ${codigo}) {
                    codigo
                    grupo
                    subgrupo
                    classificacao
                    status
                    descricao
                    porte
                    custoOperacional
                    numeroAuxiliares
                    porteAnestesico
                    filmes
                    incidencia
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PROCEDURE_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { procedimento } = data
        dispatch({ type: FETCH_PROCEDURE_FULFILLED, payload: procedimento });
        resolve(procedimento);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PROCEDURE_REJECTED, error });
      resolve(error);
    });

  });

}

export function createProcedure(procedure) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PROCEDURE_PENDING });

    api({
      method: 'post',    
      data: {
        query: `
          mutation createProcedure {
            createProcedimento (input: {
              codigo: ${procedure.codigo}
              grupo: ${procedure.grupo}
              subgrupo: ${procedure.subgrupo}
              classificacao: "${procedure.classificacao}"
              status: ${procedure.status}
              descricao: "${procedure.descricao}"
              porte: ${procedure.porte}
              custoOperacional: ${toFloat(procedure.custoOperacional)}
              numeroAuxiliares: ${procedure.numeroAuxiliares}
              porteAnestesico: ${procedure.porteAnestesico}
              filmes: ${toFloat(procedure.filmes)}
              incidencia: ${procedure.incidencia}
            } ) 
            {
              codigo
              grupo
              subgrupo
              classificacao
              status
              descricao
              porte
              custoOperacional
              numeroAuxiliares
              porteAnestesico
              filmes
              incidencia
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PROCEDURE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createProcedimento } = data
        dispatch({ type: CREATE_PROCEDURE_FULFILLED, payload: createProcedimento });
        
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PROCEDURE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateProcedure(procedure) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PROCEDURE_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation UpdateProcedure {
            updateProcedimento (input: {
              codigo: ${procedure.codigo}
              grupo: ${procedure.grupo}
              subgrupo: ${procedure.subgrupo}
              classificacao: "${procedure.classificacao}"
              status: ${procedure.status}
              descricao: "${procedure.descricao}"
              porte: ${procedure.porte}
              custoOperacional: ${toFloat(procedure.custoOperacional)}
              numeroAuxiliares: ${procedure.numeroAuxiliares}
              porteAnestesico: ${procedure.porteAnestesico}
              filmes: ${toFloat(procedure.filmes)}
              incidencia: ${procedure.incidencia}
            } ) 
            {
              codigo
              grupo
              subgrupo
              classificacao
              status
              descricao
              porte
              custoOperacional
              numeroAuxiliares
              porteAnestesico
              filmes
              incidencia
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PROCEDURE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateProcedimento } = data
        dispatch({ type: CREATE_PROCEDURE_FULFILLED, payload: updateProcedimento });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PROCEDURE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeProcedure(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_PROCEDURE_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation RemoveProcedure {
            deleteProcedimento (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_PROCEDURE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteProcedimento } = data
        dispatch({ type: DELETE_PROCEDURE_FULFILLED, payload: deleteProcedimento });
        dispatch(findAllProcedures());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_PROCEDURE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
