import api from '../../../../api'

export const FETCH_AVAILABLE_SCHEDULES_PENDING = 'FETCH_AVAILABLE_SCHEDULES_PENDING'
export const FETCH_AVAILABLE_SCHEDULES_FULFILLED = 'FETCH_AVAILABLE_SCHEDULES_FULFILLED'
export const FETCH_AVAILABLE_SCHEDULES_REJECTED = 'FETCH_AVAILABLE_SCHEDULES_REJECTED'

export const FETCH_AVAILABLE_SCHEDULE_PENDING = 'FETCH_AVAILABLE_SCHEDULE_PENDING'
export const FETCH_AVAILABLE_SCHEDULE_FULFILLED = 'FETCH_AVAILABLE_SCHEDULE_FULFILLED'
export const FETCH_AVAILABLE_SCHEDULE_REJECTED = 'FETCH_AVAILABLE_SCHEDULE_REJECTED'

export const CREATE_AVAILABLE_SCHEDULE_PENDING = 'CREATE_AVAILABLE_SCHEDULE_PENDING'
export const CREATE_AVAILABLE_SCHEDULE_FULFILLED = 'CREATE_AVAILABLE_SCHEDULE_FULFILLED'
export const CREATE_AVAILABLE_SCHEDULE_REJECTED = 'CREATE_AVAILABLE_SCHEDULE_REJECTED'

export const UPDATE_AVAILABLE_SCHEDULE_PENDING = 'UPDATE_AVAILABLE_SCHEDULE_PENDING'
export const UPDATE_AVAILABLE_SCHEDULE_FULFILLED = 'UPDATE_AVAILABLE_SCHEDULE_FULFILLED'
export const UPDATE_AVAILABLE_SCHEDULE_REJECTED = 'UPDATE_AVAILABLE_SCHEDULE_REJECTED'

export const DELETE_AVAILABLE_SCHEDULE_PENDING = 'DELETE_AVAILABLE_SCHEDULE_PENDING'
export const DELETE_AVAILABLE_SCHEDULE_FULFILLED = 'DELETE_AVAILABLE_SCHEDULE_FULFILLED'
export const DELETE_AVAILABLE_SCHEDULE_REJECTED = 'DELETE_AVAILABLE_SCHEDULE_REJECTED'

export const CHANGE_AVAILABLE_SCHEDULE = 'CHANGE_AVAILABLE_SCHEDULE'

export const ADD_AVAILABLE_SCHEDULE = 'ADD_AVAILABLE_SCHEDULE'

export function changeAvailableSchedule(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_AVAILABLE_SCHEDULE, payload: { name, value } })
  }
}
 
export function addAvailableSchedule() {
  return dispatch => {
    dispatch({ type: ADD_AVAILABLE_SCHEDULE });
  };
}

export function findAllAvailableSchedules(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_AVAILABLE_SCHEDULES_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllAvailableSchedules {
                  agendasProgramacao (first: ${first} offset: ${offset}) {
                    codigo
                    credenciado
                    agendaCredenciado {
                      nomeFantasia
                    }

                    especialidade
                    agendaEspecialidade {
                      nome
                    }
                    prestador
                    agendaPrestador {
                      razaoSocial
                    }
                    horaInicial
                    horaFinal
                    intervalo
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_AVAILABLE_SCHEDULES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { agendasProgramacao } = data
        dispatch({ type: FETCH_AVAILABLE_SCHEDULES_FULFILLED, payload: agendasProgramacao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_AVAILABLE_SCHEDULES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAvailableSchedule(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_AVAILABLE_SCHEDULE_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAvailableSchedule {
                  createAgendaProgramacao (codigo: ${codigo}) {
                    codigo
                    credenciado
                    agendaCredenciado {
                      nomeFantasia
                    }

                    especialidade
                    agendaEspecialidade {
                      nome
                    }
                    prestador
                    agendaPrestador {
                      nome
                    }
                    horaInicial
                    horaFinal
                    intervalo
                    programacao
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_AVAILABLE_SCHEDULE_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { createAgendaProgramacao } = data
        dispatch({ type: FETCH_AVAILABLE_SCHEDULE_FULFILLED, payload: createAgendaProgramacao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_AVAILABLE_SCHEDULE_REJECTED, error });
      resolve(error);
    });

  });

}

export function createAvailableSchedule(availableSchedule) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_AVAILABLE_SCHEDULE_PENDING });
    let mProgramacao = "";
    availableSchedule.programacao.forEach((row) => {
      mProgramacao += `${row.data}|${row.inicioPeriodo}|${row.saidaRefeicao}|${row.retornoRefeicao}|${row.finalPeriodo};`;
    })
    let horaInicial = availableSchedule.horaInicial > 10 ? `${availableSchedule.horaInicial}:00:00` : `0${availableSchedule.horaInicial}:00:00`;
    let horaFinal = availableSchedule.horaFinal > 10 ? `${availableSchedule.horaFinal}:00:00` : `0${availableSchedule.horaFinal}:00:00`;
    api({
      method: 'post',      
      data: {
        query: `
          mutation createAvailableSchedule {
            createAgendaProgramacao (input: {
              codigo: ${availableSchedule.codigo}
              credenciado: ${availableSchedule.credenciado}
              especialidade: ${availableSchedule.especialidade}
              prestador: ${availableSchedule.prestador}
              horaInicial: "${horaInicial}"
              horaFinal: "${horaFinal}"
              intervalo: ${availableSchedule.intervalo}
              programacao: "${mProgramacao}"
            } ) 
            {
              codigo
              credenciado
              agendaCredenciado {
                nomeFantasia
              }

              especialidade
              agendaEspecialidade {
                nome
              }
              prestador
              agendaPrestador {
                razaoSocial
              }
              horaInicial
              horaFinal
              intervalo
              programacao
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_AVAILABLE_SCHEDULE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createAgendaProgramacao } = data
        dispatch({ type: CREATE_AVAILABLE_SCHEDULE_FULFILLED, payload: createAgendaProgramacao });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_AVAILABLE_SCHEDULE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateAvailableSchedule(availableSchedule) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_AVAILABLE_SCHEDULE_PENDING });
    let mProgramacao = "";
    availableSchedule.programacao.forEach((row) => {
      mProgramacao += `${row.data}|${row.inicioPeriodo}|${row.saidaRefeicao}|${row.retornoRefeicao}|${row.finalPeriodo};`;
    })

    let horaInicial = availableSchedule.horaInicial > 10 ? `${availableSchedule.horaInicial}:00:00` : `0${availableSchedule.horaInicial}:00:00`;
    let horaFinal = availableSchedule.horaFinal > 10 ? `${availableSchedule.horaFinal}:00:00` : `0${availableSchedule.horaFinal}:00:00`;
    
    api({
      method: 'post',      
      data: {
        query: `
          mutation UpdateAvailableSchedule {
            updateAgendaProgramacao (input: {
              codigo: ${availableSchedule.codigo}
              credenciado: ${availableSchedule.credenciado}
              especialidade: ${availableSchedule.especialidade}
              prestador: ${availableSchedule.prestador}
              horaInicial: "${horaInicial}"
              horaFinal: "${horaFinal}"
              intervalo: ${availableSchedule.intervalo}
              programacao: "${mProgramacao}"
            } ) 
            {
              codigo
              credenciado
              agendaCredenciado {
                nomeFantasia
              }

              especialidade
              agendaEspecialidade {
                nome
              }
              prestador
              agendaPrestador {
                razaoSocial
              }
              horaInicial
              horaFinal
              intervalo
              programacao
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_AVAILABLE_SCHEDULE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateAgendaProgramacao } = data
        dispatch({ type: CREATE_AVAILABLE_SCHEDULE_FULFILLED, payload: updateAgendaProgramacao });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_AVAILABLE_SCHEDULE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeAvailableSchedule(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_AVAILABLE_SCHEDULE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveAvailableSchedule {
            deleteAgendaProgramacao (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_AVAILABLE_SCHEDULE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteAgendaProgramacao } = data
        dispatch({ type: DELETE_AVAILABLE_SCHEDULE_FULFILLED, payload: deleteAgendaProgramacao });
        dispatch(findAllAvailableSchedules());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_AVAILABLE_SCHEDULE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
