import api from '../../../../api'
import { toFloat } from '../../../../common/utils'

export const FETCH_CASHIER_MOVEMENT_MOVEMENTS_PENDING = 'FETCH_CASHIER_MOVEMENT_MOVEMENTS_PENDING'
export const FETCH_CASHIER_MOVEMENT_MOVEMENTS_FULFILLED = 'FETCH_CASHIER_MOVEMENT_MOVEMENTS_FULFILLED'
export const FETCH_CASHIER_MOVEMENT_MOVEMENTS_REJECTED = 'FETCH_CASHIER_MOVEMENT_MOVEMENTS_REJECTED'

export const FETCH_CASHIER_MOVEMENT_PENDING = 'FETCH_CASHIER_MOVEMENT_PENDING'
export const FETCH_CASHIER_MOVEMENT_FULFILLED = 'FETCH_CASHIER_MOVEMENT_FULFILLED'
export const FETCH_CASHIER_MOVEMENT_REJECTED = 'FETCH_CASHIER_MOVEMENT_REJECTED'

export const CREATE_CASHIER_MOVEMENT_PENDING = 'CREATE_CASHIER_MOVEMENT_PENDING'
export const CREATE_CASHIER_MOVEMENT_FULFILLED = 'CREATE_CASHIER_MOVEMENT_FULFILLED'
export const CREATE_CASHIER_MOVEMENT_REJECTED = 'CREATE_CASHIER_MOVEMENT_REJECTED'

export const UPDATE_CASHIER_MOVEMENT_PENDING = 'UPDATE_CASHIER_MOVEMENT_PENDING'
export const UPDATE_CASHIER_MOVEMENT_FULFILLED = 'UPDATE_CASHIER_MOVEMENT_FULFILLED'
export const UPDATE_CASHIER_MOVEMENT_REJECTED = 'UPDATE_CASHIER_MOVEMENT_REJECTED'

export const DELETE_CASHIER_MOVEMENT_PENDING = 'DELETE_CASHIER_MOVEMENT_PENDING'
export const DELETE_CASHIER_MOVEMENT_FULFILLED = 'DELETE_CASHIER_MOVEMENT_FULFILLED'
export const DELETE_CASHIER_MOVEMENT_REJECTED = 'DELETE_CASHIER_MOVEMENT_REJECTED'

export const CHANGE_CASHIER_MOVEMENT = 'CHANGE_CASHIER_MOVEMENT'

export const ADD_CASHIER_MOVEMENT = 'ADD_CASHIER_MOVEMENT'

export function changeCashierMovement(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_CASHIER_MOVEMENT, payload: { name, value } })
  }
}
 
export function addCashierMovement() {
  return dispatch => {
    dispatch({ type: ADD_CASHIER_MOVEMENT });
  };
}

export function findAllCashierMovements(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CASHIER_MOVEMENT_MOVEMENTS_PENDING });

    api({
      method: 'post',    
      data: {
        query: `
                query findAllCashierMovements {
                  caixaLancamentos (first: ${first} offset: ${offset}) {
                    codigo
                    caixa
                    usuario
                    dataLancamento
                    tipoDocumento
                    numeroDocumento
                    tipoLancamento
                    tipoPortador
                    portador
                    contaPortador {
                      razaoSocial
                    }
                    historico
                    formaPagamento
                    valor
                    centroCusto
                    centroDeCusto {
                      descricao
                    }
                    contaGerencial
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CASHIER_MOVEMENT_MOVEMENTS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { caixaLancamentos } = data
        dispatch({ type: FETCH_CASHIER_MOVEMENT_MOVEMENTS_FULFILLED, payload: caixaLancamentos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CASHIER_MOVEMENT_MOVEMENTS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findCashierMovement(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CASHIER_MOVEMENT_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
                query findCashierMovement {
                  caixa (codigo: ${codigo}) {
                    codigo
                    dataLancamento
                    historico
                    tipoDocumento
                    numeroDocumento
                    tipoLancamento
                    valor  
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CASHIER_MOVEMENT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { caixa } = data
        dispatch({ type: FETCH_CASHIER_MOVEMENT_FULFILLED, payload: caixa });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CASHIER_MOVEMENT_REJECTED, error });
      resolve(error);
    });

  });

}

export function createCashierMovement(cashier) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CASHIER_MOVEMENT_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
          mutation createCashierMovement {
            createCaixaLancamento( input: {
              caixa: ${cashier.caixa}
              usuario: ${cashier.usuario}
              dataLancamento: "${cashier.dataLancamento}"
              tipoDocumento: ${cashier.tipoDocumento}
              numeroDocumento: "${cashier.numeroDocumento}"
              tipoLancamento: ${cashier.tipoLancamento}
              tipoPortador: ${cashier.tipoPortador}
              portador: ${cashier.portador}
              historico: "${cashier.historico}"
              formaPagamento: ${cashier.formaPagamento}
              valor: ${toFloat(cashier.valor)}
              centroCusto: ${cashier.centroCusto}
              contaGerencial: ${cashier.contaGerencial}
            }) {
              codigo
              caixa
              usuario
              dataLancamento
              tipoDocumento
              numeroDocumento
              tipoLancamento
              tipoPortador
              portador
              contaPortador {
                razaoSocial
              }
              historico
              formaPagamento
              valor
              centroCusto
              centroDeCusto {
                descricao
              }
              contaGerencial
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CASHIER_MOVEMENT_REJECTED, payload: errors });
        reject({ status: false, error: errors });
      } else {
        const { createCaixaLancamento } = data
        dispatch({ type: CREATE_CASHIER_MOVEMENT_FULFILLED, payload: createCaixaLancamento });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CASHIER_MOVEMENT_REJECTED, errors });
      reject({ status: false, error: errors });
    });
  });
}

export function updateCashierMovement(cashier) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CASHIER_MOVEMENT_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation UpdateCashierMovement {
            updateCaixa (input: {
              codigo: ${cashier.codigo}
              dataLancamento: ${cashier.dataLancamento}
              historico: ${cashier.historico}
              tipoDocumento: ${cashier.tipoDocumento}
              numeroDocumento: ${cashier.numeroDocumento}
              tipoLancamento: ${cashier.tipoLancamento}
              valor: ${cashier.valor}  
            } ) 
            {
              codigo
              dataLancamento
              historico
              tipoDocumento
              numeroDocumento
              tipoLancamento
              valor  
      }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CASHIER_MOVEMENT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateCaixa } = data
        dispatch({ type: CREATE_CASHIER_MOVEMENT_FULFILLED, payload: updateCaixa });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CASHIER_MOVEMENT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeCashierMovement(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_CASHIER_MOVEMENT_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation RemoveCashierMovement {
            deleteCaixaLancamento (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_CASHIER_MOVEMENT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteCaixaLancamento } = data
        dispatch({ type: DELETE_CASHIER_MOVEMENT_FULFILLED, payload: deleteCaixaLancamento });
        dispatch(findAllCashierMovements());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_CASHIER_MOVEMENT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
