import {

    FETCH_SPECIALTIES_PENDING,
    FETCH_SPECIALTIES_FULFILLED,
    FETCH_SPECIALTIES_REJECTED,

    FETCH_SPECIALTY_PENDING,
    FETCH_SPECIALTY_FULFILLED,
    FETCH_SPECIALTY_REJECTED,

    CREATE_SPECIALTY_PENDING,
    CREATE_SPECIALTY_FULFILLED,
    CREATE_SPECIALTY_REJECTED,

    UPDATE_SPECIALTY_PENDING,
    UPDATE_SPECIALTY_FULFILLED,
    UPDATE_SPECIALTY_REJECTED,

    DELETE_SPECIALTY_PENDING,
    DELETE_SPECIALTY_FULFILLED,
    DELETE_SPECIALTY_REJECTED,
    
    CHANGE_SPECIALTY,
    
    ADD_SPECIALTY

} from './specialtyActions'

import { titleCase } from '../../../../common/utils'

const initialState = {
    loading: false,
    listOfSpecialties: [],
    specialty: {
        codigo: 0,
        nome: '',
        status: 0,
    },
    error: null,
}

export function specialtyReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SPECIALTY:
            return Object.assign({}, state, {
                specialty: { ...state.specialty, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_SPECIALTY: 
            return Object.assign({}, state, {
                specialty: { ...initialState.specialty }
            })
        case FETCH_SPECIALTIES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfSpecialties: [],
            })
        case FETCH_SPECIALTIES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfSpecialties: action.payload
            })
        case FETCH_SPECIALTIES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_SPECIALTY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //specialty: { ...initialState.specialty },
            })
        case CREATE_SPECIALTY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                specialty: action.payload
            })
        case CREATE_SPECIALTY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_SPECIALTY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_SPECIALTY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                specialty: action.payload
            })
        case UPDATE_SPECIALTY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_SPECIALTY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_SPECIALTY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                specialty: action.payload
            })
        case FETCH_SPECIALTY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_SPECIALTY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_SPECIALTY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_SPECIALTY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}