import api from '../../../../api'
import { toFloat } from '../../../../common/utils'

export const FETCH_BANKS_ACCOUNTS_PENDING = 'FETCH_BANKS_ACCOUNTS_PENDING'
export const FETCH_BANKS_ACCOUNTS_FULFILLED = 'FETCH_BANKS_ACCOUNTS_FULFILLED'
export const FETCH_BANKS_ACCOUNTS_REJECTED = 'FETCH_BANKS_ACCOUNTS_REJECTED'

export const FETCH_BANK_ACCOUNT_PENDING = 'FETCH_BANK_ACCOUNT_PENDING'
export const FETCH_BANK_ACCOUNT_FULFILLED = 'FETCH_BANK_ACCOUNT_FULFILLED'
export const FETCH_BANK_ACCOUNT_REJECTED = 'FETCH_BANK_ACCOUNT_REJECTED'

export const CREATE_BANK_ACCOUNT_PENDING = 'CREATE_BANK_ACCOUNT_PENDING'
export const CREATE_BANK_ACCOUNT_FULFILLED = 'CREATE_BANK_ACCOUNT_FULFILLED'
export const CREATE_BANK_ACCOUNT_REJECTED = 'CREATE_BANK_ACCOUNT_REJECTED'

export const UPDATE_BANK_ACCOUNT_PENDING = 'UPDATE_BANK_ACCOUNT_PENDING'
export const UPDATE_BANK_ACCOUNT_FULFILLED = 'UPDATE_BANK_ACCOUNT_FULFILLED'
export const UPDATE_BANK_ACCOUNT_REJECTED = 'UPDATE_BANK_ACCOUNT_REJECTED'

export const DELETE_BANK_ACCOUNT_PENDING = 'DELETE_BANK_ACCOUNTPENDING'
export const DELETE_BANK_ACCOUNT_FULFILLED = 'DELETE_BANK_ACCOUNTFULFILLED'
export const DELETE_BANK_ACCOUNT_REJECTED = 'DELETE_BANK_ACCOUNTREJECTED'

export const CHANGE_BANK_ACCOUNT = 'CHANGE_BANK_ACCOUNT'

export const ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT'

export function changeBankAccount(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_BANK_ACCOUNT, payload: { name, value } })
  }
}
 
export function addBankAccount() {
  return dispatch => {
    dispatch({ type: ADD_BANK_ACCOUNT });
  };
}

export function findAllBanksAccounts(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_BANKS_ACCOUNTS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllBanksAccounts {
                  contasBancaria (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    abertura
                    tipo
                    especie
                    descricao
                    contaBanco {
                      numero
                      nome
                    }
                    agencia
                    conta
                    saldoInicial
                    saldoAtual
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_BANKS_ACCOUNTS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { contasBancaria } = data
        dispatch({ type: FETCH_BANKS_ACCOUNTS_FULFILLED, payload: contasBancaria });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_BANKS_ACCOUNTS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findBankAccount(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_BANK_ACCOUNT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findBankAccount {
                  contaBancaria (codigo: ${codigo}) {
                    codigo
                    status
                    abertura
                    tipo
                    especie
                    descricao
                    banco
                    agencia
                    conta
                    saldoInicial
                    saldoAtual
                    limiteCredito
                    gerente
                    telefone
                    fechamento  
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_BANK_ACCOUNT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { contaBancaria } = data
        dispatch({ type: FETCH_BANK_ACCOUNT_FULFILLED, payload: contaBancaria });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_BANK_ACCOUNT_REJECTED, error });
      resolve(error);
    });

  });

}

export function createBankAccount(account) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_BANK_ACCOUNT_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createBankAccount {
            createContaBancaria (input: {
              codigo: ${account.codigo}
              status: ${account.status}
              abertura: "${account.abertura}" 
              tipo: ${account.tipo}
              especie: ${account.especie}
              descricao: "${account.descricao}"
              banco: ${account.banco}
              agencia: "${account.agencia}"
              conta: "${account.conta}"
              saldoInicial: ${toFloat(account.saldoInicial)}
              saldoAtual: ${toFloat(account.saldoAtual)}
              limiteCredito: ${toFloat(account.limiteCredito)}
              gerente: "${account.gerente}"
            } ) 
            {
              codigo
              status
              abertura
              tipo
              especie
              descricao
              banco
              agencia
              conta
              saldoInicial
              saldoAtual
              limiteCredito
              gerente
              telefone
              fechamento  
      }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_BANK_ACCOUNT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createContaBancaria } = data
        dispatch({ type: CREATE_BANK_ACCOUNT_FULFILLED, payload: createContaBancaria });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_BANK_ACCOUNT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateBankAccount(account) {
  console.log(account)
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_BANK_ACCOUNT_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
          mutation UpdateBankAccount {
            updateContaBancaria (input: {
              codigo: ${account.codigo}
              status: ${account.status}
              abertura: "${account.abertura}"
              tipo: ${account.tipo}
              especie: ${account.especie}
              descricao: "${account.descricao}"
              banco: ${account.banco}
              agencia: "${account.agencia}"
              conta: "${account.conta}"
              saldoInicial: ${toFloat(account.saldoInicial)}
              saldoAtual: ${toFloat(account.saldoAtual)}
              limiteCredito: ${toFloat(account.limiteCredito)}
              gerente: "${account.gerente}"
              telefone: "${account.telefone}"
            } ) 
            {
              codigo
              status
              abertura
              tipo
              especie
              descricao
              banco
              agencia
              conta
              saldoInicial
              saldoAtual
              limiteCredito
              gerente
              telefone
              fechamento  
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_BANK_ACCOUNT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateContaBancaria } = data
        dispatch({ type: CREATE_BANK_ACCOUNT_FULFILLED, payload: updateContaBancaria });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_BANK_ACCOUNT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeBankAccount(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_BANK_ACCOUNT_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation RemoveBankAccount {
            deleteContaBancaria (codigo: ${codigo}) 
          } 
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_BANK_ACCOUNT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteContaBancaria } = data
        dispatch({ type: DELETE_BANK_ACCOUNT_FULFILLED, payload: deleteContaBancaria });
        dispatch(findAllBanksAccounts());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_BANK_ACCOUNT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
