import {

    FETCH_HEALTH_PLANS_PENDING,
    FETCH_HEALTH_PLANS_FULFILLED,
    FETCH_HEALTH_PLANS_REJECTED,

    FETCH_HEALTH_PLAN_PENDING,
    FETCH_HEALTH_PLAN_FULFILLED,
    FETCH_HEALTH_PLAN_REJECTED,

    CREATE_HEALTH_PLAN_PENDING,
    CREATE_HEALTH_PLAN_FULFILLED,
    CREATE_HEALTH_PLAN_REJECTED,

    UPDATE_HEALTH_PLAN_PENDING,
    UPDATE_HEALTH_PLAN_FULFILLED,
    UPDATE_HEALTH_PLAN_REJECTED,

    DELETE_HEALTH_PLAN_PENDING,
    DELETE_HEALTH_PLAN_FULFILLED,
    DELETE_HEALTH_PLAN_REJECTED,
    
    CHANGE_HEALTH_PLAN,
    
    ADD_HEALTH_PLAN

} from './healthPlanActions'

import { titleCase } from '../../../../common/utils'

const initialState = {
    loading: false,
    listOfHealthPlans: [],
    healthPlan: {
        codigo: 0,
        cnpj: '',
        status: 0,
        descricao: '',
        tabela: 0,
        registroAns: '',
    },
    error: null,
}

export function healthPlanReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_HEALTH_PLAN:
            return Object.assign({}, state, {
                healthPlan: { ...state.healthPlan, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_HEALTH_PLAN: 
            return Object.assign({}, state, {
                healthPlan: { ...initialState.healthPlan }
            })
        case FETCH_HEALTH_PLANS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfHealthPlans: [],
            })
        case FETCH_HEALTH_PLANS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfHealthPlans: action.payload
            })
        case FETCH_HEALTH_PLANS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_HEALTH_PLAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //healthPlan: { ...initialState.healthPlan },
            })
        case CREATE_HEALTH_PLAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                healthPlan: action.payload
            })
        case CREATE_HEALTH_PLAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_HEALTH_PLAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_HEALTH_PLAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                healthPlan: action.payload
            })
        case UPDATE_HEALTH_PLAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_HEALTH_PLAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_HEALTH_PLAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                healthPlan: action.payload
            })
        case FETCH_HEALTH_PLAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_HEALTH_PLAN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_HEALTH_PLAN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_HEALTH_PLAN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}