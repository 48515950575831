import {

    FETCH_BANKS_PENDING,
    FETCH_BANKS_FULFILLED,
    FETCH_BANKS_REJECTED,

    FETCH_BANK_PENDING,
    FETCH_BANK_FULFILLED,
    FETCH_BANK_REJECTED,

    CREATE_BANK_PENDING,
    CREATE_BANK_FULFILLED,
    CREATE_BANK_REJECTED,

    UPDATE_BANK_PENDING,
    UPDATE_BANK_FULFILLED,
    UPDATE_BANK_REJECTED,

    DELETE_BANK_PENDING,
    DELETE_BANK_FULFILLED,
    DELETE_BANK_REJECTED,
    
    CHANGE_BANK,
    
    ADD_BANK

} from './bankActions'

import { titleCase } from '../../../../common/utils'
const initialState = {
    loading: false,
    listOfBanks: [],
    bank: {
        codigo: 0,
        numero: 0,
        cnpj: '',
        nome: '',
        abreviatura: '',   
        telefone: '',
        email: '',
    },
    error: null,
}

export function bankReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_BANK:
            let ignoreTile = action.payload.name === 'abreviatura' || action.payload.name === 'email';
            return Object.assign({}, state, {
                bank: { ...state.bank, 
                    [action.payload.name]: ignoreTile ? action.payload.value : titleCase(action.payload.value)
                }
            })
        case ADD_BANK: 
            return Object.assign({}, state, {
                bank: { ...initialState.bank }
            })
        case FETCH_BANKS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfBanks: [],
            })
        case FETCH_BANKS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfBanks: action.payload
            })
        case FETCH_BANKS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_BANK_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_BANK_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                bank: action.payload
            })
        case CREATE_BANK_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_BANK_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_BANK_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                bank: action.payload
            })
        case UPDATE_BANK_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_BANK_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_BANK_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                bank: action.payload
            })
        case FETCH_BANK_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_BANK_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_BANK_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_BANK_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}