import {

    FETCH_CASHIERS_PENDING,
    FETCH_CASHIERS_FULFILLED,
    FETCH_CASHIERS_REJECTED,

    FETCH_CASHIER_PENDING,
    FETCH_CASHIER_FULFILLED,
    FETCH_CASHIER_REJECTED,

    CREATE_CASHIER_PENDING,
    CREATE_CASHIER_FULFILLED,
    CREATE_CASHIER_REJECTED,

    UPDATE_CASHIER_PENDING,
    UPDATE_CASHIER_FULFILLED,
    UPDATE_CASHIER_REJECTED,

    DELETE_CASHIER_PENDING,
    DELETE_CASHIER_FULFILLED,
    DELETE_CASHIER_REJECTED,
    
    CHANGE_CASHIER,
    
    ADD_CASHIER

} from './cashierActions'

import { titleCase } from '../../../../common/utils'
const initialState = {
    loading: false,
    listOfCashiers: [],
    cashier: {
        codigo: 0,
        status: 0,
        descricao: '',
    },
    error: null,
}

export function cashierReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_CASHIER:
            return Object.assign({}, state, {
                cashier: { ...state.cashier, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_CASHIER: 
            return Object.assign({}, state, {
                cashier: { ...initialState.cashier }
            })
        case FETCH_CASHIERS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfCashiers: [],
            })
        case FETCH_CASHIERS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfCashiers: action.payload
            })
        case FETCH_CASHIERS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_CASHIER_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //center: { ...initialState.center },
            })
        case CREATE_CASHIER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cashier: action.payload
            })
        case CREATE_CASHIER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_CASHIER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_CASHIER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cashier: action.payload
            })
        case UPDATE_CASHIER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_CASHIER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_CASHIER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                cashier: action.payload
            })
        case FETCH_CASHIER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_CASHIER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_CASHIER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_CASHIER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}