import {

    FETCH_PROFESSIONS_PENDING,
    FETCH_PROFESSIONS_FULFILLED,
    FETCH_PROFESSIONS_REJECTED,

    FETCH_PROFESSION_PENDING,
    FETCH_PROFESSION_FULFILLED,
    FETCH_PROFESSION_REJECTED,

    CREATE_PROFESSION_PENDING,
    CREATE_PROFESSION_FULFILLED,
    CREATE_PROFESSION_REJECTED,

    UPDATE_PROFESSION_PENDING,
    UPDATE_PROFESSION_FULFILLED,
    UPDATE_PROFESSION_REJECTED,

    DELETE_PROFESSION_PENDING,
    DELETE_PROFESSION_FULFILLED,
    DELETE_PROFESSION_REJECTED,
    
    CHANGE_PROFESSION,
    
    ADD_PROFESSION

} from './professionActions'

import { titleCase } from '../../../../common/utils'

const initialState = {
    loading: false,
    listOfProfessions: [],
    profession: {
        codigo: 0,
        cbo: 0,
        nome: ''
    },
    error: null,
}

export function professionReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PROFESSION:
            return Object.assign({}, state, {
                profession: { ...state.profession, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_PROFESSION: 
            return Object.assign({}, state, {
                profession: { ...initialState.profession }
            })
        case FETCH_PROFESSIONS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfProfessions: [],
            })
        case FETCH_PROFESSIONS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfProfessions: action.payload
            })
        case FETCH_PROFESSIONS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_PROFESSION_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //profession: { ...initialState.profession },
            })
        case CREATE_PROFESSION_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                profession: action.payload
            })
        case CREATE_PROFESSION_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_PROFESSION_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_PROFESSION_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                profession: action.payload
            })
        case UPDATE_PROFESSION_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_PROFESSION_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_PROFESSION_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                profession: action.payload
            })
        case FETCH_PROFESSION_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_PROFESSION_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_PROFESSION_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_PROFESSION_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}