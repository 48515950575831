import {

    FETCH_SUBGROUPS_PENDING,
    FETCH_SUBGROUPS_FULFILLED,
    FETCH_SUBGROUPS_REJECTED,

    FETCH_SUBGROUP_PENDING,
    FETCH_SUBGROUP_FULFILLED,
    FETCH_SUBGROUP_REJECTED,

    CREATE_SUBGROUP_PENDING,
    CREATE_SUBGROUP_FULFILLED,
    CREATE_SUBGROUP_REJECTED,

    UPDATE_SUBGROUP_PENDING,
    UPDATE_SUBGROUP_FULFILLED,
    UPDATE_SUBGROUP_REJECTED,

    DELETE_SUBGROUP_PENDING,
    DELETE_SUBGROUP_FULFILLED,
    DELETE_SUBGROUP_REJECTED,
    
    CHANGE_SUBGROUP,
    
    ADD_SUBGROUP

} from './subGroupActions'

import { titleCase } from '../../../../../common/utils'

const moment = require('moment')

const initialState = {
    loading: false,
    listOfSubGroups: [],
    subGroup: {
        codigo: 0,
        grupo: 0,
        descricao: '',
    },
    error: null,
}

export function subGroupReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SUBGROUP:
            return Object.assign({}, state, {
                subGroup: { ...state.subGroup, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_SUBGROUP: 
            return Object.assign({}, state, {
                subGroup: { ...initialState.subGroup }
            })
        case FETCH_SUBGROUPS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfSubGroups: [],
            })
        case FETCH_SUBGROUPS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfSubGroups: action.payload
            })
        case FETCH_SUBGROUPS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_SUBGROUP_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //subGroup: { ...initialState.subGroup },
            })
        case CREATE_SUBGROUP_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                subGroup: action.payload
            })
        case CREATE_SUBGROUP_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_SUBGROUP_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_SUBGROUP_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                subGroup: action.payload
            })
        case UPDATE_SUBGROUP_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_SUBGROUP_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_SUBGROUP_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                subGroup: action.payload
            })
        case FETCH_SUBGROUP_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_SUBGROUP_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_SUBGROUP_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_SUBGROUP_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}