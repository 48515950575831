import {

    FETCH_BANKS_ACCOUNTS_PENDING,
    FETCH_BANKS_ACCOUNTS_FULFILLED,
    FETCH_BANKS_ACCOUNTS_REJECTED,

    FETCH_BANK_ACCOUNT_PENDING,
    FETCH_BANK_ACCOUNT_FULFILLED,
    FETCH_BANK_ACCOUNT_REJECTED,

    CREATE_BANK_ACCOUNT_PENDING,
    CREATE_BANK_ACCOUNT_FULFILLED,
    CREATE_BANK_ACCOUNT_REJECTED,

    UPDATE_BANK_ACCOUNT_PENDING,
    UPDATE_BANK_ACCOUNT_FULFILLED,
    UPDATE_BANK_ACCOUNT_REJECTED,

    DELETE_BANK_ACCOUNT_PENDING,
    DELETE_BANK_ACCOUNT_FULFILLED,
    DELETE_BANK_ACCOUNT_REJECTED,
    
    CHANGE_BANK_ACCOUNT,
    
    ADD_BANK_ACCOUNT

} from './bankAccountActions'

import { titleCase } from '../../../../common/utils'

const moment = require('moment')
const initialState = {
    loading: false,
    listOfBanksAccounts: [],
    bankAccount: {
        codigo: 0,
        status: 0,
        abertura: moment().format("DD/MM/YYYY"),
        tipo: 0,
        especie: 0,
        descricao: '',
        banco: '',
        agencia: '',
        conta: '',
        saldoInicial: '0,00',
        saldoAtual: '0,00',
        limiteCredito: '0,00',
        gerente: '',
        telefone: '',
        fechamento: '' 
    },
    error: null,
}

export function bankAccountReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_BANK_ACCOUNT:
            return Object.assign({}, state, {
                bankAccount: { ...state.bankAccount, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_BANK_ACCOUNT: 
            return Object.assign({}, state, {
                bankAccount: { ...initialState.bankAccount }
            })
        case FETCH_BANKS_ACCOUNTS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfBanksAccounts: [],
            })
        case FETCH_BANKS_ACCOUNTS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfBanksAccounts: action.payload
            })
        case FETCH_BANKS_ACCOUNTS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_BANK_ACCOUNT_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //bankAccount: { ...initialState.bankAccount },
            })
        case CREATE_BANK_ACCOUNT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                bankAccount: action.payload
            })
        case CREATE_BANK_ACCOUNT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_BANK_ACCOUNT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_BANK_ACCOUNT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                bankAccount: action.payload
            })
        case UPDATE_BANK_ACCOUNT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_BANK_ACCOUNT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_BANK_ACCOUNT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                bankAccount: action.payload
            })
        case FETCH_BANK_ACCOUNT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_BANK_ACCOUNT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_BANK_ACCOUNT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_BANK_ACCOUNT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}