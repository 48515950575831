import {

    FETCH_NCMS_PRODUCT_PENDING,
    FETCH_NCMS_PRODUCT_FULFILLED,
    FETCH_NCMS_PRODUCT_REJECTED,

    FETCH_NCM_PRODUCT_PENDING,
    FETCH_NCM_PRODUCT_FULFILLED,
    FETCH_NCM_PRODUCT_REJECTED,

    CREATE_NCM_PRODUCT_PENDING,
    CREATE_NCM_PRODUCT_FULFILLED,
    CREATE_NCM_PRODUCT_REJECTED,

    UPDATE_NCM_PRODUCT_PENDING,
    UPDATE_NCM_PRODUCT_FULFILLED,
    UPDATE_NCM_PRODUCT_REJECTED,

    DELETE_NCM_PRODUCT_PENDING,
    DELETE_NCM_PRODUCT_FULFILLED,
    DELETE_NCM_PRODUCT_REJECTED,
    
    CHANGE_NCM_PRODUCT,
    
    ADD_NCM_PRODUCT

} from './ncmProductActions'

import { titleCase } from '../../../../../common/utils'
const initialState = {
    loading: false,
    listOfNcm: [],
    ncm: {
        codigo: 0,
        status: '',
        numero: 0,
        descricao: '',
        vinculado: 0,
    },
    error: null,
}

export function ncmProductReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_NCM_PRODUCT:
            return Object.assign({}, state, {
                ncm: { ...state.ncm, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_NCM_PRODUCT: 
            return Object.assign({}, state, {
                ncm: { ...initialState.ncm }
            })
        case FETCH_NCMS_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfNcm: [],
            })
        case FETCH_NCMS_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfNcm: action.payload
            })
        case FETCH_NCMS_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_NCM_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //ncm: { ...initialState.ncm },
            })
        case CREATE_NCM_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                ncm: action.payload
            })
        case CREATE_NCM_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_NCM_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_NCM_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                ncm: action.payload
            })
        case UPDATE_NCM_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_NCM_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_NCM_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                ncm: action.payload
            })
        case FETCH_NCM_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_NCM_PRODUCT_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_NCM_PRODUCT_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_NCM_PRODUCT_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}