import api from '../../../../api'

export const FETCH_SUPPLIERS_PENDING = 'FETCH_SUPPLIERS_PENDING'
export const FETCH_SUPPLIERS_FULFILLED = 'FETCH_SUPPLIERS_FULFILLED'
export const FETCH_SUPPLIERS_REJECTED = 'FETCH_SUPPLIERS_REJECTED'

export const FETCH_SUPPLIER_PENDING = 'FETCH_SUPPLIER_PENDING'
export const FETCH_SUPPLIER_FULFILLED = 'FETCH_SUPPLIER_FULFILLED'
export const FETCH_SUPPLIER_REJECTED = 'FETCH_SUPPLIER_REJECTED'

export const CREATE_SUPPLIER_PENDING = 'CREATE_SUPPLIER_PENDING'
export const CREATE_SUPPLIER_FULFILLED = 'CREATE_SUPPLIER_FULFILLED'
export const CREATE_SUPPLIER_REJECTED = 'CREATE_SUPPLIER_REJECTED'

export const UPDATE_SUPPLIER_PENDING = 'UPDATE_SUPPLIER_PENDING'
export const UPDATE_SUPPLIER_FULFILLED = 'UPDATE_SUPPLIER_FULFILLED'
export const UPDATE_SUPPLIER_REJECTED = 'UPDATE_SUPPLIER_REJECTED'

export const DELETE_SUPPLIER_PENDING = 'DELETE_SUPPLIER_PENDING'
export const DELETE_SUPPLIER_FULFILLED = 'DELETE_SUPPLIER_FULFILLED'
export const DELETE_SUPPLIER_REJECTED = 'DELETE_SUPPLIER_REJECTED'

export const CHANGE_SUPPLIER = 'CHANGE_SUPPLIER'

export const ADD_SUPPLIER = 'ADD_SUPPLIER'

export function changeSupplier(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SUPPLIER, payload: { name, value } })
  }
}

export function addSupplier() {
  return dispatch => {
    dispatch({ type: ADD_SUPPLIER });
  };
}

export function findAllSuppliers(first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SUPPLIERS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllSuppliers {
                  fornecedores (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    cpfCnpj
                    razaoSocial
                    nomeFantasia
                    celular
                    fixo
                    ramal
                    email
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SUPPLIERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { fornecedores } = data
        dispatch({ type: FETCH_SUPPLIERS_FULFILLED, payload: fornecedores });
        resolve(fornecedores);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SUPPLIERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findSupplier(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SUPPLIER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findSupplier {
                  fornecedor (codigo: ${codigo}) {
                    codigo
                    status
                    tipoFornecedor
                    cpfCnpj
                    razaoSocial
                    nomeFantasia
                    numeroCNAE
                    numeroIE
                    numeroIM
                    tipoRegime
                    tipoEndereco
                    endereco
                    numero
                    complemento
                    cep
                    bairro
                    estado
                    municipio
                    celular
                    fixo
                    ramal
                    responsavel
                    email
                    homePage
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SUPPLIER_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { fornecedor } = data
        dispatch({ type: FETCH_SUPPLIER_FULFILLED, payload: fornecedor });
        resolve(fornecedor);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SUPPLIER_REJECTED, error });
      resolve(error);
    });

  });

}

export function createSupplier(supplier) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SUPPLIER_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation createSupplier {
            createFornecedor (input: {
              codigo: ${supplier.codigo}
              status: ${supplier.status}
              tipoFornecedor: "${supplier.tipoFornecedor}"
              cpfCnpj: "${supplier.cpfCnpj}"
              razaoSocial: "${supplier.razaoSocial}"
              nomeFantasia: "${supplier.nomeFantasia}"
              numeroCNAE: "${supplier.numeroCNAE}"
              numeroIE: "${supplier.numeroIE}"
              numeroIM: "${supplier.numeroIM}"
              tipoRegime: ${supplier.tipoRegime}
              tipoEndereco: "${supplier.tipoEndereco}"
              endereco: "${supplier.endereco}"
              numero: "${supplier.numero}"
              complemento: "${supplier.complemento}"
              cep: "${supplier.cep}"
              bairro: "${supplier.bairro}"
              estado: ${supplier.estado}
              municipio: ${supplier.municipio}
              celular: "${supplier.celular}"
              fixo: "${supplier.fixo}"
              ramal: "${supplier.ramal}"
              responsavel: "${supplier.responsavel}"
              email: "${supplier.email}"
              homePage: "${supplier.homePage}"
            } ) {
              codigo
              status
              tipoFornecedor
              cpfCnpj
              razaoSocial
              nomeFantasia
              numeroCNAE
              numeroIE
              numeroIM
              tipoRegime
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              fixo
              ramal
              responsavel
              email
              homePage
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SUPPLIER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createFornecedor } = data
        dispatch({ type: CREATE_SUPPLIER_FULFILLED, payload: createFornecedor });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SUPPLIER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateSupplier(supplier) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_SUPPLIER_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation UpdateSupplier {
            updateFornecedor (input: {
              codigo: ${supplier.codigo}
              status: ${supplier.status}
              tipoFornecedor: "${supplier.tipoFornecedor}"
              cpfCnpj: "${supplier.cpfCnpj}"
              razaoSocial: "${supplier.razaoSocial}"
              nomeFantasia: "${supplier.nomeFantasia}"
              numeroCNAE: "${supplier.numeroCNAE}"
              numeroIE: "${supplier.numeroIE}"
              numeroIM: "${supplier.numeroIM}"
              tipoRegime: ${supplier.tipoRegime}
              tipoEndereco: "${supplier.tipoEndereco}"
              endereco: "${supplier.endereco}"
              numero: "${supplier.numero}"
              complemento: "${supplier.complemento}"
              cep: "${supplier.cep}"
              bairro: "${supplier.bairro}"
              estado: ${supplier.estado}
              municipio: ${supplier.municipio}
              celular: "${supplier.celular}"
              fixo: "${supplier.fixo}"
              ramal: "${supplier.ramal}"
              responsavel: "${supplier.responsavel}"
              email: "${supplier.email}"
              homePage: "${supplier.homePage}"
            } ) {
              codigo
              status
              tipoFornecedor
              cpfCnpj
              razaoSocial
              nomeFantasia
              numeroCNAE
              numeroIE
              numeroIM
              tipoRegime
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              fixo
              ramal
              responsavel
              email
              homePage
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_SUPPLIER_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateFornecedor } = data
        dispatch({ type: UPDATE_SUPPLIER_FULFILLED, payload: updateFornecedor });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_SUPPLIER_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeSupplier(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_SUPPLIER_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation RemoveSupplier {
            deleteFornecedor (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_SUPPLIER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteFornecedor } = data
        dispatch({ type: DELETE_SUPPLIER_FULFILLED, payload: deleteFornecedor });
        dispatch(findAllSuppliers());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_SUPPLIER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
