import {

    FETCH_PROVIDERS_PENDING,
    FETCH_PROVIDERS_FULFILLED,
    FETCH_PROVIDERS_REJECTED,

    FETCH_PROVIDER_PENDING,
    FETCH_PROVIDER_FULFILLED,
    FETCH_PROVIDER_REJECTED,

    CREATE_PROVIDER_PENDING,
    CREATE_PROVIDER_FULFILLED,
    CREATE_PROVIDER_REJECTED,

    UPDATE_PROVIDER_PENDING,
    UPDATE_PROVIDER_FULFILLED,
    UPDATE_PROVIDER_REJECTED,

    DELETE_PROVIDER_PENDING,
    DELETE_PROVIDER_FULFILLED,
    DELETE_PROVIDER_REJECTED,
    
    CHANGE_PROVIDER,
    
    ADD_PROVIDER

} from './providerActions'

import { titleCase } from "../../../../common/utils";

const initialState = {
    loading: false,
    listOfProviders: [],
    provider: {
        codigo: 0,
        status: 0,
        razaoSocial: '',
        sexo: 'F',
        nascimento: '',
        fatorSangue: 'O+',
        estadoCivil: 0,
        necessidades: 0,   
        nacionalidade: 0,    
        naturalEstado: '',
        naturalMunicipio: '',
        profissao: '', 
        especialidade: '',
        especialidades: [],
        orgaoClasse: '',
        numeroConselho: '',
        estadoConselho: '', 
        cpf: '',
        rg: '',
        expedidorRg: '',
        ufRg: '', 
        nomePai: '',
        nomeMae: '',
        tipoEndereco: '5',
        endereco: '',
        numero: '',
        complemento: '',
        cep: '',
        bairro: '',
        estado: '',
        municipio: '',
        celular: '',
        telefone: '',
        foneResidencial: '',
        foneComercial: '',
        email: '',
        instagram: '',
        facebook: '',
        banco: '',
        numeroAgencia: '',
        tipoConta: '',
        numeroConta: '',
    },
    error: null,
}

export function providerReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PROVIDER: 
            let value = ""
            
            if (action.payload.name.includes('email') || 
                action.payload.name.includes('homePage')) {
                value = action.payload.value.toLowerCase();
            } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                provider: { ...state.provider, 
                    [action.payload.name]: value
                }
            })
        case ADD_PROVIDER: 
            return Object.assign({}, state, {
                provider: { ...initialState.provider }
            })
        case FETCH_PROVIDERS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfProviders: [],
            })
        case FETCH_PROVIDERS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfProviders: action.payload
            })
        case FETCH_PROVIDERS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_PROVIDER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_PROVIDER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                provider: { 
                    ...action.payload, 
                    especialidades: action.payload.especialidades.trim().split(',') 
                }
            })
        case CREATE_PROVIDER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_PROVIDER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_PROVIDER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                provider: { 
                    ...action.payload, 
                    especialidades: action.payload.especialidades.trim().split(',') 
                }
            })
        case UPDATE_PROVIDER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_PROVIDER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_PROVIDER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                provider: { 
                    ...action.payload, 
                    especialidades: action.payload.especialidades.trim().split(',') 
                }
            })
        case FETCH_PROVIDER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_PROVIDER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_PROVIDER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_PROVIDER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}