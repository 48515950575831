import {

    FETCH_PARAMETERS_PENDING,
    FETCH_PARAMETERS_FULFILLED,
    FETCH_PARAMETERS_REJECTED,

    FETCH_PARAMETER_PENDING,
    FETCH_PARAMETER_FULFILLED,
    FETCH_PARAMETER_REJECTED,

    CREATE_PARAMETER_PENDING,
    CREATE_PARAMETER_FULFILLED,
    CREATE_PARAMETER_REJECTED,

    UPDATE_PARAMETER_PENDING,
    UPDATE_PARAMETER_FULFILLED,
    UPDATE_PARAMETER_REJECTED,

    DELETE_PARAMETER_PENDING,
    DELETE_PARAMETER_FULFILLED,
    DELETE_PARAMETER_REJECTED,
    
    CHANGE_PARAMETER,
    
    ADD_PARAMETER

} from './parametersActions'

import { titleCase } from '../../../../common/utils'

const moment = require('moment')

const initialState = {
    loading: false,
    listOfParameter: [],
    parameter: {
        codigo: 0,
    },
    error: null,
}

export function parametersReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PARAMETER:
            return Object.assign({}, state, {
                parameter: { ...state.parameter, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_PARAMETER: 
            return Object.assign({}, state, {
                parameter: { ...initialState.parameter }
            })
        case FETCH_PARAMETERS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfParameter: [],
            })
        case FETCH_PARAMETERS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfParameter: action.payload
            })
        case FETCH_PARAMETERS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_PARAMETER_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //parameter: { ...initialState.parameter },
            })
        case CREATE_PARAMETER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                parameter: action.payload
            })
        case CREATE_PARAMETER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_PARAMETER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_PARAMETER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                parameter: action.payload
            })
        case UPDATE_PARAMETER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_PARAMETER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_PARAMETER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                parameter: action.payload
            })
        case FETCH_PARAMETER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_PARAMETER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_PARAMETER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_PARAMETER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}