import api from '../../../../api'

export const FETCH_PATIENTS_PENDING = 'FETCH_PATIENTS_PENDING'
export const FETCH_PATIENTS_FULFILLED = 'FETCH_PATIENTS_FULFILLED'
export const FETCH_PATIENTS_REJECTED = 'FETCH_PATIENTS_REJECTED'

export const FETCH_PATIENT_PENDING = 'FETCH_PATIENT_PENDING'
export const FETCH_PATIENT_FULFILLED = 'FETCH_PATIENT_FULFILLED'
export const FETCH_PATIENT_REJECTED = 'FETCH_PATIENT_REJECTED'

export const CREATE_PATIENT_PENDING = 'CREATE_PATIENT_PENDING'
export const CREATE_PATIENT_FULFILLED = 'CREATE_PATIENT_FULFILLED'
export const CREATE_PATIENT_REJECTED = 'CREATE_PATIENT_REJECTED'

export const UPDATE_PATIENT_PENDING = 'UPDATE_PATIENT_PENDING'
export const UPDATE_PATIENT_FULFILLED = 'UPDATE_PATIENT_FULFILLED'
export const UPDATE_PATIENT_REJECTED = 'UPDATE_PATIENT_REJECTED'

export const DELETE_PATIENT_PENDING = 'DELETE_PATIENT_PENDING'
export const DELETE_PATIENT_FULFILLED = 'DELETE_PATIENT_FULFILLED'
export const DELETE_PATIENT_REJECTED = 'DELETE_PATIENT_REJECTED'

export const CHANGE_PATIENT = 'CHANGE_PATIENT'
export const ADD_PATIENT = 'ADD_PATIENT'

export function changePatient(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_PATIENT, payload: { name, value } })
  }
}

export function addPatient() {
  return dispatch => {
    dispatch({ type: ADD_PATIENT });
  };
}

export function findAllPatients(first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PATIENTS_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
                query findAllPatients {
                  pacientes (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    razaoSocial
                    cpf
                    nascimento
                    celular
                    foneResidencial
                    email
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PATIENTS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { pacientes } = data
        dispatch({ type: FETCH_PATIENTS_FULFILLED, payload: pacientes });
        resolve(pacientes);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PATIENTS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllPatientByName(search = '') {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PATIENTS_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
                query findAllPatientByName {
                  pacientesPorNome (search: "${search}") {
                    codigo
                    status
                    razaoSocial
                    cpf
                    nascimento
                    celular
                    foneResidencial
                    email
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PATIENTS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { pacientesPorNome } = data
        dispatch({ type: FETCH_PATIENTS_FULFILLED, payload: pacientesPorNome });
        if (search.trim() === "") {
          dispatch(findAllPatients());
        }
        resolve(pacientesPorNome);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PATIENTS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findPatient(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PATIENT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findPatient {
                  paciente (codigo: ${codigo}) {
                    codigo
                    status
                    razaoSocial
                    sexo
                    nascimento
                    imagem
                    estadoCivil       
                    nacionalidade
                    necessidades
                    fatorSangue   
                    naturalEstado
                    naturalMunicipio
                    escolaridade
                    cpf
                    rg
                    expedidorRg
                    ufRg
                    numeroSus
                    peso
                    altura
                    profissao
                    nomePai
                    nomeMae
                    tipoEndereco
                    endereco
                    numero
                    complemento
                    cep
                    bairro
                    estado
                    municipio
                    celular
                    telefone
                    foneResidencial
                    foneComercial
                    email
                    planosSaude {
                      codigo
                      paciente
                      adesao
                      vencimento
                      cartao
                      modalidade
                      planoSaude {
                        codigo
                        descricao
                      }
                      modalidadePlano {
                        codigo
                        descricao
                      }
                    }
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PATIENT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { paciente } = data
        dispatch({ type: FETCH_PATIENT_FULFILLED, payload: paciente });
        resolve(paciente);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PATIENT_REJECTED, error });
      resolve(error);
    });

  });

}

export function createPatient(patient) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PATIENT_PENDING });

    const { planosSaude } = patient;

    let healtPlans = [`{
      codigo: 0
      paciente: 0
      adesao: ""
      vencimento: ""
      cartao: ""
      planoSaude: 0
      modalidade: 0
    }`];

    if (planosSaude.length > 0) {
      healtPlans = planosSaude.map((healtPlan) => (
        `{
          codigo: ${healtPlan.codigo}
          paciente: ${patient.codigo}
          adesao: "${healtPlan.adesao}"
          vencimento: "${healtPlan.vencimento}"
          cartao: "${healtPlan.cartao}"
          modalidade: ${healtPlan.modalidade}
          planoSaude: ${healtPlan.planoSaude.codigo}
      }`
      ));
    }

    api({
      method: 'post',       
      data: {
        query: `
          mutation createPatient {
            createPaciente (input: {
              codigo: ${patient.codigo}
              status: ${patient.status}
              razaoSocial: "${patient.razaoSocial}"
              sexo: "${patient.sexo}"
              nascimento: "${patient.nascimento}"
              imagem: "${patient.imagem}"
              estadoCivil: ${patient.estadoCivil}
              nacionalidade: ${patient.nacionalidade}    
              necessidades: ${patient.necessidades}
              fatorSangue: "${patient.fatorSangue}" 
              naturalEstado: ${patient.naturalEstado}
              naturalMunicipio: ${patient.naturalMunicipio}
              escolaridade: ${patient.escolaridade}
              cpf: "${patient.cpf}"
              rg: "${patient.rg}"
              expedidorRg: ${patient.expedidorRg} 
              ufRg: ${patient.ufRg} 
              numeroSus: "${patient.numeroSus}"
              peso: ${parseInt(patient.peso)}
              altura: ${parseFloat(patient.altura)}
              profissao: ${patient.profissao}
              nomePai: "${patient.nomePai}"
              nomeMae: "${patient.nomeMae}"
              tipoEndereco: ${patient.tipoEndereco}
              endereco: "${patient.endereco}"
              numero: "${patient.numero}"
              complemento: "${patient.complemento}"
              cep: "${patient.cep}"
              bairro: "${patient.bairro}"
              estado: ${patient.estado}
              municipio: ${patient.municipio}
              celular: "${patient.celular}"
              telefone: "${patient.telefone}"
              foneResidencial: "${patient.foneResidencial}"
              foneComercial: "${patient.foneComercial}"
              email: "${patient.email}"
              planosSaude: [${healtPlans}]
            } ) {
              codigo
              status
              razaoSocial
              sexo
              nascimento
              imagem
              estadoCivil       
              nacionalidade
              necessidades
              fatorSangue   
              naturalEstado
              naturalMunicipio
              escolaridade
              cpf
              rg
              expedidorRg
              ufRg
              numeroSus
              peso
              altura
              profissao
              nomePai
              nomeMae
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              telefone
              foneResidencial
              foneComercial
              email
              planosSaude {
                codigo
                paciente
                adesao
                vencimento
                cartao
                modalidade
                planoSaude {
                  codigo
                  descricao
                }
                modalidadePlano {
                  codigo
                  descricao
                }
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PATIENT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createPaciente } = data
        dispatch({ type: CREATE_PATIENT_FULFILLED, payload: createPaciente });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PATIENT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updatePatient(patient) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_PATIENT_PENDING });

    const { planosSaude } = patient;
    let healtPlans = [`{
        codigo: 0
        paciente: 0
        adesao: ""
        vencimento: ""
        cartao: ""
        planoSaude: 0
        modalidade: 0
      }`];

      if (planosSaude.length > 0) {
        healtPlans = planosSaude.map((healtPlan) => (
          `{
            codigo: ${healtPlan.codigo}
            paciente: ${patient.codigo}
            adesao: "${healtPlan.adesao}"
            vencimento: "${healtPlan.vencimento}"
            cartao: "${healtPlan.cartao}"
            modalidade: ${healtPlan.modalidade}
            planoSaude: ${healtPlan.planoSaude.codigo}
        }`
        ));
      }

      api({
      method: 'post',        
      data: {
        query: `
          mutation UpdatePatient {
            updatePaciente (input: {
              codigo: ${patient.codigo}
              status: ${patient.status}
              razaoSocial: "${patient.razaoSocial}"
              sexo: "${patient.sexo}"
              nascimento: "${patient.nascimento}"
              imagem: "${patient.imagem}"
              estadoCivil: ${patient.estadoCivil}
              nacionalidade: ${patient.nacionalidade}    
              necessidades: ${patient.necessidades}
              fatorSangue: "${patient.fatorSangue}" 
              naturalEstado: ${patient.naturalEstado}
              naturalMunicipio: ${patient.naturalMunicipio}
              escolaridade: ${patient.escolaridade}
              cpf: "${patient.cpf}"
              rg: "${patient.rg}"
              expedidorRg: ${patient.expedidorRg}
              ufRg: ${patient.ufRg} 
              numeroSus: "${patient.numeroSus}"
              peso: ${parseInt(patient.peso)}
              altura: ${parseFloat(patient.altura)}
              profissao: ${patient.profissao}
              nomePai: "${patient.nomePai}"
              nomeMae: "${patient.nomeMae}"
              tipoEndereco: ${patient.tipoEndereco}
              endereco: "${patient.endereco}"
              numero: "${patient.numero}"
              complemento: "${patient.complemento}"
              cep: "${patient.cep}"
              bairro: "${patient.bairro}"
              estado: ${patient.estado}
              municipio: ${patient.municipio}
              celular: "${patient.celular}"
              telefone: "${patient.telefone}"
              foneResidencial: "${patient.foneResidencial}"
              foneComercial: "${patient.foneComercial}"
              email: "${patient.email}"
              planosSaude: [${healtPlans}]
            } ) {
              codigo
              status
              razaoSocial
              sexo
              nascimento
              imagem
              estadoCivil       
              nacionalidade
              necessidades
              fatorSangue   
              naturalEstado
              naturalMunicipio
              escolaridade
              cpf
              rg
              expedidorRg
              ufRg
              numeroSus
              peso
              altura
              profissao
              nomePai
              nomeMae
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              telefone
              foneResidencial
              foneComercial
              email
              planosSaude {
                codigo
                paciente
                vencimento
                cartao
                modalidade
                planoSaude {
                  codigo
                  descricao
                }
                modalidadePlano {
                  codigo
                  descricao
                }
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_PATIENT_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updatePaciente } = data
        dispatch({ type: UPDATE_PATIENT_FULFILLED, payload: updatePaciente });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_PATIENT_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removePatient(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_PATIENT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemovePatient {
            deletePaciente (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_PATIENT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deletePaciente } = data
        dispatch({ type: DELETE_PATIENT_FULFILLED, payload: deletePaciente });
        dispatch(findAllPatients());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_PATIENT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
