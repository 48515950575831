import {

    FETCH_CAMPAIGNS_PENDING,
    FETCH_CAMPAIGNS_FULFILLED,
    FETCH_CAMPAIGNS_REJECTED,

    FETCH_CAMPAIGN_PENDING,
    FETCH_CAMPAIGN_FULFILLED,
    FETCH_CAMPAIGN_REJECTED,

    CREATE_CAMPAIGN_PENDING,
    CREATE_CAMPAIGN_FULFILLED,
    CREATE_CAMPAIGN_REJECTED,

    UPDATE_CAMPAIGN_PENDING,
    UPDATE_CAMPAIGN_FULFILLED,
    UPDATE_CAMPAIGN_REJECTED,

    DELETE_CAMPAIGN_PENDING,
    DELETE_CAMPAIGN_FULFILLED,
    DELETE_CAMPAIGN_REJECTED,
    
    CHANGE_CAMPAIGN,
    
    ADD_CAMPAIGN

} from './campaignActions'

import { titleCase } from "../../../../common/utils";

const initialState = {
    loading: false,
    listOfCampaigns: [],
    campaign: {
        codigo: 0,
        title: '',
        shortDescription: '',
        htmlContent: '',
        expire: false,
        startDate: '',
        endDate: '',
        imageHomeUrl: '',
        imageDetailUrl: '',
        conditions: '',
    },
    error: null,
}

export function campaignReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_CAMPAIGN: 
            return Object.assign({}, state, {
                campaign: { ...state.campaign, 
                    [action.payload.name]: action.payload.value
                }
            })
        case ADD_CAMPAIGN: 
            return Object.assign({}, state, {
                campaign: { ...initialState.campaign }
            })
        case FETCH_CAMPAIGNS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfCampaigns: [],
            })
        case FETCH_CAMPAIGNS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfCampaigns: action.payload || []
            })
        case FETCH_CAMPAIGNS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_CAMPAIGN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_CAMPAIGN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                campaign: action.payload
            })
        case CREATE_CAMPAIGN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_CAMPAIGN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_CAMPAIGN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                campaign: action.payload
            })
        case UPDATE_CAMPAIGN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_CAMPAIGN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_CAMPAIGN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                campaign: action.payload
            })
        case FETCH_CAMPAIGN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_CAMPAIGN_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_CAMPAIGN_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_CAMPAIGN_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}