import api from '../../../../api'

export const FETCH_REGION_PENDING = 'FETCH_REGION_PENDING'
export const FETCH_REGION_FULFILLED = 'FETCH_REGION_FULFILLED'
export const FETCH_REGION_REJECTED = 'FETCH_REGION_REJECTED'

export function findAllRegions() {

    return dispatch => new Promise((resolve, reject) => {

        dispatch({ type: FETCH_REGION_PENDING });

        api({
            method: 'post',        
            data: {
                query: `
          query findAllRegions {
            estados(first: 30 offset: 0) {
                codigo
                nome
                uf
            }
          }
          `
            }
        }).then((result) => {
            const { errors, data } = result.data
            if (errors) {
                dispatch({ 
                  type: FETCH_REGION_REJECTED, 
                  payload: errors });
                resolve(errors);
            } else {
                const { estados } = data
                dispatch({ 
                  type: FETCH_REGION_FULFILLED, 
                  payload: estados });
                resolve(data);
            }
        }).catch((error) => {
            dispatch({ 
              type: FETCH_REGION_REJECTED, 
              error 
            });
            resolve(error);
        });

    });

}
