import {

    FETCH_COMPANIES_PENDING,
    FETCH_COMPANIES_FULFILLED,
    FETCH_COMPANIES_REJECTED,

    FETCH_COMPANY_PENDING,
    FETCH_COMPANY_FULFILLED,
    FETCH_COMPANY_REJECTED,

    CREATE_COMPANY_PENDING,
    CREATE_COMPANY_FULFILLED,
    CREATE_COMPANY_REJECTED,

    UPDATE_COMPANY_PENDING,
    UPDATE_COMPANY_FULFILLED,
    UPDATE_COMPANY_REJECTED,

    DELETE_COMPANY_PENDING,
    DELETE_COMPANY_FULFILLED,
    DELETE_COMPANY_REJECTED,
    
    CHANGE_COMPANY,
    
    ADD_COMPANY

} from './companyActions'

import { titleCase } from "../../../../common/utils";

const moment = require('moment');
const initialState = {
    loading: false,
    listOfCompanies: [],
    company: {
        codigo: 0,
        status: 0,
        tipoEmpresa: 'J',
        dataAbertura: moment().format("DD/MM/YYYY"),
        cpfCnpj: '',
        razaoSocial: '',
        nomeFantasia: '',
        numeroCNAE: '',
        numeroIE: '',
        numeroIM: '',
        tipoRegime: 0,
        tipoEndereco: '5',
        endereco: '',
        numero: '',
        complemento: '',
        cep: '',
        bairro: '',
        estado: 0,
        municipio: 0,
        celular: '',
        fixo: '',
        ramal: '',
        responsavel: '',
        email: '',
        homePage: ''
    },
    error: null,
}

export function companyReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_COMPANY: 
            let value = ""
            
            if (action.payload.name.includes('email') || 
                action.payload.name.includes('homePage')) {
                value = action.payload.value.toLowerCase();
            } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                company: { ...state.company, 
                    [action.payload.name]: value
                }
            })
        case ADD_COMPANY: 
            return Object.assign({}, state, {
                company: { ...initialState.company }
            })
        case FETCH_COMPANIES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfCompanies: [],
            })
        case FETCH_COMPANIES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfCompanies: action.payload
            })
        case FETCH_COMPANIES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_COMPANY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_COMPANY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                company: action.payload
            })
        case CREATE_COMPANY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_COMPANY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_COMPANY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                company: action.payload
            })
        case UPDATE_COMPANY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_COMPANY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_COMPANY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                company: action.payload
            })
        case FETCH_COMPANY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_COMPANY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_COMPANY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_COMPANY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}