import {

    FETCH_PLAN_MODALITIES_PENDING,
    FETCH_PLAN_MODALITIES_FULFILLED,
    FETCH_PLAN_MODALITIES_REJECTED,

    FETCH_PLAN_MODALITY_PENDING,
    FETCH_PLAN_MODALITY_FULFILLED,
    FETCH_PLAN_MODALITY_REJECTED,

    CREATE_PLAN_MODALITY_PENDING,
    CREATE_PLAN_MODALITY_FULFILLED,
    CREATE_PLAN_MODALITY_REJECTED,

    UPDATE_PLAN_MODALITY_PENDING,
    UPDATE_PLAN_MODALITY_FULFILLED,
    UPDATE_PLAN_MODALITY_REJECTED,

    DELETE_PLAN_MODALITY_PENDING,
    DELETE_PLAN_MODALITY_FULFILLED,
    DELETE_PLAN_MODALITY_REJECTED,
    
    CHANGE_PLAN_MODALITY,
    
    ADD_PLAN_MODALITY

} from './planModalityActions'

import { titleCase } from '../../../../../common/utils'

const initialState = {
    loading: false,
    listOfPlanModalities: [],
    planModality: {
        codigo: 0, 
        status: 0,
        descricao: '',
        cobertura: 0,
        valor: 0.0,
        plano: 0,
        planoSaude: {
            codigo: 0,
            descricao: '',
        }
    },
    error: null,
}

export function planModalityReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PLAN_MODALITY:
            return Object.assign({}, state, {
                planModality: { ...state.planModality, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_PLAN_MODALITY: 
            return Object.assign({}, state, {
                planModality: { ...initialState.planModality }
            })
        case FETCH_PLAN_MODALITIES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfPlanModalities: [],
            })
        case FETCH_PLAN_MODALITIES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfPlanModalities: action.payload
            })
        case FETCH_PLAN_MODALITIES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_PLAN_MODALITY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //planModality: { ...initialState.planModality },
            })
        case CREATE_PLAN_MODALITY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                planModality: action.payload
            })
        case CREATE_PLAN_MODALITY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_PLAN_MODALITY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_PLAN_MODALITY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                planModality: action.payload
            })
        case UPDATE_PLAN_MODALITY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_PLAN_MODALITY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_PLAN_MODALITY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                planModality: action.payload
            })
        case FETCH_PLAN_MODALITY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_PLAN_MODALITY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_PLAN_MODALITY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_PLAN_MODALITY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}