import {

    FETCH_BILLS_TO_RECEIVE_PENDING,
    FETCH_BILLS_TO_RECEIVE_FULFILLED,
    FETCH_BILLS_TO_RECEIVE_REJECTED,

    FETCH_BILL_TO_RECEIVE_PENDING,
    FETCH_BILL_TO_RECEIVE_FULFILLED,
    FETCH_BILL_TO_RECEIVE_REJECTED,

    CREATE_BILL_TO_RECEIVE_PENDING,
    CREATE_BILL_TO_RECEIVE_FULFILLED,
    CREATE_BILL_TO_RECEIVE_REJECTED,

    UPDATE_BILL_TO_RECEIVE_PENDING,
    UPDATE_BILL_TO_RECEIVE_FULFILLED,
    UPDATE_BILL_TO_RECEIVE_REJECTED,

    DELETE_BILL_TO_RECEIVE_PENDING,
    DELETE_BILL_TO_RECEIVE_FULFILLED,
    DELETE_BILL_TO_RECEIVE_REJECTED,
    
    CHANGE_BILL_TO_RECEIVE,
    
    ADD_BILL_TO_RECEIVE

} from './billsToReceiveActions'

const moment = require('moment')

const initialState = {
    loading: false,
    listOfBillsToReceive: [],
    billToReceive: {
        codigo: 0,
        status: 0,
        dataMovimento: moment().format("DD/MM/YYYY"),
        tipoDocunento: 0,
        numeroDocumento: '',
        dataEmissao: moment().format("DD/MM/YYYY"), 
        descricao: '',
        centroCusto: 0,
        centroDeCusto: {
            descricao: '',
        },
        valor: '0',
        multa: '0',
        juros: '0',
        desconto: '0',
        dataVencimento: '',
        dataLiquidacao: '',
        valorTotal: '0',
        formaPagamento: 0,
        tipoPortador: 0,
        portador: 0,
        contaPortador: {
            razaoSocial: '',
        },
        contaGerencial: 0,
    },
    error: null,
}

export function billsToReceiveReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_BILL_TO_RECEIVE:
            return Object.assign({}, state, {
                billToReceive: { ...state.billToReceive, 
                    [action.payload.name]: action.payload.value
                }
            })
        case ADD_BILL_TO_RECEIVE: 
            return Object.assign({}, state, {
                billToReceive: { ...initialState.billToReceive }
            })
        case FETCH_BILLS_TO_RECEIVE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfBillsToReceive: [],
            })
        case FETCH_BILLS_TO_RECEIVE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfBillsToReceive: action.payload
            })
        case FETCH_BILLS_TO_RECEIVE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_BILL_TO_RECEIVE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //billToReceive: { ...initialState.billToReceive },
            })
        case CREATE_BILL_TO_RECEIVE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                billToReceive: action.payload
            })
        case CREATE_BILL_TO_RECEIVE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_BILL_TO_RECEIVE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_BILL_TO_RECEIVE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                billToReceive: action.payload
            })
        case UPDATE_BILL_TO_RECEIVE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_BILL_TO_RECEIVE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_BILL_TO_RECEIVE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                billToReceive: action.payload
            })
        case FETCH_BILL_TO_RECEIVE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_BILL_TO_RECEIVE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_BILL_TO_RECEIVE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_BILL_TO_RECEIVE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}