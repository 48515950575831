import api from '../../../../api'
import { toFloat } from '../../../../common/utils'

export const FETCH_PAYMENT_METHODS_PENDING = 'FETCH_PAYMENT_METHODS_PENDING'
export const FETCH_PAYMENT_METHODS_FULFILLED = 'FETCH_PAYMENT_METHODS_FULFILLED'
export const FETCH_PAYMENT_METHODS_REJECTED = 'FETCH_PAYMENT_METHODS_REJECTED'

export const FETCH_PAYMENT_METHOD_PENDING = 'FETCH_PAYMENT_METHOD_PENDING'
export const FETCH_PAYMENT_METHOD_FULFILLED = 'FETCH_PAYMENT_METHOD_FULFILLED'
export const FETCH_PAYMENT_METHOD_REJECTED = 'FETCH_PAYMENT_METHOD_REJECTED'

export const CREATE_PAYMENT_METHOD_PENDING = 'CREATE_PAYMENT_METHOD_PENDING'
export const CREATE_PAYMENT_METHOD_FULFILLED = 'CREATE_PAYMENT_METHOD_FULFILLED'
export const CREATE_PAYMENT_METHOD_REJECTED = 'CREATE_PAYMENT_METHOD_REJECTED'

export const UPDATE_PAYMENT_METHOD_PENDING = 'UPDATE_PAYMENT_METHOD_PENDING'
export const UPDATE_PAYMENT_METHOD_FULFILLED = 'UPDATE_PAYMENT_METHOD_FULFILLED'
export const UPDATE_PAYMENT_METHOD_REJECTED = 'UPDATE_PAYMENT_METHOD_REJECTED'

export const DELETE_PAYMENT_METHOD_PENDING = 'DELETE_PAYMENT_METHOD_PENDING'
export const DELETE_PAYMENT_METHOD_FULFILLED = 'DELETE_PAYMENT_METHOD_FULFILLED'
export const DELETE_PAYMENT_METHOD_REJECTED = 'DELETE_PAYMENT_METHOD_REJECTED'

export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD'

export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD'

export function changePayment(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_PAYMENT_METHOD, payload: { name, value } })
  }
}
 
export function addPayment() {
  return dispatch => {
    dispatch({ type: ADD_PAYMENT_METHOD });
  };
}

export function findAllPaymentMethods(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PAYMENT_METHODS_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllPaymentMethods {
                  formasPagamento (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    descricao
                    tipo
                    maximoParcelas
                    primeiraParcela
                    intervaloDias
                    taxaOperadora
                    jurosMora
                    jurosMulta  
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PAYMENT_METHODS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { formasPagamento } = data
        dispatch({ type: FETCH_PAYMENT_METHODS_FULFILLED, payload: formasPagamento });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PAYMENT_METHODS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findPayment(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PAYMENT_METHOD_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findPayment {
                  formaPagamento (codigo: ${codigo}) {
                    codigo
                    status
                    descricao
                    tipo
                    maximoParcelas
                    primeiraParcela
                    intervaloDias
                    taxaOperadora
                    jurosMora
                    jurosMulta  
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PAYMENT_METHOD_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { formaPagamento } = data
        dispatch({ type: FETCH_PAYMENT_METHOD_FULFILLED, payload: formaPagamento });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PAYMENT_METHOD_REJECTED, error });
      resolve(error);
    });

  });

}

export function createPayment(paymentMethods) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PAYMENT_METHOD_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createPayment {
            createFormaPagamento (input: {
              codigo: ${paymentMethods.codigo}
              status: ${paymentMethods.status}
              descricao: "${paymentMethods.descricao}"
              tipo: ${paymentMethods.tipo}
              maximoParcelas: ${paymentMethods.maximoParcelas}
              primeiraParcela: ${paymentMethods.primeiraParcela}
              intervaloDias: ${paymentMethods.intervaloDias}
              taxaOperadora: ${toFloat(paymentMethods.taxaOperadora)}
              jurosMora: ${toFloat(paymentMethods.jurosMora)}
              jurosMulta: ${toFloat(paymentMethods.jurosMulta)} 
          } ) 
            {
              codigo
              status
              descricao
              tipo
              maximoParcelas
              primeiraParcela
              intervaloDias
              taxaOperadora
              jurosMora
              jurosMulta  
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PAYMENT_METHOD_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createFormaPagamento } = data
        dispatch({ type: CREATE_PAYMENT_METHOD_FULFILLED, payload: createFormaPagamento });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PAYMENT_METHOD_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updatePayment(paymentMethods) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PAYMENT_METHOD_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation updatePayment {
            updateFormaPagamento (input: {
              codigo: ${paymentMethods.codigo}
              status: ${paymentMethods.status}
              descricao: "${paymentMethods.descricao}"
              tipo: ${paymentMethods.tipo}
              maximoParcelas: ${paymentMethods.maximoParcelas}
              primeiraParcela: ${paymentMethods.primeiraParcela}
              intervaloDias: ${paymentMethods.intervaloDias}
              taxaOperadora: ${toFloat(paymentMethods.taxaOperadora)}
              jurosMora: ${toFloat(paymentMethods.jurosMora)}
              jurosMulta: ${toFloat(paymentMethods.jurosMulta)} 
            } ) 
            {
              codigo
              status
              descricao
              tipo
              maximoParcelas
              primeiraParcela
              intervaloDias
              taxaOperadora
              jurosMora
              jurosMulta  
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PAYMENT_METHOD_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateFormaPagamento } = data
        dispatch({ type: CREATE_PAYMENT_METHOD_FULFILLED, payload: updateFormaPagamento });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PAYMENT_METHOD_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removePayment(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_PAYMENT_METHOD_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation removePayment {
            deletePayment (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_PAYMENT_METHOD_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deletePayment } = data
        dispatch({ type: DELETE_PAYMENT_METHOD_FULFILLED, payload: deletePayment });
        dispatch(findAllPaymentMethods());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_PAYMENT_METHOD_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
