import {

    FETCH_ACCREDITEDS_PENDING,
    FETCH_ACCREDITEDS_FULFILLED,
    FETCH_ACCREDITEDS_REJECTED,

    FETCH_ACCREDITED_PENDING,
    FETCH_ACCREDITED_FULFILLED,
    FETCH_ACCREDITED_REJECTED,

    CREATE_ACCREDITED_PENDING,
    CREATE_ACCREDITED_FULFILLED, 
    CREATE_ACCREDITED_REJECTED,

    UPDATE_ACCREDITED_PENDING,
    UPDATE_ACCREDITED_FULFILLED,
    UPDATE_ACCREDITED_REJECTED,

    DELETE_ACCREDITED_PENDING,
    DELETE_ACCREDITED_FULFILLED,
    DELETE_ACCREDITED_REJECTED,
    
    CHANGE_ACCREDITED,
    
    ADD_ACCREDITED

} from './accreditedActions'

import { titleCase } from "../../../../common/utils";

const initialState = {
    loading: false,
    listOfAccrediteds: [],
    accredited: {
        codigo: 0,
        status: 0,
        tipoCredenciado: 'J',
        categoria: 0,
        cpfCnpj: '',
        razaoSocial: '',
        nomeFantasia: '',
        aliquotaPis: 0.0,
        aliquotaCofins: 0.0,
        aliquotaIss: 0.0,
        aliquotaIr: 0.0,
        aliquotaInss: 0.0,
        aliquotaCsll: 0.0,
        numeroCNAE: '',
        numeroIE: '',
        numeroIM: '',
        tipoRegime: 0,
        tipoEndereco: '5',
        endereco: '',
        numero: '',
        complemento: '',
        cep: '', 
        bairro: '',
        estado: 0,
        municipio: 0,
        celular: '',
        fixo: '',
        ramal: '',
        responsavel: '',
        email: '',
        homePage: '',
        banco: 0,
        tipoConta: 0,
        numeroAgencia: '',
        numeroConta: '',
    },
    error: null,
}

export function accreditedReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_ACCREDITED: 
            let value = ""
            
            if (action.payload.name.includes('email') || 
                action.payload.name.includes('homePage')) { 
                value = action.payload.value.toLowerCase();
            } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                accredited: { ...state.accredited, 
                    [action.payload.name]: value
                }
            })
        case ADD_ACCREDITED: 
            return Object.assign({}, state, {
                accredited: { ...initialState.accredited }
            })
        case FETCH_ACCREDITEDS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfAccrediteds: [],
            })
        case FETCH_ACCREDITEDS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfAccrediteds: action.payload || []
            })
        case FETCH_ACCREDITEDS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_ACCREDITED_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_ACCREDITED_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                accredited: action.payload
            })
        case CREATE_ACCREDITED_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_ACCREDITED_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_ACCREDITED_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                accredited: action.payload
            })
        case UPDATE_ACCREDITED_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_ACCREDITED_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_ACCREDITED_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                accredited: action.payload
            })
        case FETCH_ACCREDITED_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_ACCREDITED_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_ACCREDITED_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_ACCREDITED_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}