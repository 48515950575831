import api from '../../../../../api'

export const FETCH_DEPENDENTS_PENDING = 'FETCH_DEPENDENTS_PENDING'
export const FETCH_DEPENDENTS_FULFILLED = 'FETCH_DEPENDENTS_FULFILLED'
export const FETCH_DEPENDENTS_REJECTED = 'FETCH_DEPENDENTS_REJECTED'

export const FETCH_DEPENDENT_PENDING = 'FETCH_DEPENDENT_PENDING'
export const FETCH_DEPENDENT_FULFILLED = 'FETCH_DEPENDENT_FULFILLED'
export const FETCH_DEPENDENT_REJECTED = 'FETCH_DEPENDENT_REJECTED'

export const CREATE_DEPENDENT_PENDING = 'CREATE_DEPENDENT_PENDING'
export const CREATE_DEPENDENT_FULFILLED = 'CREATE_DEPENDENT_FULFILLED'
export const CREATE_DEPENDENT_REJECTED = 'CREATE_DEPENDENT_REJECTED'

export const UPDATE_DEPENDENT_PENDING = 'UPDATE_DEPENDENT_PENDING'
export const UPDATE_DEPENDENT_FULFILLED = 'UPDATE_DEPENDENT_FULFILLED'
export const UPDATE_DEPENDENT_REJECTED = 'UPDATE_DEPENDENT_REJECTED'

export const DELETE_DEPENDENT_PENDING = 'DELETE_DEPENDENT_PENDING'
export const DELETE_DEPENDENT_FULFILLED = 'DELETE_DEPENDENT_FULFILLED'
export const DELETE_DEPENDENT_REJECTED = 'DELETE_DEPENDENT_REJECTED'

export const CHANGE_DEPENDENT = 'CHANGE_DEPENDENT'

export const ADD_DEPENDENT = 'ADD_DEPENDENT'

export function changeDependent(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_DEPENDENT, payload: { name, value } })
  }
}

export function addDependent() {
  return dispatch => {
    dispatch({ type: ADD_DEPENDENT });
  };
}

export function findAllDependents(patient, first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_DEPENDENTS_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
                query findAllDependents {
                  dependentes (paciente: ${patient} first: ${first} offset: ${offset}) {
                    codigo
                    razaoSocial
                    cpf
                    nascimento
                    celular
                    parentesco
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_DEPENDENTS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { dependentes } = data
        dispatch({ type: FETCH_DEPENDENTS_FULFILLED, payload: dependentes });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_DEPENDENTS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findDependent(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_DEPENDENT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findDependent {
                  dependente (codigo: ${codigo}) {
                    codigo
                    paciente
                    parentesco
                    status
                    razaoSocial
                    sexo
                    nascimento
                    estadoCivil       
                    nacionalidade
                    necessidades
                    fatorSangue   
                    naturalEstado
                    naturalMunicipio
                    escolaridade
                    cpf
                    rg
                    expedidorRg
                    ufRg
                    numeroSus
                    peso
                    altura
                    profissao
                    nomePai
                    nomeMae
                    tipoEndereco
                    endereco
                    numero
                    complemento
                    cep
                    bairro
                    estado
                    municipio
                    celular
                    telefone
                    foneResidencial
                    foneComercial
                    email
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_DEPENDENT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { dependente } = data
        dispatch({ type: FETCH_DEPENDENT_FULFILLED, payload: dependente });
        resolve(dependente);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_DEPENDENT_REJECTED, error });
      resolve(error);
    });

  });

}

export function createDependent(patient, dependent) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_DEPENDENT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createDependent {
            createDependente (input: {
              codigo: ${dependent.codigo}
              paciente: ${patient}
              parentesco: ${dependent.parentesco}
              status: ${dependent.status}
              razaoSocial: "${dependent.razaoSocial}"
              sexo: "${dependent.sexo}"
              nascimento: "${dependent.nascimento}"
              estadoCivil: ${dependent.estadoCivil}
              nacionalidade: ${dependent.nacionalidade}    
              necessidades: ${dependent.necessidades}
              fatorSangue: "${dependent.fatorSangue}" 
              naturalEstado: ${dependent.naturalEstado}
              naturalMunicipio: ${dependent.naturalMunicipio}
              escolaridade: ${dependent.escolaridade}
              cpf: "${dependent.cpf}"
              rg: "${dependent.rg}"
              expedidorRg: ${dependent.expedidorRg}
              ufRg: ${dependent.ufRg} 
              numeroSus: "${dependent.numeroSus}"
              peso: ${parseInt(dependent.peso)}
              altura: ${parseFloat(dependent.altura)}
              profissao: ${dependent.profissao}
              nomePai: "${dependent.nomePai}"
              nomeMae: "${dependent.nomeMae}"
              tipoEndereco: ${dependent.tipoEndereco}
              endereco: "${dependent.endereco}"
              numero: "${dependent.numero}"
              complemento: "${dependent.complemento}"
              cep: "${dependent.cep}"
              bairro: "${dependent.bairro}"
              estado: ${dependent.estado}
              municipio: ${dependent.municipio}
              celular: "${dependent.celular}"
              telefone: "${dependent.telefone}"
              foneResidencial: "${dependent.foneResidencial}"
              foneComercial: "${dependent.foneComercial}"
              email: "${dependent.email}"
            } ) {
              codigo
              paciente
              parentesco
              status
              razaoSocial
              sexo
              nascimento
              estadoCivil       
              nacionalidade
              necessidades
              fatorSangue   
              naturalEstado
              naturalMunicipio
              escolaridade
              cpf
              rg
              expedidorRg
              ufRg
              numeroSus
              peso
              altura
              profissao
              nomePai
              nomeMae
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              telefone
              foneResidencial
              foneComercial
              email
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_DEPENDENT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createDependente } = data
        dispatch({ type: CREATE_DEPENDENT_FULFILLED, payload: createDependente });
        dispatch(findAllDependents(patient));
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_DEPENDENT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateDependent(patient, dependent) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_DEPENDENT_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation UpdateDependent {
            updateDependente (input: {
              codigo: ${dependent.codigo}
              paciente: ${patient}
              parentesco: ${dependent.parentesco}
              status: ${dependent.status}
              razaoSocial: "${dependent.razaoSocial}"
              sexo: "${dependent.sexo}"
              nascimento: "${dependent.nascimento}"
              estadoCivil: ${dependent.estadoCivil}
              nacionalidade: ${dependent.nacionalidade}    
              necessidades: ${dependent.necessidades}
              fatorSangue: "${dependent.fatorSangue}" 
              naturalEstado: ${dependent.naturalEstado}
              naturalMunicipio: ${dependent.naturalMunicipio}
              escolaridade: ${dependent.escolaridade}
              cpf: "${dependent.cpf}"
              rg: "${dependent.rg}"
              expedidorRg: ${dependent.expedidorRg}
              ufRg: ${dependent.ufRg} 
              numeroSus: "${dependent.numeroSus}"
              peso: ${parseInt(dependent.peso)}
              altura: ${parseFloat(dependent.altura)}
              profissao: ${dependent.profissao}
              nomePai: "${dependent.nomePai}"
              nomeMae: "${dependent.nomeMae}"
              tipoEndereco: ${dependent.tipoEndereco}
              endereco: "${dependent.endereco}"
              numero: "${dependent.numero}"
              complemento: "${dependent.complemento}"
              cep: "${dependent.cep}"
              bairro: "${dependent.bairro}"
              estado: ${dependent.estado}
              municipio: ${dependent.municipio}
              celular: "${dependent.celular}"
              telefone: "${dependent.telefone}"
              foneResidencial: "${dependent.foneResidencial}"
              foneComercial: "${dependent.foneComercial}"
              email: "${dependent.email}"
            } ) {
              codigo
              paciente
              parentesco
              status
              razaoSocial
              sexo
              nascimento
              estadoCivil       
              nacionalidade
              necessidades
              fatorSangue   
              naturalEstado
              naturalMunicipio
              escolaridade
              cpf
              rg
              expedidorRg
              ufRg
              numeroSus
              peso
              altura
              profissao
              nomePai
              nomeMae
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              telefone
              foneResidencial
              foneComercial
              email
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_DEPENDENT_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateDependente } = data
        dispatch({ type: UPDATE_DEPENDENT_FULFILLED, payload: updateDependente });
        dispatch(findAllDependents(patient));
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_DEPENDENT_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeDependent(patient, codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_DEPENDENT_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation RemoveDependent {
            deleteDependente (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_DEPENDENT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteDependente } = data
        dispatch({ type: DELETE_DEPENDENT_FULFILLED, payload: deleteDependente });
        dispatch(findAllDependents(patient));
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_DEPENDENT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
