import api from '../../../../api'
import { toFloat } from '../../../../common/utils'

export const FETCH_BILLS_TO_PAY_PENDING = 'FETCH_BILLS_TO_PAY_PENDING'
export const FETCH_BILLS_TO_PAY_FULFILLED = 'FETCH_BILLS_TO_PAY_FULFILLED'
export const FETCH_BILLS_TO_PAY_REJECTED = 'FETCH_BILLS_TO_PAY_REJECTED'

export const FETCH_BILL_TO_PAY_PENDING = 'FETCH_BILL_TO_PAY_PENDING'
export const FETCH_BILL_TO_PAY_FULFILLED = 'FETCH_BILL_TO_PAY_FULFILLED'
export const FETCH_BILL_TO_PAY_REJECTED = 'FETCH_BILL_TO_PAY_REJECTED'

export const CREATE_BILL_TO_PAY_PENDING = 'CREATE_BILL_TO_PAY_PENDING'
export const CREATE_BILL_TO_PAY_FULFILLED = 'CREATE_BILL_TO_PAY_FULFILLED'
export const CREATE_BILL_TO_PAY_REJECTED = 'CREATE_BILL_TO_PAY_REJECTED'

export const UPDATE_BILL_TO_PAY_PENDING = 'UPDATE_BILL_TO_PAY_PENDING'
export const UPDATE_BILL_TO_PAY_FULFILLED = 'UPDATE_BILL_TO_PAY_FULFILLED'
export const UPDATE_BILL_TO_PAY_REJECTED = 'UPDATE_BILL_TO_PAY_REJECTED'

export const DELETE_BILL_TO_PAY_PENDING = 'DELETE_BILL_TO_PAY_PENDING'
export const DELETE_BILL_TO_PAY_FULFILLED = 'DELETE_BILL_TO_PAY_FULFILLED'
export const DELETE_BILL_TO_PAY_REJECTED = 'DELETE_BILL_TO_PAY_REJECTED'

export const CHANGE_BILL_TO_PAY = 'CHANGE_BILL_TO_PAY'

export const ADD_BILL_TO_PAY = 'ADD_BILL_TO_PAY'

export function changeBillToPay(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_BILL_TO_PAY, payload: { name, value } })
  }
}
 
export function addBillToPay() {
  return dispatch => {
    dispatch({ type: ADD_BILL_TO_PAY });
  };
}

export function findAllBillToPays(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_BILLS_TO_PAY_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllBillToPays {
                  contasPagar (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    dataMovimento
                    tipoDocunento
                    numeroDocumento
                    dataEmissao
                    descricao
                    centroCusto
                    centroDeCusto { 
                      descricao
                    }
                    valor
                    multa
                    juros
                    desconto
                    dataVencimento
                    valorTotal
                    formaPagamento
                    tipoPortador
                    portador
                    contaPortador {
                      razaoSocial
                    }
                    contaGerencial
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_BILLS_TO_PAY_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { contasPagar } = data
        dispatch({ type: FETCH_BILLS_TO_PAY_FULFILLED, payload: contasPagar });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_BILLS_TO_PAY_REJECTED, error });
      resolve(error);
    });

  });

}

export function findBillToPay(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_BILL_TO_PAY_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findBillToPay {
                  contaPagar (codigo: ${codigo}) {
                    codigo
                    status
                    dataMovimento
                    tipoDocunento
                    numeroDocumento
                    dataEmissao
                    descricao
                    centroCusto
                    centroDeCusto {
                      descricao
                    }
                    valor
                    multa
                    juros
                    desconto
                    dataVencimento
                    dataLiquidacao
                    valorTotal
                    formaPagamento
                    tipoPortador
                    portador
                    contaPortador {
                      razaoSocial
                    }
                    contaGerencial
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_BILL_TO_PAY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { contaPagar } = data
        dispatch({ type: FETCH_BILL_TO_PAY_FULFILLED, payload: contaPagar });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_BILL_TO_PAY_REJECTED, error });
      reject(error);
    });

  });

}

export function createBillToPay(billToPay) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_BILL_TO_PAY_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createBillToPay {
            createContaPagar (input: {
              codigo: ${billToPay.codigo}
              status: ${billToPay.status}
              dataMovimento: "${billToPay.dataMovimento}"
              tipoDocunento: ${billToPay.tipoDocunento}
              numeroDocumento: "${billToPay.numeroDocumento}"
              dataEmissao: "${billToPay.dataEmissao}"
              descricao: "${billToPay.descricao}"
              centroCusto: ${billToPay.centroCusto}
              valor: ${toFloat(billToPay.valor)}
              multa: ${toFloat(billToPay.multa)}
              juros: ${toFloat(billToPay.juros)}
              dataVencimento: "${billToPay.dataVencimento}"
              valorTotal: ${toFloat(billToPay.valorTotal)}
              formaPagamento: ${billToPay.formaPagamento}
              tipoPortador: ${billToPay.tipoPortador}
              portador: ${billToPay.portador}
              contaGerencial: ${billToPay.contaGerencial}
            } ) 
            {
              codigo
              status
              dataMovimento
              tipoDocunento
              numeroDocumento
              dataEmissao
              descricao
              centroCusto
              centroDeCusto { 
                descricao
              }
              valor
              multa
              juros
              dataVencimento
              valorTotal
              formaPagamento
              tipoPortador
              portador
              contaPortador {
                razaoSocial
              }
              contaGerencial
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_BILL_TO_PAY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createContaPagar } = data
        dispatch({ type: CREATE_BILL_TO_PAY_FULFILLED, payload: createContaPagar });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_BILL_TO_PAY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateBillToPay(billToPay) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_BILL_TO_PAY_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation UpdateBillToPay {
            updateContaPagar (input: {
              codigo: ${billToPay.codigo}
              status: ${billToPay.status}
              dataMovimento: "${billToPay.dataMovimento}"
              tipoDocunento: ${billToPay.tipoDocunento}
              numeroDocumento: "${billToPay.numeroDocumento}"
              dataEmissao: "${billToPay.dataEmissao}"
              descricao: "${billToPay.descricao}"
              centroCusto: ${billToPay.centroCusto}
              valor: ${toFloat(billToPay.valor)}
              multa: ${toFloat(billToPay.multa)}
              juros: ${toFloat(billToPay.juros)}
              dataVencimento: "${billToPay.dataVencimento}"
              valorTotal: ${toFloat(billToPay.valorTotal)}
              formaPagamento: ${billToPay.formaPagamento}
              tipoPortador: ${billToPay.tipoPortador}
              portador: ${billToPay.portador}
              contaGerencial: ${billToPay.contaGerencial}
            } ) 
            {
              codigo
              status
              dataMovimento
              tipoDocunento
              numeroDocumento
              dataEmissao
              descricao
              centroCusto
              centroDeCusto { 
                descricao
              }
              valor
              multa
              juros
              dataVencimento
              valorTotal
              formaPagamento
              tipoPortador
              portador
              contaPortador {
                razaoSocial
              }
              contaGerencial
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_BILL_TO_PAY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateContaPagar } = data
        dispatch({ type: CREATE_BILL_TO_PAY_FULFILLED, payload: updateContaPagar });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_BILL_TO_PAY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeBillToPay(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_BILL_TO_PAY_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation RemoveBillToPay {
            deleteContaPagar (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_BILL_TO_PAY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteContaPagar } = data
        dispatch({ type: DELETE_BILL_TO_PAY_FULFILLED, payload: deleteContaPagar });
        dispatch(findAllBillToPays());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_BILL_TO_PAY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
