import api from '../../../../api'
import { toFloat } from '../../../../common/utils'

export const FETCH_CUSTOMERS_PENDING = 'FETCH_CUSTOMERS_PENDING'
export const FETCH_CUSTOMERS_FULFILLED = 'FETCH_CUSTOMERS_FULFILLED'
export const FETCH_CUSTOMERS_REJECTED = 'FETCH_CUSTOMERS_REJECTED'

export const FETCH_CUSTOMER_PENDING = 'FETCH_CUSTOMER_PENDING'
export const FETCH_CUSTOMER_FULFILLED = 'FETCH_CUSTOMER_FULFILLED'
export const FETCH_CUSTOMER_REJECTED = 'FETCH_CUSTOMER_REJECTED'

export const CREATE_CUSTOMER_PENDING = 'CREATE_CUSTOMER_PENDING'
export const CREATE_CUSTOMER_FULFILLED = 'CREATE_CUSTOMER_FULFILLED'
export const CREATE_CUSTOMER_REJECTED = 'CREATE_CUSTOMER_REJECTED'

export const UPDATE_CUSTOMER_PENDING = 'UPDATE_CUSTOMER_PENDING'
export const UPDATE_CUSTOMER_FULFILLED = 'UPDATE_CUSTOMER_FULFILLED'
export const UPDATE_CUSTOMER_REJECTED = 'UPDATE_CUSTOMER_REJECTED'

export const DELETE_CUSTOMER_PENDING = 'DELETE_CUSTOMER_PENDING'
export const DELETE_CUSTOMER_FULFILLED = 'DELETE_CUSTOMER_FULFILLED'
export const DELETE_CUSTOMER_REJECTED = 'DELETE_CUSTOMER_REJECTED'

export const CHANGE_CUSTOMER = 'CHANGE_CUSTOMER'

export const ADD_CUSTOMER = 'ADD_CUSTOMER'

export function changeCustomer(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_CUSTOMER, payload: { name, value } })
  }
}

export function addCustomer() {
  return dispatch => {
    dispatch({ type: ADD_CUSTOMER });
  };
}

export function findAllCustomers(first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CUSTOMERS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllCustomers {
                  clientes (first: ${first} offset: ${offset}) {
                    codigo
                    nome
                    cpf
                    nascimento
                    limiteCompra
                    saldoDisponivel
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CUSTOMERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { clientes } = data
        dispatch({ type: FETCH_CUSTOMERS_FULFILLED, payload: clientes });
        resolve(clientes);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CUSTOMERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllCustomerByName(search = '', first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CUSTOMERS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllCustomersByName {
                  clientesPorNome (search: "%${search}%" first: ${first} offset: ${offset}) {
                    codigo
                    nome
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CUSTOMERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { clientesPorNome } = data
        dispatch({ type: FETCH_CUSTOMERS_FULFILLED, payload: clientesPorNome });
        resolve(clientesPorNome);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CUSTOMERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findCustomer(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CUSTOMER_PENDING });

    api({
      method: 'post',    
      data: {
        query: `
                query findCustomer {
                  cliente (codigo: ${codigo}) {
                    codigo
                    nome
                    nascimento  
                    mae
                    cpf
                    rg
                    endereco
                    numero
                    complemento
                    cep
                    bairro
                    cidade
                    estado
                    status
                    telefone
                    celular
                    email
                    limiteCompra
                    saldoDisponivel
                    ultimaCompra
                    dataCadastro
                    usuarioCadastro
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CUSTOMER_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { cliente } = data
        dispatch({ type: FETCH_CUSTOMER_FULFILLED, payload: cliente });
        resolve(cliente);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CUSTOMER_REJECTED, error });
      resolve(error);
    });

  });

}

export function createCustomer(customer) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CUSTOMER_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createCustomer {
            inclusaoCliente (input: {
              nome: "${customer.nome}"
              nascimento: "${customer.nascimento}"  
              mae: "${customer.mae}"
              cpf: "${customer.cpf}"
              rg: "${customer.rg}"
              endereco: "${customer.endereco}"
              numero: "${customer.numero}"
              complemento: "${customer.complemento}"
              cep: "${customer.cep}"
              bairro: "${customer.bairro}"
              cidade: "${customer.cidade}"
              estado: "${customer.estado}"
              status: 0,
              telefone: "${customer.telefone}"
              celular: "${customer.celular}"
              email: "${customer.email}"
              limiteCompra: ${toFloat(customer.limiteCompra)}
            } ) {
              codigo
              nome
              nascimento  
              mae
              cpf
              rg
              endereco
              numero
              complemento
              cep
              bairro
              cidade
              estado
              status
              telefone
              celular
              email
              limiteCompra
              saldoDisponivel
              ultimaCompra
              dataCadastro
              usuarioCadastro
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CUSTOMER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { inclusaoCliente } = data
        dispatch({ type: CREATE_CUSTOMER_FULFILLED, payload: inclusaoCliente });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CUSTOMER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateCustomer(customer) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_CUSTOMER_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
          mutation UpdateCustomer {
            atualizacaoCliente (codigo: ${customer.codigo} input: {
              nome: "${customer.nome}"
              nascimento: "${customer.nascimento}"  
              mae: "${customer.mae}"
              cpf: "${customer.cpf}"
              rg: "${customer.rg}"
              endereco: "${customer.endereco}"
              numero: "${customer.numero}"
              complemento: "${customer.complemento}"
              cep: "${customer.cep}"
              bairro: "${customer.bairro}"
              cidade: "${customer.cidade}"
              estado: "${customer.estado}"
              status: 0
              telefone: "${customer.telefone}"
              celular: "${customer.celular}"
              email: "${customer.email}"
              limiteCompra: ${toFloat(customer.limiteCompra)}
            } ) {
              codigo
              nome
              nascimento  
              mae
              cpf
              rg
              endereco
              numero
              complemento
              cep
              bairro
              cidade
              estado
              status
              telefone
              celular
              email
              limiteCompra
              saldoDisponivel
              ultimaCompra
              dataCadastro
              usuarioCadastro
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_CUSTOMER_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { atualizacaoCliente } = data
        dispatch({ type: UPDATE_CUSTOMER_FULFILLED, payload: atualizacaoCliente });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_CUSTOMER_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeCustomer(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_CUSTOMER_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveCustomer {
            deleteCustomer (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_CUSTOMER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteCustomer } = data
        dispatch({ type: DELETE_CUSTOMER_FULFILLED, payload: deleteCustomer });
        dispatch(findAllCustomers());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_CUSTOMER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
