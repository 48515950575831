import api from '../../../../api'

export const FETCH_SPECIALTIES_PENDING = 'FETCH_SPECIALTIES_PENDING'
export const FETCH_SPECIALTIES_FULFILLED = 'FETCH_SPECIALTIES_FULFILLED'
export const FETCH_SPECIALTIES_REJECTED = 'FETCH_SPECIALTIES_REJECTED'

export const FETCH_SPECIALTY_PENDING = 'FETCH_SPECIALTY_PENDING'
export const FETCH_SPECIALTY_FULFILLED = 'FETCH_SPECIALTY_FULFILLED'
export const FETCH_SPECIALTY_REJECTED = 'FETCH_SPECIALTY_REJECTED'

export const CREATE_SPECIALTY_PENDING = 'CREATE_SPECIALTY_PENDING'
export const CREATE_SPECIALTY_FULFILLED = 'CREATE_SPECIALTY_FULFILLED'
export const CREATE_SPECIALTY_REJECTED = 'CREATE_SPECIALTY_REJECTED'

export const UPDATE_SPECIALTY_PENDING = 'UPDATE_SPECIALTY_PENDING'
export const UPDATE_SPECIALTY_FULFILLED = 'UPDATE_SPECIALTY_FULFILLED'
export const UPDATE_SPECIALTY_REJECTED = 'UPDATE_SPECIALTY_REJECTED'

export const DELETE_SPECIALTY_PENDING = 'DELETE_SPECIALTY_PENDING'
export const DELETE_SPECIALTY_FULFILLED = 'DELETE_SPECIALTY_FULFILLED'
export const DELETE_SPECIALTY_REJECTED = 'DELETE_SPECIALTY_REJECTED'

export const CHANGE_SPECIALTY = 'CHANGE_SPECIALTY'

export const ADD_SPECIALTY = 'ADD_SPECIALTY'

export function changeSpecialty(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SPECIALTY, payload: { name, value } })
  }
}
 
export function addSpecialty() {
  return dispatch => {
    dispatch({ type: ADD_SPECIALTY });
  };
}

export function findAllSpecialties(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SPECIALTIES_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllSpecialties {
                  especialidades (first: ${first} offset: ${offset}) {
                    codigo
                    nome
                    status
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SPECIALTIES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { especialidades } = data
        dispatch({ type: FETCH_SPECIALTIES_FULFILLED, payload: especialidades });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SPECIALTIES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findSpecialty(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SPECIALTY_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findSpecialty {
                  especialidade (codigo: ${codigo}) {
                    codigo
                    nome
                    status
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SPECIALTY_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { especialidade } = data
        dispatch({ type: FETCH_SPECIALTY_FULFILLED, payload: especialidade });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SPECIALTY_REJECTED, error });
      resolve(error);
    });

  });

}

export function createSpecialty(specialty) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SPECIALTY_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createSpecialty {
            createEspecialidade (input: {
              codigo: ${specialty.codigo}
              nome: "${specialty.nome}"
              status: ${specialty.status}
            } ) 
            {
              codigo
              nome
              status
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SPECIALTY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createEspecialidade } = data
        dispatch({ type: CREATE_SPECIALTY_FULFILLED, payload: createEspecialidade });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SPECIALTY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateSpecialty(specialty) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SPECIALTY_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateSpecialty {
            updateEspecialidade (input: {
              codigo: ${specialty.codigo}
              nome: "${specialty.nome}"
              status: ${specialty.status}
            } ) 
            {
              codigo
              nome
              status
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SPECIALTY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateEspecialidade } = data
        dispatch({ type: CREATE_SPECIALTY_FULFILLED, payload: updateEspecialidade });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SPECIALTY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeSpecialty(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_SPECIALTY_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveSpecialty {
            deleteEspecialidade (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_SPECIALTY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteBanco } = data
        dispatch({ type: DELETE_SPECIALTY_FULFILLED, payload: deleteBanco });
        dispatch(findAllSpecialties());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_SPECIALTY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
