import api from '../../../../api'
import { toFloat } from '../../../../common'

export const FETCH_CASHIERS_OPEN_CLOSE_PENDING = 'FETCH_CASHIERS_OPEN_CLOSE_PENDING'
export const FETCH_CASHIERS_OPEN_CLOSE_FULFILLED = 'FETCH_CASHIERS_OPEN_CLOSE_FULFILLED'
export const FETCH_CASHIERS_OPEN_CLOSE_REJECTED = 'FETCH_CASHIERS_OPEN_CLOSE_REJECTED'

export const FETCH_CASHIER_OPEN_CLOSE_PENDING = 'FETCH_CASHIER_OPEN_CLOSE_PENDING'
export const FETCH_CASHIER_OPEN_CLOSE_FULFILLED = 'FETCH_CASHIER_OPEN_CLOSE_FULFILLED'
export const FETCH_CASHIER_OPEN_CLOSE_REJECTED = 'FETCH_CASHIER_OPEN_CLOSE_REJECTED'

export const CREATE_CASHIER_OPEN_CLOSE_PENDING = 'CREATE_CASHIER_OPEN_CLOSE_PENDING'
export const CREATE_CASHIER_OPEN_CLOSE_FULFILLED = 'CREATE_CASHIER_OPEN_CLOSE_FULFILLED'
export const CREATE_CASHIER_OPEN_CLOSE_REJECTED = 'CREATE_CASHIER_OPEN_CLOSE_REJECTED'

export const UPDATE_CASHIER_OPEN_CLOSE_PENDING = 'UPDATE_CASHIER_OPEN_CLOSE_PENDING'
export const UPDATE_CASHIER_OPEN_CLOSE_FULFILLED = 'UPDATE_CASHIER_OPEN_CLOSE_FULFILLED'
export const UPDATE_CASHIER_OPEN_CLOSE_REJECTED = 'UPDATE_CASHIER_OPEN_CLOSE_REJECTED'

export const DELETE_CASHIER_OPEN_CLOSE_PENDING = 'DELETE_CASHIER_OPEN_CLOSE_PENDING'
export const DELETE_CASHIER_OPEN_CLOSE_FULFILLED = 'DELETE_CASHIER_OPEN_CLOSE_FULFILLED'
export const DELETE_CASHIER_OPEN_CLOSE_REJECTED = 'DELETE_CASHIER_OPEN_CLOSE_REJECTED'

export const CHANGE_CASHIER_OPENING = 'CHANGE_CASHIER_OPENING'

export const ADD_CASHIER_OPENING = 'ADD_CASHIER_OPENING'

export function changeCashierOpenAndClose(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_CASHIER_OPENING, payload: { name, value } })
  }
}
 
export function addCashierOpenAndClose() {
  return dispatch => {
    dispatch({ type: ADD_CASHIER_OPENING });
  };
}

export function findAllCashiersOpenAndClose(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CASHIERS_OPEN_CLOSE_PENDING });

    api({
      method: 'post',    
      data: {
        query: `
                query findAllCashiersOpening {
                  caixasMovimento (first: ${first} offset: ${offset}) {
                    codigo
                    usuario
                    caixa
                    caixaOperado {
                      descricao
                    }
                    dataAbertura
                    horaAbertura
                    saldoInicial  
                    recebimentos
                    pagamentos
                    transferencias
                    saldoFinal
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CASHIERS_OPEN_CLOSE_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { caixasMovimento } = data
        dispatch({ type: FETCH_CASHIERS_OPEN_CLOSE_FULFILLED, payload: caixasMovimento });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CASHIERS_OPEN_CLOSE_REJECTED, error });
      resolve(error);
    });

  });

}

export function findCashierOpenAndClose(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CASHIER_OPEN_CLOSE_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
                query findCashierOpening {
                  caixaMovimento (codigo: ${codigo}) {
                    codigo
                    usuario
                    terminal
                    dataAbetura
                    horaAbertura
                    caixa
                    contaGerencial
                    saldoInicial  
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CASHIER_OPEN_CLOSE_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { caixa } = data
        dispatch({ type: FETCH_CASHIER_OPEN_CLOSE_FULFILLED, payload: caixa });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CASHIER_OPEN_CLOSE_REJECTED, error });
      resolve(error);
    });

  });

}

export function createCashierOpenAndClose(cashierOpening) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CASHIER_OPEN_CLOSE_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
          mutation createCashierOpening {
            createCaixaMovimento (input: {
              codigo: ${cashierOpening.codigo}
              usuario: ${cashierOpening.usuario}
              caixa: ${cashierOpening.caixa}
              dataAbertura: "${cashierOpening.dataAbertura}"
              horaAbertura: "${cashierOpening.horaAbertura}"
              saldoInicial: ${toFloat(cashierOpening.saldoInicial)}
              saldoFinal: ${toFloat(cashierOpening.saldoInicial)}  
            }) 
            {
              codigo
              usuario
              caixa
              dataAbertura
              horaAbertura
              saldoInicial  
              saldoFinal
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CASHIER_OPEN_CLOSE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createCaixaMovimento } = data
        dispatch({ type: CREATE_CASHIER_OPEN_CLOSE_FULFILLED, payload: createCaixaMovimento });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CASHIER_OPEN_CLOSE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateCashierOpenAndClose(cashierOpening) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_CASHIER_OPEN_CLOSE_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation updateCashierOpening {
            updateCaixaMovimento (input: {
              codigo: ${cashierOpening.codigo}
              usuario: ${cashierOpening.usuario}
              caixa: ${cashierOpening.caixa}
              dataAbertura: "${cashierOpening.dataAbertura}"
              horaAbertura: "${cashierOpening.horaAbertura}"
              saldoInicial: ${toFloat(cashierOpening.saldoInicial)}
              saldoFinal: ${toFloat(cashierOpening.saldoInicial)} 
            } ) 
            {
              codigo
              usuario
              caixa
              dataAbertura
              horaAbertura
              saldoInicial  
              saldoFinal 
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_CASHIER_OPEN_CLOSE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateCaixaMovimento } = data
        dispatch({ type: CREATE_CASHIER_OPEN_CLOSE_FULFILLED, payload: updateCaixaMovimento });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_CASHIER_OPEN_CLOSE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeCashierOpenAndClose(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_CASHIER_OPEN_CLOSE_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation RemoveCashierOpening {
            deleteCaixaMovimento (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_CASHIER_OPEN_CLOSE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteCaixa } = data
        dispatch({ type: DELETE_CASHIER_OPEN_CLOSE_FULFILLED, payload: deleteCaixa });
        dispatch(findAllCashiersOpenAndClose());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_CASHIER_OPEN_CLOSE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
