import {

    FETCH_CUSTOMERS_PENDING,
    FETCH_CUSTOMERS_FULFILLED,
    FETCH_CUSTOMERS_REJECTED,

    FETCH_CUSTOMER_PENDING,
    FETCH_CUSTOMER_FULFILLED,
    FETCH_CUSTOMER_REJECTED,

    CREATE_CUSTOMER_PENDING,
    CREATE_CUSTOMER_FULFILLED,
    CREATE_CUSTOMER_REJECTED,

    UPDATE_CUSTOMER_PENDING,
    UPDATE_CUSTOMER_FULFILLED,
    UPDATE_CUSTOMER_REJECTED,

    DELETE_CUSTOMER_PENDING,
    DELETE_CUSTOMER_FULFILLED,
    DELETE_CUSTOMER_REJECTED,
    
    CHANGE_CUSTOMER,
    
    ADD_CUSTOMER

} from './customerActions'

import { titleCase } from "../../../../common/utils";

const initialState = {
    loading: false,
    listOfCustomers: [],
    customer: {
        codigo: 0,
        nome: '',
        nascimento: '',  
        mae: '',
        cpf: '',
        rg: '',
        endereco: '',
        numero: '',
        complemento: '',
        cep: '',
        bairro: '',
        cidade: '',
        estado: '',
        status: '',
        telefone: '',
        celular: '',
        email: '',
        limiteCompra: 0.0,
        saldoDisponivel: 0.0,
        ultimaCompra: '',
        dataCadastro: '',
        usuarioCadastro: 0
    },
    error: null,
}

export function customerReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_CUSTOMER: 
            let value = ""
            
            if (action.payload.name.includes('email')) {
                value = action.payload.value.toLowerCase();
            } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                customer: { ...state.customer, 
                    [action.payload.name]: value
                }
            })
        case ADD_CUSTOMER: 
            return Object.assign({}, state, {
                customer: { ...initialState.customer }
            })
        case FETCH_CUSTOMERS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfCustomers: [],
            })
        case FETCH_CUSTOMERS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfCustomers: action.payload
            })
        case FETCH_CUSTOMERS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_CUSTOMER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case CREATE_CUSTOMER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                customer: action.payload
            })
        case CREATE_CUSTOMER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_CUSTOMER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_CUSTOMER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                customer: action.payload
            })
        case UPDATE_CUSTOMER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_CUSTOMER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_CUSTOMER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                customer: action.payload
            })
        case FETCH_CUSTOMER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_CUSTOMER_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_CUSTOMER_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_CUSTOMER_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}