import api from '../../../../api'

export const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING'
export const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED'
export const FETCH_USERS_REJECTED = 'FETCH_USERS_REJECTED'

export const FETCH_USER_PENDING = 'FETCH_USER_PENDING'
export const FETCH_USER_FULFILLED = 'FETCH_USER_FULFILLED'
export const FETCH_USER_REJECTED = 'FETCH_USER_REJECTED'

export const CREATE_USER_PENDING = 'CREATE_USER_PENDING'
export const CREATE_USER_FULFILLED = 'CREATE_USER_FULFILLED'
export const CREATE_USER_REJECTED = 'CREATE_USER_REJECTED'

export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING'
export const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED'
export const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED'

export const DELETE_USER_PENDING = 'DELETE_USER_PENDING'
export const DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED'
export const DELETE_USER_REJECTED = 'DELETE_USER_REJECTED'

export const CHANGE_USER = 'CHANGE_USER'

export const ADD_USER = 'ADD_USER'

export function changeUser(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_USER, payload: { name, value } })
  }
}
 
export function addUser() {
  return dispatch => {
    dispatch({ type: ADD_USER });
  };
}

export function findAllUsers(first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_USERS_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllUsers {
                  usuarios (first: ${first} offset: ${offset}) {
                    codigo
                    nome
                    login
                    validade
                    email
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_USERS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { usuarios } = data
        dispatch({ type: FETCH_USERS_FULFILLED, payload: usuarios });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_USERS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findUser(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_USER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                    query findUser {
                      usuario (codigo: ${codigo}) {
                        codigo
                        perfil
                        nome
                        status
                        celular
                        validade
                        login
                        email
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_USER_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { usuario } = data
        dispatch({ type: FETCH_USER_FULFILLED, payload: usuario });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_USER_REJECTED, error });
      resolve(error);
    });

  });

}

export function createUser(usuario) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_USER_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createUser {
            createUsuario (input: {
              codigo: ${usuario.codigo}
              perfil: ${usuario.perfil}
              nome: "${usuario.nome}"
              status: ${usuario.status}
              celular: "${usuario.celular}"
              validade: "${usuario.validade}"
              login: "${usuario.login}"
              senha: "${usuario.senha}"
              email: "${usuario.email}"
            } ) {
              codigo
              perfil
              nome
              status
              celular
              validade
              login
              email
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_USER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createUsuario } = data
        dispatch({ type: CREATE_USER_FULFILLED, payload: createUsuario });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_USER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateUser(usuario) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_USER_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
          mutation UpdateUser {
            updateUsuario (input: {
              codigo: ${usuario.codigo}
              perfil: ${usuario.perfil}
              nome: "${usuario.nome}"
              status: ${usuario.status}
              celular: "${usuario.celular}"
              validade: "${usuario.validade}"
              login: "${usuario.login}"
              senha: "${usuario.senha}"
              email: "${usuario.email}"
            } ) {
              codigo
              perfil
              nome
              status
              celular
              validade
              login
              email
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_USER_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateUsuario } = data
        dispatch({ type: UPDATE_USER_FULFILLED, payload: updateUsuario });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_USER_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
} 

export function removeUser(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_USER_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveUser {
            deleteUsuario (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_USER_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteUsuario } = data
        dispatch({ type: DELETE_USER_FULFILLED, payload: deleteUsuario });
        dispatch(findAllUsers());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_USER_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
