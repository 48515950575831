import {

    FETCH_PAYMENT_METHODS_PENDING,
    FETCH_PAYMENT_METHODS_FULFILLED,
    FETCH_PAYMENT_METHODS_REJECTED,

    FETCH_PAYMENT_METHOD_PENDING,
    FETCH_PAYMENT_METHOD_FULFILLED,
    FETCH_PAYMENT_METHOD_REJECTED,

    CREATE_PAYMENT_METHOD_PENDING,
    CREATE_PAYMENT_METHOD_FULFILLED,
    CREATE_PAYMENT_METHOD_REJECTED,

    UPDATE_PAYMENT_METHOD_PENDING,
    UPDATE_PAYMENT_METHOD_FULFILLED,
    UPDATE_PAYMENT_METHOD_REJECTED,

    DELETE_PAYMENT_METHOD_PENDING,
    DELETE_PAYMENT_METHOD_FULFILLED,
    DELETE_PAYMENT_METHOD_REJECTED,
    
    CHANGE_PAYMENT_METHOD,
    
    ADD_PAYMENT_METHOD

} from './paymentMethodsActions'

import { titleCase } from '../../../../common/utils'

const moment = require('moment')

const initialState = {
    loading: false,
    listOfPayment: [],
    paymentMethod: {
        codigo: 0,
        status: 0,
        descricao: '',
        tipo: 0,
        maximoParcelas: 1,
        primeiraParcela: 30,
        intervaloDias: 30,
        taxaOperadora: 0.0,
        jurosMora: 0.0,
        jurosMulta: 0.0
    },
    error: null,
}

export function paymentMethodsReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PAYMENT_METHOD:
            return Object.assign({}, state, {
                paymentMethod: { ...state.paymentMethod, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_PAYMENT_METHOD: 
            return Object.assign({}, state, {
                paymentMethod: { ...initialState.paymentMethod }
            })
        case FETCH_PAYMENT_METHODS_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfPayment: [],
            })
        case FETCH_PAYMENT_METHODS_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfPayment: action.payload
            })
        case FETCH_PAYMENT_METHODS_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_PAYMENT_METHOD_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //payment: { ...initialState.payment },
            })
        case CREATE_PAYMENT_METHOD_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                paymentMethod: action.payload
            })
        case CREATE_PAYMENT_METHOD_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_PAYMENT_METHOD_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_PAYMENT_METHOD_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                paymentMethod: action.payload
            })
        case UPDATE_PAYMENT_METHOD_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_PAYMENT_METHOD_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_PAYMENT_METHOD_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                paymentMethod: action.payload
            })
        case FETCH_PAYMENT_METHOD_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_PAYMENT_METHOD_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_PAYMENT_METHOD_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_PAYMENT_METHOD_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}