import api from '../../../../api'

export const FETCH_HEALTH_PLANS_PENDING = 'FETCH_HEALTH_PLANS_PENDING'
export const FETCH_HEALTH_PLANS_FULFILLED = 'FETCH_HEALTH_PLANS_FULFILLED'
export const FETCH_HEALTH_PLANS_REJECTED = 'FETCH_HEALTH_PLANS_REJECTED'

export const FETCH_HEALTH_PLAN_PENDING = 'FETCH_HEALTH_PLAN_PENDING'
export const FETCH_HEALTH_PLAN_FULFILLED = 'FETCH_HEALTH_PLAN_FULFILLED'
export const FETCH_HEALTH_PLAN_REJECTED = 'FETCH_HEALTH_PLAN_REJECTED'

export const CREATE_HEALTH_PLAN_PENDING = 'CREATE_HEALTH_PLAN_PENDING'
export const CREATE_HEALTH_PLAN_FULFILLED = 'CREATE_HEALTH_PLAN_FULFILLED'
export const CREATE_HEALTH_PLAN_REJECTED = 'CREATE_HEALTH_PLAN_REJECTED'

export const UPDATE_HEALTH_PLAN_PENDING = 'UPDATE_HEALTH_PLAN_PENDING'
export const UPDATE_HEALTH_PLAN_FULFILLED = 'UPDATE_HEALTH_PLAN_FULFILLED'
export const UPDATE_HEALTH_PLAN_REJECTED = 'UPDATE_HEALTH_PLAN_REJECTED'

export const DELETE_HEALTH_PLAN_PENDING = 'DELETE_HEALTH_PLAN_PENDING'
export const DELETE_HEALTH_PLAN_FULFILLED = 'DELETE_HEALTH_PLAN_FULFILLED'
export const DELETE_HEALTH_PLAN_REJECTED = 'DELETE_HEALTH_PLAN_REJECTED'

export const CHANGE_HEALTH_PLAN = 'CHANGE_HEALTH_PLAN'

export const ADD_HEALTH_PLAN = 'ADD_HEALTH_PLAN'

export function changeHealthPlan(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_HEALTH_PLAN, payload: { name, value } })
  }
}
 
export function addHealthPlan() {
  return dispatch => {
    dispatch({ type: ADD_HEALTH_PLAN });
  };
}

export function findAllHealthPlans(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_HEALTH_PLANS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllHealthPlans {
                  planosSaude (first: ${first} offset: ${offset}) {
                    codigo
                    cnpj
                    status
                    descricao
                    tabela
                    registroAns
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_HEALTH_PLANS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { planosSaude } = data
        dispatch({ type: FETCH_HEALTH_PLANS_FULFILLED, payload: planosSaude });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_HEALTH_PLANS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findHealthPlan(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_HEALTH_PLAN_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findHealthPlan {
                  planoSaude (codigo: ${codigo}) {
                    codigo
                    cnpj
                    status
                    descricao
                    tabela
                    registroAns
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_HEALTH_PLAN_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { planoSaude } = data
        dispatch({ type: FETCH_HEALTH_PLAN_FULFILLED, payload: planoSaude });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_HEALTH_PLAN_REJECTED, error });
      resolve(error);
    });

  });

}

export function createHealthPlan(healthPlan) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_HEALTH_PLAN_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation createHealthPlan {
            createPlanoSaude (input: {
              codigo: ${healthPlan.codigo}
              cnpj: "${healthPlan.cnpj}"
              status: ${healthPlan.status}
              descricao: "${healthPlan.descricao}"
              tabela: ${healthPlan.tabela}
              registroAns: "${healthPlan.registroAns}"
            } ) 
            {
              codigo
              cnpj
              status
              descricao
              tabela
              registroAns
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_HEALTH_PLAN_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createPlanoSaude } = data
        dispatch({ type: CREATE_HEALTH_PLAN_FULFILLED, payload: createPlanoSaude });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_HEALTH_PLAN_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateHealthPlan(healthPlan) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_HEALTH_PLAN_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateHealthPlan {
            updatePlanoSaude (input: {
              codigo: ${healthPlan.codigo}
              cnpj: "${healthPlan.cnpj}"
              status: ${healthPlan.status}
              descricao: "${healthPlan.descricao}"
              tabela: ${healthPlan.tabela}
              registroAns: "${healthPlan.registroAns}"
            } ) 
            {
              codigo
              cnpj
              status
              descricao
              tabela
              registroAns
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_HEALTH_PLAN_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updatePlanoSaude } = data
        dispatch({ type: CREATE_HEALTH_PLAN_FULFILLED, payload: updatePlanoSaude });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_HEALTH_PLAN_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeHealthPlan(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_HEALTH_PLAN_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveHealthPlan {
            deletePlanoSaude (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_HEALTH_PLAN_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deletePlanoSaude } = data
        dispatch({ type: DELETE_HEALTH_PLAN_FULFILLED, payload: deletePlanoSaude });
        dispatch(findAllHealthPlans());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_HEALTH_PLAN_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
