import api from '../../../../api'

export const FETCH_PROFESSIONS_PENDING = 'FETCH_PROFESSIONS_PENDING'
export const FETCH_PROFESSIONS_FULFILLED = 'FETCH_PROFESSIONS_FULFILLED'
export const FETCH_PROFESSIONS_REJECTED = 'FETCH_PROFESSIONS_REJECTED'

export const FETCH_PROFESSION_PENDING = 'FETCH_PROFESSION_PENDING'
export const FETCH_PROFESSION_FULFILLED = 'FETCH_PROFESSION_FULFILLED'
export const FETCH_PROFESSION_REJECTED = 'FETCH_PROFESSION_REJECTED'

export const CREATE_PROFESSION_PENDING = 'CREATE_PROFESSION_PENDING'
export const CREATE_PROFESSION_FULFILLED = 'CREATE_PROFESSION_FULFILLED'
export const CREATE_PROFESSION_REJECTED = 'CREATE_PROFESSION_REJECTED'

export const UPDATE_PROFESSION_PENDING = 'UPDATE_PROFESSION_PENDING'
export const UPDATE_PROFESSION_FULFILLED = 'UPDATE_PROFESSION_FULFILLED'
export const UPDATE_PROFESSION_REJECTED = 'UPDATE_PROFESSION_REJECTED'

export const DELETE_PROFESSION_PENDING = 'DELETE_PROFESSION_PENDING'
export const DELETE_PROFESSION_FULFILLED = 'DELETE_PROFESSION_FULFILLED'
export const DELETE_PROFESSION_REJECTED = 'DELETE_PROFESSION_REJECTED'

export const CHANGE_PROFESSION = 'CHANGE_PROFESSION'

export const ADD_PROFESSION = 'ADD_PROFESSION'

export function changeProfession(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_PROFESSION, payload: { name, value } })
  }
}
 
export function addProfession() {
  return dispatch => {
    dispatch({ type: ADD_PROFESSION });
  };
}

export function findAllProfessions(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PROFESSIONS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllProfessions {
                  profissoes (first: ${first} offset: ${offset}) {
                    codigo
                    cbo
                    nome
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PROFESSIONS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { profissoes } = data
        dispatch({ type: FETCH_PROFESSIONS_FULFILLED, payload: profissoes });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PROFESSIONS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findProfession(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PROFESSION_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findProfession {
                  profissao (codigo: ${codigo}) {
                    codigo
                    cbo
                    nome
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PROFESSION_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { profissao } = data
        dispatch({ type: FETCH_PROFESSION_FULFILLED, payload: profissao });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PROFESSION_REJECTED, error });
      resolve(error);
    });

  });

}

export function createProfession(profession) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PROFESSION_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createProfession {
            createProfissao (input: {
              codigo: ${profession.codigo}
              cbo: ${profession.cbo}
              nome: "${profession.nome}"
            } ) 
            {
              codigo
              cbo
              nome
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PROFESSION_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createProfissao } = data
        dispatch({ type: CREATE_PROFESSION_FULFILLED, payload: createProfissao });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PROFESSION_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateProfession(profession) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PROFESSION_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation UpdateProfession {
            updateProfissao (input: {
              codigo: ${profession.codigo}
              cbo: ${profession.cbo}
              nome: "${profession.nome}"
            } ) 
            {
              codigo
              cbo
              nome
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PROFESSION_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateProfissao } = data
        dispatch({ type: CREATE_PROFESSION_FULFILLED, payload: updateProfissao });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PROFESSION_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeProfession(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_PROFESSION_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation RemoveProfession {
            deleteProfissao (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_PROFESSION_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteBanco } = data
        dispatch({ type: DELETE_PROFESSION_FULFILLED, payload: deleteBanco });
        dispatch(findAllProfessions());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_PROFESSION_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
