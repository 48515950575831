import api from '../../../../../api'
import { toFloat } from '../../../../../common/utils'

export const FETCH_PLAN_MODALITIES_PENDING = 'FETCH_PLAN_MODALITIES_PENDING'
export const FETCH_PLAN_MODALITIES_FULFILLED = 'FETCH_PLAN_MODALITIES_FULFILLED'
export const FETCH_PLAN_MODALITIES_REJECTED = 'FETCH_PLAN_MODALITIES_REJECTED'

export const FETCH_PLAN_MODALITY_PENDING = 'FETCH_PLAN_MODALITY_PENDING'
export const FETCH_PLAN_MODALITY_FULFILLED = 'FETCH_PLAN_MODALITY_FULFILLED'
export const FETCH_PLAN_MODALITY_REJECTED = 'FETCH_PLAN_MODALITY_REJECTED'

export const CREATE_PLAN_MODALITY_PENDING = 'CREATE_PLAN_MODALITY_PENDING'
export const CREATE_PLAN_MODALITY_FULFILLED = 'CREATE_PLAN_MODALITY_FULFILLED'
export const CREATE_PLAN_MODALITY_REJECTED = 'CREATE_PLAN_MODALITY_REJECTED'

export const UPDATE_PLAN_MODALITY_PENDING = 'UPDATE_PLAN_MODALITY_PENDING'
export const UPDATE_PLAN_MODALITY_FULFILLED = 'UPDATE_PLAN_MODALITY_FULFILLED'
export const UPDATE_PLAN_MODALITY_REJECTED = 'UPDATE_PLAN_MODALITY_REJECTED'

export const DELETE_PLAN_MODALITY_PENDING = 'DELETE_PLAN_MODALITY_PENDING'
export const DELETE_PLAN_MODALITY_FULFILLED = 'DELETE_PLAN_MODALITY_FULFILLED'
export const DELETE_PLAN_MODALITY_REJECTED = 'DELETE_PLAN_MODALITY_REJECTED'

export const CHANGE_PLAN_MODALITY = 'CHANGE_PLAN_MODALITY'

export const ADD_PLAN_MODALITY = 'ADD_PLAN_MODALITY'

export function changePlanModality(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_PLAN_MODALITY, payload: { name, value } })
  }
}
 
export function addPlanModality() {
  return dispatch => {
    dispatch({ type: ADD_PLAN_MODALITY });
  };
}

export function findAllPlanModalities(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PLAN_MODALITIES_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllPlanModalities {
                  modalidades (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    descricao
                    cobertura
                    valor
                    plano
                    planoSaude {
                      codigo
                      descricao
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PLAN_MODALITIES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { modalidades } = data
        dispatch({ type: FETCH_PLAN_MODALITIES_FULFILLED, payload: modalidades });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PLAN_MODALITIES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllPlanModalitiesByHealthPlan(healthPlan) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PLAN_MODALITIES_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllPlanModalities {
                  modalidadesPorPlano (plano: ${healthPlan}) {
                    codigo
                    descricao
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PLAN_MODALITIES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { modalidadesPorPlano } = data
        dispatch({ type: FETCH_PLAN_MODALITIES_FULFILLED, payload: modalidadesPorPlano });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PLAN_MODALITIES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findPlanModality(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_PLAN_MODALITY_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findPlanModality {
                  modalidade (codigo: ${codigo}) {
                    codigo
                    status
                    descricao
                    cobertura
                    valor
                    plano
                    planoSaude {
                      codigo
                      descricao
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_PLAN_MODALITY_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { modalidade } = data
        dispatch({ type: FETCH_PLAN_MODALITY_FULFILLED, payload: modalidade });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_PLAN_MODALITY_REJECTED, error });
      resolve(error);
    });

  });

}

export function createPlanModality(planModality) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PLAN_MODALITY_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createPlanModality {
            createModalidade (input: {
              codigo: ${planModality.codigo}
              status: ${planModality.status}
              descricao: "${planModality.descricao}"
              cobertura: ${planModality.cobertura}
              valor: ${toFloat(planModality.valor)}
              plano: ${planModality.plano}
            } ) 
            {
              codigo
              status
              descricao
              cobertura
              valor
              plano
              planoSaude {
                codigo
                descricao
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PLAN_MODALITY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createModalidade } = data
        dispatch({ type: CREATE_PLAN_MODALITY_FULFILLED, payload: createModalidade });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PLAN_MODALITY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updatePlanModality(planModality) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_PLAN_MODALITY_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation UpdateHealthPlan {
            updateModalidade (input: {
              codigo: ${planModality.codigo}
              status: ${planModality.status}
              descricao: "${planModality.descricao}"
              cobertura: ${planModality.cobertura}
              valor: ${toFloat(planModality.valor)}
              plano: ${planModality.plano}
            } ) 
            {
              codigo
              status
              descricao
              cobertura
              valor
              plano
              planoSaude {
                codigo
                descricao
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_PLAN_MODALITY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateModalidade } = data
        dispatch({ type: CREATE_PLAN_MODALITY_FULFILLED, payload: updateModalidade });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_PLAN_MODALITY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removePlanModality(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_PLAN_MODALITY_PENDING });

    api({
      method: 'post',     
      data: {
        query: `
          mutation RemovePlanModality {
            deleteModalidade (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_PLAN_MODALITY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteModalidade } = data
        dispatch({ type: DELETE_PLAN_MODALITY_FULFILLED, payload: deleteModalidade });
        dispatch(findAllPlanModalities());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_PLAN_MODALITY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
