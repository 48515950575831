import api from '../../../../../api'

export const FETCH_NCMS_PRODUCT_PENDING = 'FETCH_NCMS_PRODUCT_PENDING'
export const FETCH_NCMS_PRODUCT_FULFILLED = 'FETCH_NCMS_PRODUCT_FULFILLED'
export const FETCH_NCMS_PRODUCT_REJECTED = 'FETCH_NCMS_PRODUCT_REJECTED'

export const FETCH_NCM_PRODUCT_PENDING = 'FETCH_NCM_PRODUCT_PENDING'
export const FETCH_NCM_PRODUCT_FULFILLED = 'FETCH_NCM_PRODUCT_FULFILLED'
export const FETCH_NCM_PRODUCT_REJECTED = 'FETCH_NCM_PRODUCT_REJECTED'

export const CREATE_NCM_PRODUCT_PENDING = 'CREATE_NCM_PRODUCT_PENDING'
export const CREATE_NCM_PRODUCT_FULFILLED = 'CREATE_NCM_PRODUCT_FULFILLED'
export const CREATE_NCM_PRODUCT_REJECTED = 'CREATE_NCM_PRODUCT_REJECTED'

export const UPDATE_NCM_PRODUCT_PENDING = 'UPDATE_NCM_PRODUCT_PENDING'
export const UPDATE_NCM_PRODUCT_FULFILLED = 'UPDATE_NCM_PRODUCT_FULFILLED'
export const UPDATE_NCM_PRODUCT_REJECTED = 'UPDATE_NCM_PRODUCT_REJECTED'

export const DELETE_NCM_PRODUCT_PENDING = 'DELETE_NCM_PRODUCT_PENDING'
export const DELETE_NCM_PRODUCT_FULFILLED = 'DELETE_NCM_PRODUCT_FULFILLED'
export const DELETE_NCM_PRODUCT_REJECTED = 'DELETE_NCM_PRODUCT_REJECTED'

export const CHANGE_NCM_PRODUCT = 'CHANGE_NCM_PRODUCT'

export const ADD_NCM_PRODUCT = 'ADD_NCM_PRODUCT'

export function changeNcm(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_NCM_PRODUCT, payload: { name, value } })
  }
}
 
export function addNcm() {
  return dispatch => {
    dispatch({ type: ADD_NCM_PRODUCT });
  };
}

export function findAllNcm(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_NCMS_PRODUCT_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllNcm {
                  ncms (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    numero
                    descricao
                    vinculado
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_NCMS_PRODUCT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { ncms } = data
        dispatch({ type: FETCH_NCMS_PRODUCT_FULFILLED, payload: ncms });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_NCMS_PRODUCT_REJECTED, error });
      resolve(error);
    });

  });

}

export function findNcm(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_NCM_PRODUCT_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findNcm {
                  ncm (codigo: ${codigo}) {
                    codigo
                    status
                    numero
                    descricao
                    vinculado
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_NCM_PRODUCT_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { ncm } = data
        dispatch({ type: FETCH_NCM_PRODUCT_FULFILLED, payload: ncm });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_NCM_PRODUCT_REJECTED, error });
      resolve(error);
    });

  });

}

export function createNcm(ncm) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_NCM_PRODUCT_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation createNcm {
            createNcm (input: {
              codigo: ${ncm.codigo}
              status: ${ncm.status}
              numero: ${ncm.numero}
              descricao: "${ncm.descricao}"
              vinculado: ${ncm.vinculado}
      } ) 
            {
              codigo
              status
              numero
              descricao
              vinculado
      }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_NCM_PRODUCT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createNcm } = data
        dispatch({ type: CREATE_NCM_PRODUCT_FULFILLED, payload: createNcm });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_NCM_PRODUCT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateNcm(ncm) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_NCM_PRODUCT_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateNcm {
            updateNcm (input: {
              codigo: ${ncm.codigo}
              status: ${ncm.status}
              numero: ${ncm.numero}
              descricao: ${ncm.descricao}
              vinculado: ${ncm.vinculado}
            } ) 
            {
              codigo
              status
              numero
              descricao
              vinculado
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_NCM_PRODUCT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateNcm } = data
        dispatch({ type: CREATE_NCM_PRODUCT_FULFILLED, payload: updateNcm });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_NCM_PRODUCT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeNcm(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_NCM_PRODUCT_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveNcm {
            deleteNcm (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_NCM_PRODUCT_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteNcm } = data
        dispatch({ type: DELETE_NCM_PRODUCT_FULFILLED, payload: deleteNcm });
        dispatch(findAllNcm());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_NCM_PRODUCT_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
