import api from '../../../../api'

export const FETCH_SALES_PEOPLES_PENDING = 'FETCH_SALES_PEOPLES_PENDING'
export const FETCH_SALES_PEOPLES_FULFILLED = 'FETCH_SALES_PEOPLES_FULFILLED'
export const FETCH_SALES_PEOPLES_REJECTED = 'FETCH_SALES_PEOPLES_REJECTED'

export const FETCH_SALES_PEOPLE_PENDING = 'FETCH_SALES_PEOPLE_PENDING'
export const FETCH_SALES_PEOPLE_FULFILLED = 'FETCH_SALES_PEOPLE_FULFILLED'
export const FETCH_SALES_PEOPLE_REJECTED = 'FETCH_SALES_PEOPLE_REJECTED'

export const CREATE_SALES_PEOPLE_PENDING = 'CREATE_SALES_PEOPLE_PENDING'
export const CREATE_SALES_PEOPLE_FULFILLED = 'CREATE_SALES_PEOPLE_FULFILLED'
export const CREATE_SALES_PEOPLE_REJECTED = 'CREATE_SALES_PEOPLE_REJECTED'

export const UPDATE_SALES_PEOPLE_PENDING = 'UPDATE_SALES_PEOPLE_PENDING'
export const UPDATE_SALES_PEOPLE_FULFILLED = 'UPDATE_SALES_PEOPLE_FULFILLED'
export const UPDATE_SALES_PEOPLE_REJECTED = 'UPDATE_SALES_PEOPLE_REJECTED'

export const DELETE_SALES_PEOPLE_PENDING = 'DELETE_SALES_PEOPLE_PENDING'
export const DELETE_SALES_PEOPLE_FULFILLED = 'DELETE_SALES_PEOPLE_FULFILLED'
export const DELETE_SALES_PEOPLE_REJECTED = 'DELETE_SALES_PEOPLE_REJECTED'

export const CHANGE_SALES_PEOPLE = 'CHANGE_SALES_PEOPLE'

export const ADD_SALES_PEOPLE = 'ADD_SALES_PEOPLE'

export function changeSalesPeople(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SALES_PEOPLE, payload: { name, value } })
  }
}

export function addSalesPeople() {
  return dispatch => {
    dispatch({ type: ADD_SALES_PEOPLE });
  };
}

export function findAllSalesPeoples(first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SALES_PEOPLES_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
                query findAllSalesPeoples {
                  vendedores (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    cpfCnpj
                    razaoSocial
                    nomeFantasia
                    celular
                    fixo
                    ramal
                    email
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SALES_PEOPLES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { vendedores } = data
        dispatch({ type: FETCH_SALES_PEOPLES_FULFILLED, payload: vendedores });
        resolve(vendedores);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SALES_PEOPLES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findSalesPeople(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SALES_PEOPLE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findSalesPeople {
                  vendedor (codigo: ${codigo}) {
                    codigo
                    status
                    tipoVendedor
                    cpfCnpj
                    razaoSocial
                    nomeFantasia
                    numeroCNAE
                    numeroIE
                    numeroIM
                    tipoRegime
                    tipoEndereco
                    endereco
                    numero
                    complemento
                    cep
                    bairro
                    estado
                    municipio
                    celular
                    fixo
                    ramal
                    responsavel
                    email
                    homePage
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SALES_PEOPLE_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { vendedor } = data
        dispatch({ type: FETCH_SALES_PEOPLE_FULFILLED, payload: vendedor });
        resolve(vendedor);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SALES_PEOPLE_REJECTED, error });
      resolve(error);
    });

  });

}

export function createSalesPeople(salesPeople) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SALES_PEOPLE_PENDING });
 
    api({
      method: 'post',       
      data: {
        query: `
          mutation createSalesPeople {
            createVendedor (input: {
              codigo: ${salesPeople.codigo}
              status: ${salesPeople.status}
              tipoVendedor: "${salesPeople.tipoVendedor}"
              cpfCnpj: "${salesPeople.cpfCnpj}"
              razaoSocial: "${salesPeople.razaoSocial}"
              nomeFantasia: "${salesPeople.nomeFantasia}"
              numeroCNAE: "${salesPeople.numeroCNAE}"
              numeroIE: "${salesPeople.numeroIE}"
              numeroIM: "${salesPeople.numeroIM}"
              tipoRegime: ${salesPeople.tipoRegime}
              tipoEndereco: "${salesPeople.tipoEndereco}"
              endereco: "${salesPeople.endereco}"
              numero: "${salesPeople.numero}"
              complemento: "${salesPeople.complemento}"
              cep: "${salesPeople.cep}"
              bairro: "${salesPeople.bairro}"
              estado: ${salesPeople.estado}
              municipio: ${salesPeople.municipio}
              celular: "${salesPeople.celular}"
              fixo: "${salesPeople.fixo}"
              ramal: "${salesPeople.ramal}"
              responsavel: "${salesPeople.responsavel}"
              email: "${salesPeople.email}"
              homePage: "${salesPeople.homePage}"
            } ) {
              codigo
              status
              tipoVendedor
              cpfCnpj
              razaoSocial
              nomeFantasia
              numeroCNAE
              numeroIE
              numeroIM
              tipoRegime
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              fixo
              ramal
              responsavel
              email
              homePage
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SALES_PEOPLE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createVendedor } = data
        dispatch({ type: CREATE_SALES_PEOPLE_FULFILLED, payload: createVendedor });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SALES_PEOPLE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateSalesPeople(salesPeople) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_SALES_PEOPLE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation UpdateSalesPeople {
            updateVendedor (input: {
              codigo: ${salesPeople.codigo}
              status: ${salesPeople.status}
              tipoVendedor: "${salesPeople.tipoVendedor}"
              cpfCnpj: "${salesPeople.cpfCnpj}"
              razaoSocial: "${salesPeople.razaoSocial}"
              nomeFantasia: "${salesPeople.nomeFantasia}"
              numeroCNAE: "${salesPeople.numeroCNAE}"
              numeroIE: "${salesPeople.numeroIE}"
              numeroIM: "${salesPeople.numeroIM}"
              tipoRegime: ${salesPeople.tipoRegime}
              tipoEndereco: "${salesPeople.tipoEndereco}"
              endereco: "${salesPeople.endereco}"
              numero: "${salesPeople.numero}"
              complemento: "${salesPeople.complemento}"
              cep: "${salesPeople.cep}"
              bairro: "${salesPeople.bairro}"
              estado: ${salesPeople.estado}
              municipio: ${salesPeople.municipio}
              celular: "${salesPeople.celular}"
              fixo: "${salesPeople.fixo}"
              ramal: "${salesPeople.ramal}"
              responsavel: "${salesPeople.responsavel}"
              email: "${salesPeople.email}"
              homePage: "${salesPeople.homePage}"
            } ) {
              codigo
              status
              tipoVendedor
              cpfCnpj
              razaoSocial
              nomeFantasia
              numeroCNAE
              numeroIE
              numeroIM
              tipoRegime
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              fixo
              ramal
              responsavel
              email
              homePage
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_SALES_PEOPLE_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateVendedor } = data
        dispatch({ type: UPDATE_SALES_PEOPLE_FULFILLED, payload: updateVendedor });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_SALES_PEOPLE_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeSalesPeople(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_SALES_PEOPLE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation RemoveSalesPeople {
            deleteVendedor (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_SALES_PEOPLE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteVendedor } = data
        dispatch({ type: DELETE_SALES_PEOPLE_FULFILLED, payload: deleteVendedor });
        dispatch(findAllSalesPeoples());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_SALES_PEOPLE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
