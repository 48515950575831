import api from '../../../../api'
import { toFloat } from '../../../../common/utils'

export const FETCH_ACCREDITEDS_PENDING = 'FETCH_ACCREDITEDS_PENDING'
export const FETCH_ACCREDITEDS_FULFILLED = 'FETCH_ACCREDITEDS_FULFILLED'
export const FETCH_ACCREDITEDS_REJECTED = 'FETCH_ACCREDITEDS_REJECTED'

export const FETCH_ACCREDITED_PENDING = 'FETCH_ACCREDITED_PENDING'
export const FETCH_ACCREDITED_FULFILLED = 'FETCH_ACCREDITED_FULFILLED'
export const FETCH_ACCREDITED_REJECTED = 'FETCH_ACCREDITED_REJECTED'

export const CREATE_ACCREDITED_PENDING = 'CREATE_ACCREDITED_PENDING'
export const CREATE_ACCREDITED_FULFILLED = 'CREATE_ACCREDITED_FULFILLED'
export const CREATE_ACCREDITED_REJECTED = 'CREATE_ACCREDITED_REJECTED'

export const UPDATE_ACCREDITED_PENDING = 'UPDATE_ACCREDITED_PENDING' 
export const UPDATE_ACCREDITED_FULFILLED = 'UPDATE_ACCREDITED_FULFILLED'
export const UPDATE_ACCREDITED_REJECTED = 'UPDATE_ACCREDITED_REJECTED'

export const DELETE_ACCREDITED_PENDING = 'DELETE_ACCREDITED_PENDING'
export const DELETE_ACCREDITED_FULFILLED = 'DELETE_ACCREDITED_FULFILLED'
export const DELETE_ACCREDITED_REJECTED = 'DELETE_ACCREDITED_REJECTED'

export const CHANGE_ACCREDITED = 'CHANGE_ACCREDITED'

export const ADD_ACCREDITED = 'ADD_ACCREDITED'

export function changeAccredited(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_ACCREDITED, payload: { name, value } })
  }
}

export function addAccredited() {
  return dispatch => {
    dispatch({ type: ADD_ACCREDITED });
  };
}

export function findAllAccrediteds(first = 100, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_ACCREDITEDS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllAccrediteds {
                  credenciados (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    cpfCnpj
                    razaoSocial
                    nomeFantasia
                    celular
                    fixo
                    ramal
                    email
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_ACCREDITEDS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { credenciados } = data
        dispatch({ type: FETCH_ACCREDITEDS_FULFILLED, payload: credenciados });
        resolve(credenciados);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_ACCREDITEDS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAccredited(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_ACCREDITED_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAccredited {
                  credenciado (codigo: ${codigo}) {
                    codigo
                    status
                    tipoCredenciado
                    categoria
                    cpfCnpj
                    razaoSocial
                    nomeFantasia
                    aliquotaPis
                    aliquotaCofins
                    aliquotaIss
                    aliquotaIr
                    aliquotaInss
                    aliquotaCsll
                    numeroCNAE
                    numeroIE
                    numeroIM
                    tipoRegime
                    tipoEndereco
                    endereco
                    numero
                    complemento
                    cep
                    bairro
                    estado
                    municipio
                    celular
                    fixo
                    ramal
                    responsavel
                    email
                    homePage
                    banco
                    tipoConta
                    numeroAgencia
                    numeroConta
                  }
                }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_ACCREDITED_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { credenciado } = data
        dispatch({ type: FETCH_ACCREDITED_FULFILLED, payload: credenciado });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_ACCREDITED_REJECTED, error });
      resolve(error);
    });

  });

}

export function createAccredited(accredited) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_ACCREDITED_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation createAccredited {
            createCredenciado (input: {
              codigo: ${accredited.codigo}
              status: ${accredited.status}
              tipoCredenciado: "${accredited.tipoCredenciado}"
              categoria: ${accredited.categoria}
              cpfCnpj: "${accredited.cpfCnpj}"
              razaoSocial: "${accredited.razaoSocial}"
              nomeFantasia: "${accredited.nomeFantasia}"
              aliquotaPis: ${toFloat(accredited.aliquotaPis)}
              aliquotaCofins: ${toFloat(accredited.aliquotaCofins)}
              aliquotaIss: ${toFloat(accredited.aliquotaIss)}
              aliquotaIr: ${toFloat(accredited.aliquotaIr)}
              aliquotaInss: ${toFloat(accredited.aliquotaInss)}
              aliquotaCsll: ${toFloat(accredited.aliquotaCsll)}
              numeroCNAE: "${accredited.numeroCNAE}"
              numeroIE: "${accredited.numeroIE}"
              numeroIM: "${accredited.numeroIM}"
              tipoRegime: ${accredited.tipoRegime}
              tipoEndereco: "${accredited.tipoEndereco}"
              endereco: "${accredited.endereco}"
              numero: "${accredited.numero}"
              complemento: "${accredited.complemento}"
              cep: "${accredited.cep}"
              bairro: "${accredited.bairro}"
              estado: ${accredited.estado}
              municipio: ${accredited.municipio}
              celular: "${accredited.celular}"
              fixo: "${accredited.fixo}"
              ramal: "${accredited.ramal}"
              responsavel: "${accredited.responsavel}"
              email: "${accredited.email}"
              homePage: "${accredited.homePage}"
              banco: ${accredited.banco}
              tipoConta: ${accredited.tipoConta}
              numeroAgencia: "${accredited.numeroAgencia}"
              numeroConta: "${accredited.numeroConta}"
            } ) {
              codigo
              status
              tipoCredenciado
              categoria
              cpfCnpj
              razaoSocial
              nomeFantasia
              aliquotaPis
              aliquotaCofins
              aliquotaIss
              aliquotaIr
              aliquotaInss
              aliquotaCsll
              numeroCNAE
              numeroIE
              numeroIM
              tipoRegime
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              fixo
              ramal
              responsavel
              email
              homePage
              banco
              tipoConta
              numeroAgencia
              numeroConta
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_ACCREDITED_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createCredenciado } = data
        dispatch({ type: CREATE_ACCREDITED_FULFILLED, payload: createCredenciado });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_ACCREDITED_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateAccredited(accredited) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_ACCREDITED_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation UpdateAccredited {
            updateCredenciado (input: {
              codigo: ${accredited.codigo}
              status: ${accredited.status}
              tipoCredenciado: "${accredited.tipoCredenciado}"
              categoria: ${accredited.categoria}
              cpfCnpj: "${accredited.cpfCnpj}"
              razaoSocial: "${accredited.razaoSocial}"
              nomeFantasia: "${accredited.nomeFantasia}"
              aliquotaPis: ${toFloat(accredited.aliquotaPis)}
              aliquotaCofins: ${toFloat(accredited.aliquotaCofins)}
              aliquotaIss: ${toFloat(accredited.aliquotaIss)}
              aliquotaIr: ${toFloat(accredited.aliquotaIr)}
              aliquotaInss: ${toFloat(accredited.aliquotaInss)}
              aliquotaCsll: ${toFloat(accredited.aliquotaCsll)}
              numeroCNAE: "${accredited.numeroCNAE}"
              numeroIE: "${accredited.numeroIE}"
              numeroIM: "${accredited.numeroIM}"
              tipoRegime: ${accredited.tipoRegime}
              tipoEndereco: "${accredited.tipoEndereco}"
              endereco: "${accredited.endereco}"
              numero: "${accredited.numero}"
              complemento: "${accredited.complemento}"
              cep: "${accredited.cep}"
              bairro: "${accredited.bairro}"
              estado: ${accredited.estado}
              municipio: ${accredited.municipio}
              celular: "${accredited.celular}"
              fixo: "${accredited.fixo}"
              ramal: "${accredited.ramal}"
              responsavel: "${accredited.responsavel}"
              email: "${accredited.email}"
              homePage: "${accredited.homePage}"
              banco: ${accredited.banco}
              tipoConta: ${accredited.tipoConta}
              numeroAgencia: "${accredited.numeroAgencia}"
              numeroConta: "${accredited.numeroConta}"
            } ) {
              codigo
              status
              tipoCredenciado
              categoria
              cpfCnpj
              razaoSocial
              nomeFantasia
              aliquotaPis
              aliquotaCofins
              aliquotaIss
              aliquotaIr
              aliquotaInss
              aliquotaCsll
              numeroCNAE
              numeroIE
              numeroIM
              tipoRegime
              tipoEndereco
              endereco
              numero
              complemento
              cep
              bairro
              estado
              municipio
              celular
              fixo
              ramal
              responsavel
              email
              homePage
              banco
              tipoConta
              numeroAgencia
              numeroConta
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_ACCREDITED_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateCredenciado } = data
        dispatch({ type: UPDATE_ACCREDITED_FULFILLED, payload: updateCredenciado });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_ACCREDITED_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeAccredited(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_ACCREDITED_PENDING });

    api({
      method: 'post',    
      data: {
        query: `
          mutation RemoveAccredited {
            deleteCredenciado (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_ACCREDITED_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteCredenciado } = data
        dispatch({ type: DELETE_ACCREDITED_FULFILLED, payload: deleteCredenciado });
        dispatch(findAllAccrediteds());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_ACCREDITED_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
