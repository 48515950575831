import {

    FETCH_USER_TYPES_PENDING,
    FETCH_USER_TYPES_FULFILLED,
    FETCH_USER_TYPES_REJECTED,

    FETCH_USER_TYPE_PENDING,
    FETCH_USER_TYPE_FULFILLED,
    FETCH_USER_TYPE_REJECTED,

    CREATE_USER_TYPE_PENDING,
    CREATE_USER_TYPE_FULFILLED,
    CREATE_USER_TYPE_REJECTED,

    UPDATE_USER_TYPE_PENDING,
    UPDATE_USER_TYPE_FULFILLED,
    UPDATE_USER_TYPE_REJECTED,

    DELETE_USER_TYPE_PENDING,
    DELETE_USER_TYPE_FULFILLED,
    DELETE_USER_TYPE_REJECTED,
    
    CHANGE_USER_TYPE,
    
    ADD_USER_TYPE

} from './userTypeActions'

const titleCase = (str) => {
    if (!str) return;
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const initialState = {
    loading: false,
    listOfUserTypes: [],
    userType: {
        codigo: 0,
        descricao: '',
        permissoes: '0;0;0;0;0;0;0;0;0;0;0;0;0'
    },
    error: null,
}

export function userTypeReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_USER_TYPE: 
            let value = ""
                
            if (action.payload.name.includes('mail')) {
                value = action.payload.value.toLowerCase();
            } else 
                if (action.payload.name.includes('apelido')) {
                    value = action.payload.value;
                } else {
                    value = titleCase(action.payload.value);
                }

            return Object.assign({}, state, {
                userType: { ...state.userType, 
                    [action.payload.name]: value
                }
            })
        case ADD_USER_TYPE: 
            return Object.assign({}, state, {
                userType: { ...initialState.userType }
            })
        case FETCH_USER_TYPES_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfUserTypes: [],
            })
        case FETCH_USER_TYPES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfUserTypes: action.payload
            })
        case FETCH_USER_TYPES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_USER_TYPE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //userType: { ...initialState.userType },
            })
        case CREATE_USER_TYPE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                userType: action.payload
            })
        case CREATE_USER_TYPE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_USER_TYPE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_USER_TYPE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                userType: action.payload
            })
        case UPDATE_USER_TYPE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_USER_TYPE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_USER_TYPE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                userType: action.payload
            })
        case FETCH_USER_TYPE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DELETE_USER_TYPE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_USER_TYPE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_USER_TYPE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}