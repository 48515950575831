import {

    FETCH_AVAILABLE_SCHEDULES_PENDING,
    FETCH_AVAILABLE_SCHEDULES_FULFILLED,
    FETCH_AVAILABLE_SCHEDULES_REJECTED,

    FETCH_AVAILABLE_SCHEDULE_PENDING,
    FETCH_AVAILABLE_SCHEDULE_FULFILLED,
    FETCH_AVAILABLE_SCHEDULE_REJECTED,

    CREATE_AVAILABLE_SCHEDULE_PENDING,
    CREATE_AVAILABLE_SCHEDULE_FULFILLED,
    CREATE_AVAILABLE_SCHEDULE_REJECTED,

    UPDATE_AVAILABLE_SCHEDULE_PENDING,
    UPDATE_AVAILABLE_SCHEDULE_FULFILLED,
    UPDATE_AVAILABLE_SCHEDULE_REJECTED,

    DELETE_AVAILABLE_SCHEDULE_PENDING,
    DELETE_AVAILABLE_SCHEDULE_FULFILLED,
    DELETE_AVAILABLE_SCHEDULE_REJECTED,
    
    CHANGE_AVAILABLE_SCHEDULE,
    
    ADD_AVAILABLE_SCHEDULE

} from './availableScheduleActions'

import { titleCase } from '../../../../common/utils'
const moment = require('moment');
const initialState = {
    loading: false,
    listOfAvailableSchedules: [],
    availableSchedule: {
        codigo: 0,
        credenciado: 0,
        especialidade: 0,
        prestador: 0,
        dataInicial: moment().format('DD/MM/YYYY'),
        horaInicial: 8,
        horaFinal: 18,
        intervalo: 30,
        programacao: [
            {
                data: moment().format('DD/MM/YYYY'),
                inicioPeriodo: "99:99",
                saidaRefeicao: "99:99",
                retornoRefeicao: "99:99",
                finalPeriodo: "99:99"
            },
            {
                data: moment().format('DD/MM/YYYY'),
                inicioPeriodo: "99:99",
                saidaRefeicao: "99:99",
                retornoRefeicao: "99:99",
                finalPeriodo: "99:99"
            },
            {
                data: moment().format('DD/MM/YYYY'),
                inicioPeriodo: "99:99",
                saidaRefeicao: "99:99",
                retornoRefeicao: "99:99",
                finalPeriodo: "99:99"
            },
            {
                data: moment().format('DD/MM/YYYY'),
                inicioPeriodo: "99:99",
                saidaRefeicao: "99:99",
                retornoRefeicao: "99:99",
                finalPeriodo: "99:99"
            },
            {
                data: moment().format('DD/MM/YYYY'),
                inicioPeriodo: "99:99",
                saidaRefeicao: "99:99",
                retornoRefeicao: "99:99",
                finalPeriodo: "99:99"
            },
            {
                data: moment().format('DD/MM/YYYY'),
                inicioPeriodo: "99:99",
                saidaRefeicao: "99:99",
                retornoRefeicao: "99:99",
                finalPeriodo: "99:99"
            },
            {
                data: moment().format('DD/MM/YYYY'),
                inicioPeriodo: "99:99",
                saidaRefeicao: "99:99",
                retornoRefeicao: "99:99",
                finalPeriodo: "99:99"
            }
        ],
    },
    error: null,
}

export function availableScheduleReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_AVAILABLE_SCHEDULE:
            return Object.assign({}, state, {
                availableSchedule: { ...state.availableSchedule, 
                    [action.payload.name]: titleCase(action.payload.value)
                }
            })
        case ADD_AVAILABLE_SCHEDULE: 
            return Object.assign({}, state, {
                availableSchedule: { ...initialState.availableSchedule }
            })
        case FETCH_AVAILABLE_SCHEDULES_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_AVAILABLE_SCHEDULES_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfAvailableSchedules: action.payload
            })
        case FETCH_AVAILABLE_SCHEDULES_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case CREATE_AVAILABLE_SCHEDULE_PENDING:
            return Object.assign({}, state, {
                loading: true,
                //availableSchedule: { ...initialState.availableSchedule },
            })
        case CREATE_AVAILABLE_SCHEDULE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                availableSchedule: { ...initialState.availableSchedule }
            })
        case CREATE_AVAILABLE_SCHEDULE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case UPDATE_AVAILABLE_SCHEDULE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case UPDATE_AVAILABLE_SCHEDULE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                availableSchedule: action.payload
            })
        case UPDATE_AVAILABLE_SCHEDULE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case FETCH_AVAILABLE_SCHEDULE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case FETCH_AVAILABLE_SCHEDULE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                availableSchedule: action.payload
            })
        case FETCH_AVAILABLE_SCHEDULE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            }) 

        case DELETE_AVAILABLE_SCHEDULE_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_AVAILABLE_SCHEDULE_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_AVAILABLE_SCHEDULE_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })
 
        default:
            return state
    }
}