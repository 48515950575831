import api from '../../../../../api'

export const FETCH_SUBGROUPS_PENDING = 'FETCH_SUBGROUPS_PENDING'
export const FETCH_SUBGROUPS_FULFILLED = 'FETCH_SUBGROUPS_FULFILLED'
export const FETCH_SUBGROUPS_REJECTED = 'FETCH_SUBGROUPS_REJECTED'

export const FETCH_SUBGROUP_PENDING = 'FETCH_SUBGROUP_PENDING'
export const FETCH_SUBGROUP_FULFILLED = 'FETCH_SUBGROUP_FULFILLED'
export const FETCH_SUBGROUP_REJECTED = 'FETCH_SUBGROUP_REJECTED'

export const CREATE_SUBGROUP_PENDING = 'CREATE_SUBGROUP_PENDING'
export const CREATE_SUBGROUP_FULFILLED = 'CREATE_SUBGROUP_FULFILLED'
export const CREATE_SUBGROUP_REJECTED = 'CREATE_SUBGROUP_REJECTED'

export const UPDATE_SUBGROUP_PENDING = 'UPDATE_SUBGROUP_PENDING'
export const UPDATE_SUBGROUP_FULFILLED = 'UPDATE_SUBGROUP_FULFILLED'
export const UPDATE_SUBGROUP_REJECTED = 'UPDATE_SUBGROUP_REJECTED'

export const DELETE_SUBGROUP_PENDING = 'DELETE_SUBGROUP_PENDING'
export const DELETE_SUBGROUP_FULFILLED = 'DELETE_SUBGROUP_FULFILLED'
export const DELETE_SUBGROUP_REJECTED = 'DELETE_SUBGROUP_REJECTED'

export const CHANGE_SUBGROUP = 'CHANGE_SUBGROUP'

export const ADD_SUBGROUP = 'ADD_SUBGROUP'

export function changeSubGroup(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_SUBGROUP, payload: { name, value } })
  }
}
 
export function addSubGroup() {
  return dispatch => {
    dispatch({ type: ADD_SUBGROUP });
  };
}

export function findAllSubGroups(first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SUBGROUPS_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findAllSubGroups {
                  subGrupos (first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    grupoMaster {
                      descricao
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SUBGROUPS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { subGrupos } = data
        dispatch({ type: FETCH_SUBGROUPS_FULFILLED, payload: subGrupos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SUBGROUPS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllSubGroupsByGroup(group = 0, first = 200, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SUBGROUPS_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
                query findAllSubGroupsByGroup {
                  subGruposPorGrupo (grupo: ${group} first: ${first} offset: ${offset}) {
                    codigo
                    descricao
                    grupoMaster {
                      descricao
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SUBGROUPS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { subGruposPorGrupo } = data
        dispatch({ type: FETCH_SUBGROUPS_FULFILLED, payload: subGruposPorGrupo });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SUBGROUPS_REJECTED, error });
      resolve(error);
    });

  });

}

export function findSubGroup(codigo = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_SUBGROUP_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
                query findSubGroup {
                  subGrupo (codigo: ${codigo}) {
                    codigo
                    grupo
                    descricao
                    grupoMaster {
                      codigo
                      descricao
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_SUBGROUP_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { subGrupo } = data
        dispatch({ type: FETCH_SUBGROUP_FULFILLED, payload: subGrupo });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_SUBGROUP_REJECTED, error });
      resolve(error);
    });

  });

}

export function createSubGroup(subGroup) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SUBGROUP_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation createSubGroup {
            createSubGrupo (input: {
              codigo: ${subGroup.codigo}
              grupo: ${subGroup.grupo}
              descricao: "${subGroup.descricao}"
            } ) 
            {
              codigo
              grupo
              descricao
              grupoMaster {
                codigo
                descricao
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SUBGROUP_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createSubGrupo } = data
        dispatch({ type: CREATE_SUBGROUP_FULFILLED, payload: createSubGrupo });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SUBGROUP_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateSubGroup(subGroup) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_SUBGROUP_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation UpdateSubGroup {
            updateSubGrupo (input: {
              codigo: ${subGroup.codigo}
              grupo: ${subGroup.grupo}
              descricao: "${subGroup.descricao}"
            } ) 
            {
              codigo
              grupo
              descricao
              grupoMaster {
                codigo
                descricao
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_SUBGROUP_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { updateSubGrupo } = data
        dispatch({ type: CREATE_SUBGROUP_FULFILLED, payload: updateSubGrupo });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_SUBGROUP_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function removeSubGroup(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_SUBGROUP_PENDING });

    api({
      method: 'post',       
      data: {
        query: `
          mutation RemoveSubGroup {
            deleteSubGrupo (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_SUBGROUP_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteSubGrupo } = data
        dispatch({ type: DELETE_SUBGROUP_FULFILLED, payload: deleteSubGrupo });
        dispatch(findAllSubGroups());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_SUBGROUP_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
